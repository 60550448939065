import React from 'react'
import {Card, Table} from 'react-bootstrap'

function ProducedCellsComponent({producedCells}) {
  return (
    <Card>
      <Card.Body>
        <h5>Produced Cells (last 30 days)</h5>
        <Table>
          <thead>
            <tr>
              <th>Model</th>
              <th>Quantity</th>
            </tr>
          </thead>
          <tbody>
            {Object.keys(producedCells).map((cell, index) => {return(
              <tr key={index}>
                <td>{cell}</td>
                <td>{producedCells[cell]}</td>
              </tr>
            )})}
          </tbody>
        </Table>
        
      </Card.Body>
    </Card>
  )
}

export default ProducedCellsComponent