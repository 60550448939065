import React, {useState} from 'react'
import {Form} from 'react-bootstrap'
import { Typeahead } from 'react-bootstrap-typeahead'
import { translator } from '../../scripts/translator'

const ChargerForm = ({chargerFormFields, updateChargerForm}) => {
  const [models] = useState(["C024DQ027401", "C024DQ037801","C024JK010000", "C024LE025001", "C024PF010A01", "C024PF010B01", "C024PF010001", "C024PF020001", "C024PF025002",
                             "C024PF025003", "C024PF025004", "C024PF025201", "C024PF030001", "C024SE025GE1", "C024SE025GE2", "C024SE060G20", "C024SE060G20", "C024SE070G20", 
                             "C024SE100KS1", "C024SE120G40", "C024SE120KS1", "C024SE011001", "C024SE015001", "C024SE025001", "C024SE025005", "C024SE025007", "C024SE025008", 
                             "C024SE040101", "C024TC025N02", "C024TC050N02", "C024TC050O02","C036YJ003802","C048DQ020801", "C048DQ021200", "C048LE020001", "C048LE022001",
                             "C048PF012750", "C048PF015001","C048SE040G20","C048SE050F80","C048SE060G40","C048SE070G40","C048SE=75G41", "C048SE150G60", "C048SE020101", 
                             "C048SE022101","C048TC035O02", "C048TC160KT1", "C048YJ002YS1", "C072TC035O02", "C096SE075G60", "IC650", "IC900", "IC1200", "QuiQ1000", "MDA254", 
                             "BC-PF-2410-LXR", "BC-PF-P3-2410IR-SB50", "PFC0600-48CH10S(48V10A)", "PRO0720-48CH10S(48V10A)", "NG9", "SG3", ])

  const updateForm = (key, value) =>{
    const params = {...chargerFormFields}
    params[key] = value;
    updateChargerForm(params)
  }
  
  const handleModel = (data) => updateForm('product_model', data)

  return (
    <div className='w-100 d-flex flex-wrap justify-content-between '>
      <div className='w-100 m-2'>
        <Form.Label>{translator('model')}</Form.Label>
        <Typeahead  id="basic-typeahead-single model-number" options={models} onChange={handleModel} placeholder={chargerFormFields['product_model']} />
      </div>
      <div className="w-100 m-2">
        <Form.Label>{translator('serial number')} </Form.Label>
        <Form.Control value={chargerFormFields['serial_number']} onChange={e => updateForm('serial_number', e.target.value)}/>
      </div>
      <div className='w-100 m-2'>
        <Form.Label>{translator('charging')}</Form.Label>
        <Form.Select value={chargerFormFields['charging']} onChange={e => updateForm('charging', e.target.value)}>
          <option value="false">{translator('false')}</option>
          <option value="true">{translator('true')}</option>
        </Form.Select>
      </div>
    </div>
  )
}

export default ChargerForm