import React from 'react'
import { Modal, Button} from 'react-bootstrap'



function WesReportModal({showModal, hideModal, report}) {

  return (
    <Modal show={showModal} onHide={hideModal}>
    <Modal.Header closeButton>
      <Modal.Title>Wes report</Modal.Title>
    </Modal.Header>
    <Modal.Body>
    <strong><pre>{JSON.stringify(report, null, 2)}</pre></strong>       
    </Modal.Body>
    <Modal.Footer>
      <Button variant="default" onClick={hideModal} >{'Cancel'}</Button>
    </Modal.Footer>
  </Modal>
  )
}

export default WesReportModal