import React, {useState, useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux';
import {Modal, Button, Container, Form, Row, Col, Alert} from 'react-bootstrap';

import { deleteFiles } from '../../state/ShipmentSlice';
import {updateParams, clearParams} from '../../scripts/helperMethods';

import { translator } from '../../scripts/translator';

function ShipmentsModal({showModal, hideModal, save, pol, currentShipment}) {
  const dispatch = useDispatch();
  const currentUser = useSelector(state => state.SessionReducer) 
  const countries = ["None", "Andorra", "United Arab Emirates", "Afghanistan", "Antigua and Barbuda", 
                      "Anguilla", "Albania", "Armenia", "Angola", "Antarctica", "Argentina", "American Samoa", 
                      "Austria", "Australia", "Aruba", "Åland Islands", "Azerbaijan","Bosnia and Herzegovina", 
                      "Barbados", "Bangladesh", "Belgium", "Burkina Faso", "Bulgaria", "Bahrain", "Burundi", 
                      "Benin", "Saint Barthélemy", "Bermuda", "Brunei Darussalam", 
                      "Bolivia (Plurinational State of)", "Bonaire, Sint Eustatius and Saba", "Brazil", 
                      "Bahamas", "Bhutan", "Bouvet Island", "Botswana", "Belarus", "Belize", "Canada", 
                      "Cocos (Keeling) Islands",  "Congo (Democratic Republic of the)", 
                      "Central African Republic", "Congo", "Switzerland", "Côte d'Ivoire", "Cook Islands", 
                      "Chile", "Cameroon", "China", "Colombia", "Costa Rica", "Cuba", "Cabo Verde", "Curaçao", 
                      "Christmas Island", "Cyprus", "Czechia", "Germany", "Djibouti", "Denmark", "Dominica", 
                      "Dominican Republic", "Algeria", "Ecuador", "Estonia", "Egypt", "Western Sahara", 
                      "Eritrea", "Spain", "Ethiopia", "Finland", "Fiji", "Falkland Islands (Malvinas)",  
                      "Micronesia (Federated States of)", "Faroe Islands", "France", "Gabon", 
                      "United Kingdom of Great Britain and Northern Ireland", "Grenada", "Georgia", 
                      "French Guiana", "Guernsey", "Ghana", "Gibraltar", "Greenland", "Gambia", "Guinea", 
                      "Guadeloupe", "Equatorial Guinea", "Greece", "South Georgia and the South Sandwich Islands", 
                      "Guatemala", "Guam", "Guinea-Bissau", "Guyana", "Hong Kong", 
                      "Heard Island and McDonald Islands", "Honduras", "Croatia", "Haiti", "Hungary", "Indonesia", 
                      "Ireland", "Israel", "Isle of Man", "India", "British Indian Ocean Territory", "Iraq", 
                      "Iran (Islamic Republic of)", "Iceland", "Italy", "Jersey", "Jamaica", "Jordan", "Japan", 
                      "Kenya", "Kyrgyzstan", "Cambodia", "Kiribati", "Comoros", "Saint Kitts and Nevis", 
                      "Korea (Democratic People's Republic of)", "Korea (Republic of)", "Kuwait", "Cayman Islands", 
                      "Kazakhstan", "Lao People's Democratic Republic", "Lebanon", "Saint Lucia", "Liechtenstein", 
                      "Sri Lanka", "Liberia", "Lesotho", "Lithuania", "Luxembourg", "Latvia", "Libya", "Morocco", 
                      "Monaco", "Moldova (Republic of)", "Montenegro", "Saint Martin (French part)", "Madagascar", 
                      "Marshall Islands", "North Macedonia", "Mali", "Myanmar", "Mongolia", "Macao", 
                      "Northern Mariana Islands", "Martinique", "Mauritania", "Montserrat", "Malta", "Mauritius", 
                      "Maldives", "Malawi", "Mexico", "Malaysia", "Mozambique", "Namibia", "New Caledonia", "Niger", 
                      "Norfolk Island", "Nigeria", "Nicaragua", "Netherlands", "Norway", "Nepal", "Nauru", "Niue", 
                      "New Zealand", "Oman", "Panama", "Peru", "French Polynesia", "Papua New Guinea", "Philippines", 
                      "Pakistan", "Poland", "Saint Pierre and Miquelon", "Pitcairn", "Puerto Rico", 
                      "Palestine, State of", "Portugal", "Palau", "Paraguay", "Qatar", "Réunion", "Romania", "Serbia", 
                      "Russian Federation", "Rwanda", "Saudi Arabia", "Solomon Islands", "Seychelles", "Sudan", "Sweden", 
                      "Singapore", "Saint Helena, Ascension and Tristan da Cunha", "Slovenia", "Svalbard and Jan Mayen", 
                      "Slovakia", "Sierra Leone", "San Marino", "Senegal", "Somalia", "Suriname", "South Sudan", 
                      "Sao Tome and Principe", "El Salvador", "Sint Maarten (Dutch part)", "Syrian Arab Republic", 
                      "Eswatini", "Turks and Caicos Islands", "Chad", "French Southern Territories", "Togo", "Thailand", 
                      "Tajikistan", "Tokelau", "Timor-Leste", "Turkmenistan", "Tunisia", "Tonga", "Turkey", 
                      "Trinidad and Tobago", "Tuvalu", "Taiwan, Province of China", "Tanzania, United Republic of", 
                      "Ukraine", "Uganda", "United States Minor Outlying Islands", "United States of America", "Uruguay", 
                      "Uzbekistan", "Holy See", "Saint Vincent and the Grenadines", "Venezuela (Bolivarian Republic of)", 
                      "Virgin Islands (British)", "Virgin Islands (U.S.)", "Viet Nam", "Vanuatu", "Wallis and Futuna", 
                      "Samoa", "Yemen", "Mayotte", "South Africa", "Zambia", "Zimbabwe"]
  
  const [params, setParams] = useState({method: 'Sea', amount_shipped: 0, tracking: '', shipped_date: '', 
                                        expected_delivery_date: '', purchase_order_line_id: pol.id, 
                                        received_date: '', note: '', customer_note: '', imo: '', 
                                        destination_country: 'Denmark', shipper_expected_delivery_date: '', 
                                        auto_update_eta: false, shipname: '', forcedimo: 0, bill_of_lading: {}, 
                                        commercial_invoice: {}, packing_list: {}, packing_details: {}, 
                                        documents: []})
  const [errorMessages, setErrorMessages] = useState([]);

  useEffect(() => {
    handleErrors(params)
  },[params])

  useEffect(() => {
    if(showModal === false){clearParams(params, setParams)}
    if(!!currentShipment){ setShipment() }
  },[currentShipment, showModal]) // eslint-disable-line react-hooks/exhaustive-deps

  const setShipment = () => {
    const data = {}
    for(let key of Object.keys(params)){
      if(key !== 'documents'){
        data[key] = !!currentShipment[key] ? currentShipment[key] : params[key]
      }else{
        data['documents'] = []
      }
    }
    setParams(data)
    handleErrors(data)
  }
  
  const addDocument = () => {
    const data = {...params};
    data.documents ? data['documents'].push({note: '', file: {}}) : data['documents'] = [{note: '', file: {}}]
    setParams(data);
  }

  const updateDocument = (index, key, value) => {
    const data = {...params};
    data['documents'][index][key] = value;
    setParams(data)
  } 

  const deleteDocument = (index, keyword, id) => {
    const data = {...params}
    if(keyword === 'document'){
      data.documents.splice(index, 1);
    }else{
      data[keyword] = null;
      currentShipment[keyword] = null;
    }
    setParams(data);
    dispatch(deleteFiles({id: id, token: currentUser.token}))
  } 

  // claculates how many units have been shipped
  const calculateShippedItems = (data) => {
    let amounts = 0; // sets amouts variable
    if(!!currentShipment){ // checks if we are dealing with a current shipment or not 
      for(let shipment of pol.shipments){ // itterates over shipments of purchase order line
        if(shipment.id !== currentShipment.id){amounts = amounts + shipment['amount_shipped']} // checks if it is current shipent, if it is not the ammount shipped will be aded to ammount
      }
      amounts = amounts + parseInt(data.amount_shipped) // adds the updated shipped amount to ammount
    }else{ // if there is no current shipment
      for(let shipment of pol.shipments){ // itterates over all shipments for purchase order line
        amounts = amounts + shipment['amount_shipped'] // adds shipped amount to amount
      }
      amounts = amounts + parseInt(data['amount_shipped'])// added updated amount to ammount
    }
    return amounts // returns amoount
  }

  const handleErrors = (data) => {
    let errors = [];
    if(data.amount_shipped === 0){errors.push(translator('amount shipped must be bigger than zero'))}
    if(parseInt(data.amount_shipped) >= 1000000){errors.push(translator('ammount shipped must be less than one million'))}
    if(pol.amount < calculateShippedItems(data)){errors.push(translator('total shipped amount cannot exceed the ordered amount'))}
    console.log(errors)
    setErrorMessages(errors);
  } 

  return (
    <Modal show={showModal} onHide={hideModal}>
      <Modal.Header closeButton>
        <Modal.Title>{!!currentShipment ? translator('update shipment') : translator('create shipment')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container>
          <Row>
            <Col>
              <Form.Label>{translator('destination country')}</Form.Label>
              <Form.Select value={params.destination_country || 'Denmark'} onChange={e => updateParams(params, setParams, 'destination_country', e.target.value)}>
                {countries.map((country, index) => {return(
                  <option key={index} value={country}>{country}</option>
                )})}
              </Form.Select>
            </Col>
            <Col>
              <Form.Label>{translator('method')}</Form.Label> 
              <Form.Select id='method' value={params.method || 'Sea'} onChange={e => updateParams(params, setParams, 'method', e.target.value)}>
                <option value='Sea'>Sea</option>
                <option value='UPS'>UPS</option>
                <option value='FedEx'>FedEx</option>
                <option value='Air'>Air</option>
                <option value='Truck'>Truck</option>
                <option value='VRT'>VRT</option>
                <option value='Other'>Other</option>
              </Form.Select>
            </Col>
          </Row>
          <br />
          <Row>
            <Col>
              <Form.Label>{translator('amount shipped')} *</Form.Label>
              <Form.Control id='amount-shipped' value={params.amount_shipped || ''} onChange={e => updateParams(params, setParams, 'amount_shipped', e.target.value)} type='number'/>
            </Col>
            <Col>
              <Form.Label>{translator('override ship name')}</Form.Label>
              <Form.Control id='ship-name' value={params.shipname || ''} onChange={e => updateParams(params, setParams, 'shipname', e.target.value)}/>
            </Col>
          </Row>
          <br />
          <Row>
            <Col>
              <Form.Label>{translator('tracking')}</Form.Label>
              <Form.Control id='tracking' value={params.tracking || ''} onChange={e => updateParams(params, setParams, 'tracking', e.target.value)} as='textarea'/>
            </Col>
          </Row>
          <br />
          <Row>
            <Col>
              <Form.Label>{translator('forced imo to use for vessel finder')}</Form.Label>
              <Form.Control id='imo' value={params.forcedimo || ''} onChange={e => updateParams(params, setParams, 'forcedimo', e.target.value)}/>
            </Col>
            <Col>
              <br />
              <Form.Label>{translator('shipped date')}</Form.Label>
              <Form.Control id='shipped-date' value={params.shipped_date || ''} onChange={e => updateParams(params, setParams, 'shipped_date', e.target.value)} type='date'/>
            </Col>
          </Row>
          <br />
          <Row>
            <Col>
              <Form.Label>{translator('expected delivery date')}</Form.Label>
              <Form.Control id='exp-delivery-date' value={params.expected_delivery_date || ''} onChange={e => updateParams(params, setParams, 'expected_delivery_date', e.target.value)} type='date'/>
            </Col>
            <Col>
              <Form.Label>{translator('recieved date')}</Form.Label>
              <Form.Control id='recieved-date' value={params.received_date || ''} onChange={e => updateParams(params, setParams, 'received_date', e.target.value)} type='date'/>
            </Col>
          </Row>
          <br />
          <Row>
            <Col>
              <Form.Label>{translator('auto update eta')}</Form.Label>
              <Form.Check id='auto-update-eta' checked={params.auto_update_eta || ''} onChange={e => updateParams(params, setParams, 'auto_update_eta', e.target.value)}/>
            </Col>
          </Row>
          <br />
          <Row>
            <Col>
              <Form.Label>{translator('internal note')}</Form.Label>
              <Form.Control id='internal-note' value={params.note || ''} onChange={e => updateParams(params, setParams, 'note', e.target.value)} as='textarea' />
            </Col>
          </Row>
          <br />
          <Row>
            <Col>
              <Form.Label>{translator('customer note')}</Form.Label>
              <Form.Control id='customer-note' value={params.customer_note || ''} onChange={e => updateParams(params, setParams, 'customer_note', e.target.value)} as='textarea' />
            </Col>
          </Row>
          <br />
          <Row>
            <Col>
              <Form.Label>{translator('bill of landing')}</Form.Label>
              <Form.Control id='bill-of-lading' onChange={e => updateParams(params, setParams, 'bill_of_lading', e.target.files[0])} type='file'/>
              <br />
              <Form.Label>{translator('comercial invoice')}</Form.Label>
              <Form.Control id='comercial-invoice' onChange={e => updateParams(params, setParams, 'commercial_invoice', e.target.files[0])} type='file'/>
              <br />
              <Form.Label>{translator('packing list')}</Form.Label>
              <Form.Control id='packing-list' onChange={e => updateParams(params, setParams, 'packing_list', e.target.files[0])} type='file'/>
              <br />
              <Form.Label>{translator('packing details')}</Form.Label>
              <Form.Control id='packing-details' onChange={e => updateParams(params, setParams, 'packing_details', e.target.files[0])} type='file'/>
              <br />
            </Col>
            <Col>
              <div>
                <strong><p>{translator('bill of landing')}:</p></strong>
                {(currentShipment && !!currentShipment.bill_of_lading) && 
                  <div className='d-flex justify-content-between'>
                    <p>{currentShipment.bill_of_lading.filename}</p>
                    <span onClick={e => deleteDocument(null, 'bill_of_lading', currentShipment.bill_of_lading.id)} className="material-symbols-outlined pointer red-color">delete</span>
                  </div>
                }
              </div>
              <br />
              <div>
                <strong><p>{translator('comercial invoice')}:</p></strong>
                {(currentShipment && !!currentShipment.commercial_invoice) &&
                  <div className='d-flex justify-content-between'>
                    <p>{currentShipment.commercial_invoice.filename}</p>
                    <span onClick={e => deleteDocument(null, 'commercial_invoice', currentShipment.commercial_invoice.id)} className="material-symbols-outlined pointer red-color">delete</span>
                  </div> 
                }
                
              </div>
              <br />
              <div>
                <strong><p>{translator('packing list')}:</p></strong>
                {(currentShipment && !!currentShipment.packing_list) && 
                  <div className='d-flex justify-content-between'>
                    <p>{currentShipment.packing_list.filename}</p>
                    <span onClick={e => deleteDocument(null, 'packing_list', currentShipment.packing_list.id)} className="material-symbols-outlined pointer red-color">delete</span>
                  </div> 
                }
              </div>
              <br />
              <div>
                <strong><p>{translator('packing details')}:</p></strong>
                {(currentShipment && !!currentShipment.packing_details) &&
                  <div className='d-flex justify-content-between'>
                    <p>{currentShipment.packing_details.filename}</p>
                    <span onClick={e => deleteDocument(null, 'packing_details', currentShipment.packing_details.id)} className="material-symbols-outlined pointer red-color">delete</span>
                  </div> 
                }
              </div>
              <br />
            </Col>
          </Row>
          <br />
          <Row>
            <Col>
              <h6>{translator('documents')}</h6>
              <br />
              {(!!currentShipment && currentShipment.documents && currentShipment.documents.length !== 0 ) && currentShipment.documents.map((document, index) => { return(
                <div className='d-flex justify-content-between'>
                  <a href={document.url} >{document.filename}</a>
                  <span onClick={e => deleteDocument(index, 'document', document.id)} className="material-symbols-outlined pointer red-color">delete</span>
                </div>
              )})}
              <br />
              {(params.documents && params.documents.length !== 0) && params.documents.map((document, index) => {return(
                <div key={index}>
                  <Form.Label>{translator('note')}</Form.Label>
                  <Form.Control id='note' onChange={e => updateDocument(index, 'note', e.target.value)} as='textarea'/>
                  <br />
                  <Form.Label>{translator('file')}</Form.Label>
                  <Form.Control id='document' onChange={e => updateDocument(index, 'file', e.target.files[0])} type='file'/>
                  <br />
                  <br />
                </div>
              )})}
              <br />  
              <Button className='btn-sm' onClick={e => addDocument()}>{translator('add document')}</Button>
            </Col>
          </Row>
        </Container>
        <br />
        {errorMessages.length !== 0 && errorMessages.map((message, index) => {return(
          <Alert key={index} variant='danger'>
            {message}
          </Alert>
        )})}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="default" onClick={hideModal}>{translator('cancel')}</Button>
        <Button onClick={e => save(params)} disabled={errorMessages.length !== 0 }>{translator('save')}</Button>
      </Modal.Footer>
    </Modal>
  )
}

export default ShipmentsModal