export const chinese = {
  // navigation
  'overview': '概述',
  'reports': '报告',
  'Aqua Report': 'AQUA 报告',
  'WES reports': 'WES 报告',
  'Cell reports': '电芯报告',
  'Dashboard': '仪表板',
  'Batteries': '电池',
  'BMS models': 'BMS 型号', 
  'Override Codes': '覆写代码',
  'Batteries': '电池',
  'Battery Params': '电池参数',
  'Battery Firwares': '电池固件程序',
  'Upgrade Manifest': '升级清单',
  'RMA': 'RMA',
  'Users': '用户',
  'Companies': '公司',
  'Chargers': '充电器',
  'Settings': '设置',
  'Update profile': '更新个人信息',
  'New Customer/Supplier': '新客户/供应商',

  // general
  'cancel': '取消',
  'save': '保存',
  'select': '选择',
  'note': '备注',
  'notes': '备注',
  'page size': '页面大小',
  'file': '文件',
  'files': '文件',
  'add file': '添加文件',
  'documents': '文件',
  'add document': '添加文档',
  'type': '类型',
  'docs': '文档',
  'model': '型号',
  'search': '搜索',
  'clear': '清除',
  'results': '结果',
  'id': 'ID',
  'name': '姓名',
  'date': '日期',
  'dates': '日期',
  'created at': '创建于',
  'updated at': '更新于',
  'test date': '测试日期',
  'prod date': '生产日期',
  'select': '选择',
  'actions': '行动',
  'excel': 'Excel',
  'delete': '删除',

  //Component
  'wst number': 'Wst #',
  'haidi number': 'Haidi #',
  'item number': 'Item #',
  'tracking': '查询',
  'placed': '已下单',
  'all done': '全部完成',
  'technical document delivered': '技术文件已发送',
  'purchase order lines': '订单行',
  'cusotmer': '客户',
  'po line id': '订单行 ID',
  'product number': '产品编号',
  'amount': '数量',
  'qspec': '质检参数',
  'pid': 'Pid',
  'td': '技术文件',
  'produced': '已生产',
  'shipped': '已发货',
  'actually done': '实际完成',
  'expected done': '预计完成',
  'cqr': 'CQR',
  'shipments': '出货批次',
  'all done date': '全部完成日期', 
  'dest': '目的地',
  'qty': '数量.',
  'bms model': 'BMS型号',
  'io': 'IO',
  'firmware': '固件程序', 
  'serial number': '序列号',
  'cell voltage diff': '电芯电压差',
  'fc diff': 'FC差异',
  'ir': 'IR',
  'csv': 'CSV',
  'show report': '显示报告',
  'po': '采购订单',
  'line': '行',
  'soc': 'SOC',
  'mos temp': 'MOS温度',
  'ambient temp': '环境温度',
  'cell t max': '电芯温度最大值',
  'cell t min': '电芯温度最小值',
  'design cap': '设计容量', 
  'full cap': '满容量', 
  'rem cap': '剩余容量',
  'cell v max': '电芯电压最大值',
  'cell v min': '电芯电压最小值',
  'cell diff': '电芯差异',
  'fc diff': 'FC差异', 
  'aqua report id': 'AQUA报告ID',
  'aqua report': 'AQUA报告',
  'battery model': '电池型号',
  'internal note': '内部备注',
  'customer note': '客户备注',
  'bill of lading': '提货单',
  'packing list': '装箱单',
  'packing details': '包装细节',
  'chg': '充电',
  'dsg': '放电',
  'ov number': 'OV',
  'uv number': 'UV',
  'sc number': 'SC',
  'ov cycle ratio': 'OV循环比',
  'uv cycle ratio': 'UV循环比',
  'sc cycle ratio': 'SC循环比',
  'charging': '充电中',
  'checked by': '检查者',
  'delivery date': '出货日期',
  'rma type': 'RMA类型',
  'customer rma number': '客户 RMA 编号',
  'supplier rma number': '供应商 RMA 编号',
  'customer issue': '客户问题',
  'status': '状态',
  'fault': '错误',
  'details': '具体细节',
  'battery': '电池',
  'charger': '充电器',
  'created': '已创建',
  'received': '已收到',
  'working': '工作中',
  'cycle number':  '循环次数',
  'upgrades': '升级',


  //Modal
  'show only open orders': '仅显示未完成订单',
  'open all fields': '打开所有字段',
  'new purchase order': '新采购订单',
  'select cusotmer': '选择客户',
  'purchase order line id': '采购订单行ID',
  'aqua enabled': '启用AQUA',
  'wes enabled': '启用WES',
  'internal order for pack': '电池组电芯订单',
  'expected production done date': '预计生产完成日期',
  'amount complete': '完成数量',
  'serial number begins': '序列号开始于',
  'serial number end': '序列号结束于',
  'cell quality report': '电芯质量报告CQR',
  'technical documents': '技术文件',
  'show old qspec': '显示旧的qspec参数',
  'show old wes spec': '显示旧的WES参数',
  'wes spec': 'WES规格',
  'internal order': '内部订单',
  'auto update eta': '自动更新收货日期',
  'comercial invoice': '商业发票',
  'wst order number': 'WST订单号',
  'haidi order number': '海帝订单号',
  'customer order number': '客户订单号',
  'purchase order line': '采购订单行', 
  'destination country': '目的地国家',
  'method': '方法',
  'amount shipped': '已发货数量',
  'override ship name': '覆盖运输名称',
  'forced imo to use for vessel finder': '强制 imo 用于船舶查找器',
  'shipped date': '发货日期',
  'expected delivery date': '预计出货日期',
  'recieved date': '收货日期',
  'packvoltage': '电池组电压',
  'less than or equal': '小于或等于',
  'more than or equal': '大于或等于',
  'bms serial number': 'BMS序列号',
  'create bms model': '创建BMS型号',
  'barcode': '条码',
  'manufacturer': '制造商',
  'voltage': '电压',
  'charger serial': '充电器序列号',
  'charger model': '充电器型号',
  'new charger': '新充电器',
  'select charger model': '选择充电器型号',
  'company name': '公司名称',
  'update company': '更新公司',
  'create company': '创建公司',
  'customer name': '客户名称',
  'user': '用户',
  'code': '代码',
  'create override code': '创建覆写代码',
  'comm.': '通讯',
  'casing voltage': '外壳电压',
  'true': '正确',
  'false': '错误',
  'root cause analysis': '根本原因分析',
  'warranty wst status': 'WST保修状态',
  'replacement percentage': '更换百分比',
  'warranty supplier status': '供应商保修状态',
  'replacement spent': '更换花费',
  'supplier': '供应商',
  'new rma': '新RMA',
  'wst rma number': 'WST RMA编号',
  'completed date': '完成日期',
  'supervisor help': '上级协助',
  'waiting on supplier': '等待供应商',
  'processed': '已处理',
  'discarded': '已废弃',
  'returned': '已返回',
  'customer Rma': '客户RMA',
  'wst rma': 'WST RMA',
  'supplier rma': '供应商RMA',
  'received date': '收货日期',
  'warranty length': '保修期',
  'wst warranty': 'WST保修',
  'unknown': '未知',
  'yes': '是',
  'no': '否',
  'supplier warranty': '供应商保修',
  'fault description': '故障描述',
  'select fault description': '选择故障描述',
  'assembly error': '装配错误',
  'deep discharged': '过度放电',
  'bms issue': 'BMS问题',
  'no Issue': '无问题',
  'bricked': '砖化',
  'parameter issue': '参数问题',
  'charger issue': '充电器问题',
  'cell issue': '电芯问题',
  'handling error': '处理错误中',
  'na': '无法使用',
  'calculate warranty': '计算保修期',
  'email': '电子邮件',
  'password': '密码',
  'login': '登录',
  'create firmware': '创建固件程序',
  'new battery firmware': '新电池固件程序',
  'select model': '选择型号',
  'parameters': '参数',
  'create parameter': '创建参数',
  'new battery params': '新电池参数',
  'parameters data': '参数数据',
  'battery parameter': '电池参数',
  'battery firmware': '电池固件程序',
  'new upgrade manifest': '新升级清单',
  'select firmware': '选择固件程序',
  'battery params': '电池参数',
  'select param': '选择参数',
  'company': '公司',
  'role': '角色',
  'create user': '创建用户',
  'password confirmation': '确认密码',
  'select company': '选择公司',
  'report version': '报告版本',
  'test type': '测试类型',
  'delete confirmation': '删除确认',
  'version': '版本',
  'description': '描述',
  'mandatory': '强制',
  'allow downgrade': '允许降级',

  // Messages
  'are you sure you want to delete purchase order': '确定要删除采购订单吗',
  'purchase order line id not filled in': '订单行 ID 未填写',
  'product number too short': '产品编号太短（最少 4 个字符）且与现有产品相同',
  'amount not set': '数量未设定',
  'bill of lading missing': '（提单缺失）',
  'actual done date not set': '（实际完成日期未设定）',
  'not shipped on time': '（未按时发货）',
  'not produced on time': '（未按时生产）',
  'are you sure you want to delete purchase order line': '您确定要删除采购订单行吗',
  'wst order number cannot be empty and has to be unique': 'Wst 订单号不能为空且必须是唯一的',
  'amount shipped must be bigger than zero': '发货数量必须大于0',
  'ammount shipped must be less than one million': '发货数量必须小于1000000',
  'total shipped amount cannot exceed the ordered amount': '发货总数量不能超过订单数量',  
  'are you sure you want to delete shipment': '您确定要删除该批货运吗',
  'rma successfully saved': 'Rma成功保存',
  'rma successfully deleted': 'Rma成功删除',
  'assign selected manifest to selected batteries': '将选定的清单分配给选定的电池',
  'assign Manifest to batteries': '将清单分配给电池',
  'remove selected upgrade from selected batteries': '从选定的电池中删除选定的升级',
}