import {Navigate, Outlet } from 'react-router'
import {useSelector} from 'react-redux'

function SessionRoutes() {
  const user = useSelector(state => state.session.data);

  return !!user ? <Outlet/> : <Navigate to='/login'/>
}

export default SessionRoutes
