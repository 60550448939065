import { chinese } from "../translations/chi"
import { english } from "../translations/eng"


export const translator = (string) => {
  const language = navigator.language || navigator.userLanguage

  if(['zh', 'zh-hk', 'zh-HK', 'zh-cn', 'zh-CN', 'zh-sg', 'sh-SG', 'zh-tw', "zh-TW"].includes(language)){
    return english[string]
  }else{
    return english[string]
  }
}
