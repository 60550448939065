import React, {useEffect, useState} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import {Container, Form, Row, Col, Button, Spinner, Table} from 'react-bootstrap'

import { getCompanies, newCompany, updateCompany } from '../../state/CompaniesSlice'
import { getCustomers } from '../../state/CustomerSlice'
import { getSuppliers } from '../../state/SuppliersSlice'

import { translator } from '../../scripts/translator'

import CompanyModal from './CompanyModal'
import SimpleTable from '../widgets/SimpleTable'
import SearchWidget from '../widgets/SearchWidget'

function CompanyIndexComponent() {
  const dispatch = useDispatch();
  const currentUser = useSelector(state => state.session.data);
  const companies = useSelector(state => state.companies.data)
  const [currentCompany, setCurrentCompany] = useState(null)
  const [searchParams, setSearchParams] = useState({name_i_cont: '', type_eq: ''})
  const [searchHeaders, setSearchHeaders] = useState([
                                                      {label: translator('company name'), subLables: null, params: ['name_i_cont'], options: null, type: 'text'},
                                                      {label: translator('type'), subLables: null, params: ['type_eq'], options: [{label: 'Supplier', value: 'Supplier'}, {label: 'Customer', value: 'Customer'}], type: 'select'},
                                                    ])
  const [showModal, setShowModal] = useState(false)
  const [loading, setLoading] = useState(true)
  const [tableHeaders] = useState({'actions': 'actions', 'name': 'name', 'type': 'type'})

    
  // use effect triggers when canvas is made
  useEffect(() => {
    fetchData()
  },[])

  // use effect triggers when companies global variable changes
  useEffect(() => {
   if(!!companies.message && companies.message === "Company Succesfully saved"){ // if api succes message 
      fetchData() // recalls companies index enpoint
   }
   if(Array.isArray(companies.companies) && companies.companies.length !== 0 ){ // if there are companies  
    setLoading(false) // end loading spinner
   }
  },[companies])

  // hides modal and resets current company
  const hideModals = () => {
    setShowModal(false);
    setCurrentCompany(null);
  }

  // saves company
  const saveCompany = (params) => { // takes params from modal
    setLoading(true) // starts loading spinner
    !!currentCompany ? dispatch(updateCompany({id: currentCompany.id, token: currentUser.token, params})) : dispatch(newCompany({token: currentUser.token, params}))  // checks if the current company is not ull and nor undefined then it calls either patch endpoint or post endpoint
    hideModals(); // closes active modals
  }

  // gets relevant data
  const fetchData = () => {
    setLoading(true) // starts loading spinner
    dispatch(getCustomers({token: currentUser.token})); // gets all customers
    dispatch(getSuppliers({token: currentUser.token})); // gets all suppliers
    dispatch(getCompanies({token: currentUser.token, searchParams})); // gets all cusotmers
  }

  // empties search fields
  const resetSearch = () => {
    setSearchParams({name_i_cont: '', type_eq: ''}) // resets search params state 
    fetchData() // gets relevant data
  }

  // opens update modal (takes a company)
  const update = (company) => {
    setCurrentCompany(company); // sets current company
    setShowModal(true) // opens modal
  } 

  return (
    <Container fluid>
      <Container className='w-50' style={{marginTop: '100px', marginBottom: '50px'}}>
        <h1><span className="material-symbols-outlined">search</span></h1>
        <SearchWidget searchParams={searchParams} searchHeaders={searchHeaders} setSearchParams={setSearchParams} search={fetchData} resetSearch={resetSearch}/>    
      </Container>
      <br />
      <br />
      <Button onClick={e => setShowModal(true)}>New company</Button>
      {loading === true && <Col className='d-flex justify-content-center'><Spinner size='lg' animation="grow" /></Col> }
      {loading === false && 
        <SimpleTable tableElements={tableHeaders} data={companies.companies} searchParams={searchParams} setOrder={null} openEdit={update} openDelete={null}/>
       
      }
      {showModal === true && <CompanyModal hideModal={hideModals} showModal={showModal}  currentCompany={currentCompany} save={saveCompany}  />}
     
    </Container>
  )
}

export default CompanyIndexComponent

