import {createSlice, createAsyncThunk} from '@reduxjs/toolkit';

export const getCompanies = createAsyncThunk('getCompanies', async (data) => {
  console.log(data)
  let searchString = `?`;
  for (const key of Object.keys(data.searchParams)) {
    if (data.searchParams[key].length !== 0) {
      searchString = searchString + `&q[${key}]=${data.searchParams[key]}`
    }
  }

  const companies = await fetch(`${process.env.REACT_APP_API_URL}/company${searchString}`, {
    method: 'GET',
    headers: {"Content-Type": "application/json", token: data.token},
  })

  return(companies.json())
})

export const newCompany = createAsyncThunk('newCompany', async (data) => {
  const company = await fetch(`${process.env.REACT_APP_API_URL}/company`, {
    method: 'POST',
    headers: {"Content-Type": "application/json", token: data.token},
    body: JSON.stringify({company: data. params})
  })

  return(company.json())
})

export const updateCompany = createAsyncThunk('updateCompany', async (data) => {
  const company = await fetch(`${process.env.REACT_APP_API_URL}/company/${data.id}`, {
    method: 'PATCH',
    headers: {"Content-Type": "application/json", token: data.token},
    body: JSON.stringify(data.params)
  })

  return(company.json())
})

export const companiesSlice = createSlice({
  name: 'companies',
  initialState: {
    loading: false,
    data: [],
    error: null
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getCompanies.fulfilled, (state, action) => {
      state.data = action.payload
    });
    builder.addCase(newCompany.fulfilled, (state, action) => {
      state.data = action.payload
    });
    builder.addCase(updateCompany.fulfilled, (state, action) => {
      state.data = action.payload
    })
  }
})

export default companiesSlice.reducer;