 import {createSlice, createAsyncThunk} from '@reduxjs/toolkit'; 

 export const getSuppliers = createAsyncThunk('createSupplier', async (data) => {
  const suppliers = await fetch(`${process.env.REACT_APP_API_URL}/suppliers`, {
    method: 'GET',
    headers: {"Content-Type": "application/json", token: data.token}
  })

  return(suppliers.json())
 })

 export const suppliersSlice = createSlice({
  name: 'suppliers',
  initialState: {
    loading: false,
    data: [],
    error: null
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getSuppliers.fulfilled, (state, action) => {
      state.data = action.payload
    })
  }
 })

 export default suppliersSlice.reducer;