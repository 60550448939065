import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Col, Container, Spinner} from 'react-bootstrap';

import { getAquaReports } from '../../state/AquaReportsSlice';

import { setOrder } from '../../scripts/helperMethods';
import { translator } from '../../scripts/translator';

import SimpleTable from '../widgets/SimpleTable';
import Paginator from '../widgets/Paginator';
import SearchWidget from '../widgets/SearchWidget';
import QspecModal from '../widgets/modals/QspecModal';

function AquaReportsIndexComponent() {
  const dispatch = useDispatch();
  const currentUser = useSelector(state => state.session.data);
  const data = useSelector(state => state.aquaReports.data);
  const [downloadLink, setDownloadLink] = useState((`${process.env.REACT_APP_API_URL}/aquareports/download_csv`))
  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(true)
  const [currentReport, setCurrentReport] = useState(null);
  const [showModal, setShowModal] = useState(false)
  const [searchParams, setSearchParams] = useState({purchase_order_line_product_number_i_cont: '', bmsmodel_i_cont: '', po_i_cont: '', firmware_gteq: '', firmware_lteq: '', serialnumber_gteq: '', serialnumber_lteq: '', celldiff_gteq: '', celldiff_lteq: '', fullcyclecelldifference_gteq: '', fullcyclecelldifference_lteq: '', ir_pack_gteq: '', ir_pack_lteq: '', testdate_gteq: '', testdate_lteq: '', s: 'testdate desc'});
  const [searchHeaders] = useState([
                                    {label: translator('model'), subLables: null, params: ['model_i_cont'], options: null, type: 'text'},
                                    {label: translator('bms model'), subLables: null, params: ['bmsmodel_i_cont'], options: null, type: 'text'},
                                    {label: translator('io'), subLables: null, params: ['po_i_cont'], options: null, type: 'text'},
                                    {label: translator('firmware'), subLables: ['More than or equal', 'Less than or equal'], params: ['firmware_gteq', 'firmware_lteq'],  options: null, type: 'text'},
                                    {label: translator('serial number'), subLables: ['More than or equal', 'Less than or equal'], params: ['serialnumber_gteq', 'serialnumber_lteq'], options: null, type: 'text'},
                                    {label: translator('cell voltage diff.'), subLables: ['More than or equal', 'Less than or equal'], params: ['celldiff_gteq', 'celldiff_lteq'], options: null, type: 'text'},
                                    {label: translator('fC diff.'), subLables: ['More than or equal', 'Less than or equal'], params: ['fullcyclecelldifference_gteq', 'fullcyclecelldifference_lteq'], options: null, type: 'text'},
                                    {label: translator('ir'), subLables: ['More than or equal', 'Less than or equal'], params: ['ir_pack_gteq', 'ir_pack_lteq'], options: null, type: 'text'},
                                    {label: translator('test Date'), subLables: ['More than or equal', 'Less than or equal'], params: ['testdate_gteq', 'testdate_lteq'], options: null, type: 'text'}
                                  ])
  const [tableElements] = useState({actions: 'actions',  date: 'testdate', po: 'po', line: 'po_line_id', model: 'model', 'serial number': 'serialnumber', 'bms model': 'bmsmodel', firmware: 'firmware', packvoltage: 'packvoltage', soc: 'soc', 'mos temp': 'mostemperature', 'ambient temp': 'ambienttemperature', 'cell t max': 'cell_t_max', 'cell t min': 'cell_t_min', 'design cap': 'designcapacity', 'full cap': 'fullcapacity', 'rem cap': 'remainingcapacity', 'cell v max': 'cell_v_max', 'cell v min': 'cell_v_min', 'cell diff': 'cellDiff', 'fc diff': 'fullcyclecelldifference', ir: 'internalresistance'})
  
  useEffect(() => {
    setLoading(true);
    getReports();
  },[page]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if(data['reports'] !== undefined &&  data['reports'].length !== 0){
      setLoading(false)
    }
  },[data])

  const getReports = () => {
    const params = {...searchParams}
    if(currentUser.role === 'customer'){
      params['purchase_order_line_company_id_eq'] = currentUser.company_id
    }
    setLoading(true)
    dispatch(getAquaReports({searchParams: params, page, token: currentUser.token }));
  }

  const generateCsvDownloadLink = () => {
    const params = {...searchParams}
    if(currentUser.role === 'customer'){
      params['purchase_order_line_company_id_eq'] = currentUser.company_id
    }
    let searchString = '?'
    for(const key of Object.keys(params)){
      if(params[key].length !== 0){
       searchString = searchString + `&q[${key}]=${params[key]}`
      }
    }
    const searchUrl = `${process.env.REACT_APP_API_URL}/aquareports/download_csv${searchString}`
    setDownloadLink(searchUrl)
  }

  const clearSearch = () => {
    const params = {purchase_order_line_product_number_i_cont: '', bmsmodel_i_cont: '', po_i_cont: '', firmware_gteq: '', firmware_lteq: '', serialnumber_gteq: '', serialnumber_lteq: '', celldiff_gteq: '', celldiff_lteq: '', fullcyclecelldifference_gteq: '', fullcyclecelldifference_lteq: '', ir_pack_gteq: '', ir_pack_lteq: '', testdate_gteq: '', testdate_lteq: '', s: 'testdate desc'}
    setPage(0);
    setSearchParams(params);
    dispatch(getAquaReports({params, page, token: currentUser.token }))
    generateCsvDownloadLink();
  }

  const search = () => {
    generateCsvDownloadLink();
    setPage(0);
    getReports();
  }
  
  const order = (element) => setOrder(searchParams, setSearchParams, element, getReports)

  const showReport = (report) => {
    localStorage.setItem('aquaReport', JSON.stringify(report, null, 2))
    window.open('aqua_report', "_blank")
  }

  const closeModal = () => {
    setShowModal(false)
    setCurrentReport(null)
  }

  const openModal = (report) => {
    setCurrentReport(report.qardata.header.QspecContent.Params)
    setShowModal(true)
  }

  return (
    <Container fluid>
      <br />
      <Container className='my-5 mx-auto' style={{width: '60%'}}>
        {(!!currentUser && currentUser.role !== 'customer') && <h3>{translator('Search')} ( {translator('results')}: {data.results} )</h3>}
        {(!!currentUser && currentUser.role !== 'customer')&&<SearchWidget searchParams={searchParams} searchHeaders={searchHeaders} setSearchParams={setSearchParams} search={search} resetSearch={clearSearch} />}
        <div className='w-100 d-flex justify-content-end'>
          <a href={downloadLink} className='btn btn-primary d-flex' rel="noopener noreferrer"><span className="material-symbols-outlined pointer" >download</span>{translator('csv')}</a>
        </div>
      </Container>
     
      
      <div>
        <br/>
        <br/>
        <Paginator count={data.results} pageSize={100} page={page} setPage={setPage}/>
        <br/>
        {loading === true &&
          <Col className='d-flex justify-content-center'>
            <Spinner size='lg' animation="grow" />
          </Col>
        }
        {loading === false &&
          <SimpleTable tableElements={tableElements}  data={data.reports} searchParams={searchParams} setOrder={order} show={showReport} openModal={openModal}/> 
        }
        <br />
        <Paginator count={data.results} pageSize={100} page={page} setPage={setPage}/>
        <br/>
      </div>
      <QspecModal showModal={showModal} hideModal={closeModal} report={currentReport}/>
    </Container>
  )
}

export default AquaReportsIndexComponent