import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

export const getCustomers = createAsyncThunk('getCustomers', async (data) => {
  console.log('running')
  const customers = await fetch(`${process.env.REACT_APP_API_URL}/customers`, {
    method: 'GET',
    headers: {"Content-Type": "application/json", token: data.token}
  })

  return(customers.json())
})

export const customersSlice = createSlice({
  name: 'cutomers',
  initialState: {
    loading: false,
    data: [],
    error: null
  }, 
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getCustomers.fulfilled, (state, action) => {
      state.data = action.payload
    })
  }
})

export default customersSlice.reducer;