import React, {useState, useEffect} from 'react'
import { Modal, Button, Alert, Form } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux';

import { updateUser } from '../../../state/userSlice';

function UpdateCurrentUserModal({showModal, hideModal, currentUser}) {
  const dispatch = useDispatch()
  const [params, setParams] = useState({email: '', password: '', password_confirmation: '', role: '', company_id: ''})
  const [errors, setErrors] = useState([]);
  const [loading, setLoading] = useState(false)
  const [saved, setSaved] = useState(false)
  const users = useSelector(state => state.user.data)


  useEffect(() => {
    if(!!users.message && users.message === 'User successfully saved'){ // if user has a success message
      setSaved(true)
    }
  },[users])

  useEffect(() => {
    if(showModal === false){
      resetParams()
    }
  },[showModal])

  const resetParams = () => setParams({password: '', password_confirmation: ''})


  const updateParams = (key, value) =>{
    const data = {...params}
    data[key] = value
    setParams(data)
    checkErrors(data)
  } 

  const checkErrors = (data) => {
    let alerts = []
    if(data['password'] !== data['password_confirmation']){alerts.push('password and password confirmation are different')}
    setErrors(alerts)
  }

  const saveUser = () => dispatch(updateUser({id: currentUser.id , user: params, token: currentUser.token}))

  return (
    <Modal show={showModal} onHide={hideModal}>
      <Modal.Header closeButton>
        <Modal.Title>Update profile</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {saved === true &&  <h3>Profile updated! </h3>}
        {saved === false && 
          <div>
            <div className='mb-4'>
              <Form.Label>Email</Form.Label>
              <Form.Control value={!!currentUser ? currentUser.email : ''} disabled={true}/>
            </div>

            <div className='mb-4'>
              <Form.Label>Password</Form.Label>
              <Form.Control value={params['password'] } onChange={e => updateParams('password', e.target.value)}/>
            </div>

            <div className='mb-4'>
              <Form.Label>Password confirmation</Form.Label>
              <Form.Control value={params['password_confirmation'] } onChange={e => updateParams('password_confirmation', e.target.value)}/>
            </div>
            
            {errors.length !== 0 && errors.map(error => <Alert variant='danger' key={error}>{error}</Alert>)}
          </div>
        }          
      </Modal.Body>
      <Modal.Footer>
        <Button variant="default" onClick={hideModal} >{saved === false ?  'Cancel' : 'Close'}</Button>
        {saved === false &&  <Button onClick={e => saveUser()} >Save</Button>}
      </Modal.Footer>
    </Modal>
  )
}

export default UpdateCurrentUserModal