import {createSlice, createAsyncThunk} from '@reduxjs/toolkit';

export const getOverideCodes = createAsyncThunk('getOverrideCodes', async (data) => {
  const codes = await fetch(`${process.env.REACT_APP_API_URL}/override_codes`, {    
    method: 'GET',
    headers: {"Content-Type": "application/json", token: data.token}
  })

  return(codes.json())
})

export const createOverideCodes = createAsyncThunk('createOverrideCodes', async (data) => {
  const codes = await fetch(`${process.env.REACT_APP_API_URL}/override_codes`, {    
    method: 'POST',
    headers: {"Content-Type": "application/json", token: data.token},
    body: JSON.stringify(data.params)
  })

  return(codes.json())
})

export const overrideCodesSlice = createSlice({
  name: 'overrideCodes',
  initialState: {
    loading: false,
    data: [],
    error: null
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getOverideCodes.fulfilled, (state, action) => {
      state.data = action.payload
    });
    builder.addCase(createOverideCodes.fulfilled, (state, action) => {
      state.data = action.payload
    })
  }
})

export default overrideCodesSlice.reducer;