import {createSlice, createAsyncThunk} from '@reduxjs/toolkit'

export const getUsers = createAsyncThunk('getUsers', async (data) => {
  
  const searchParams = data.searchParams
  let searchString = '?'
  
  for(let key of Object.keys(searchParams)){
    if(searchParams[key].length !== 0){
      searchString = searchString + `&q[${key}]=${searchParams[key]}`
    }
  }

  const user =  await fetch(`${process.env.REACT_APP_API_URL}/users${searchString}`, {
    method: 'GET',
    headers: {"Content-Type": "application/json", token: data.token}
  })

  return(user.json())

})

export const createUser = createAsyncThunk('createUser', async (data) => {
  const user = await fetch(`${process.env.REACT_APP_API_URL}/users`, {
    method: 'POST',
    headers: {"Content-Type": "application/json", token: data.token},
    body: JSON.stringify({user: data.user})
  })
  
  return(user.json())
})

export const updateUser = createAsyncThunk('updateUser', async (data) => {
  const user = await fetch(`${process.env.REACT_APP_API_URL}/users/${data.id}`, {
    method: 'PATCH',
    headers: {"Content-Type": "application/json", token: data.token},
    body: JSON.stringify({user: data.user})
  })

  return(user.json())
})

export const deleteUser = createAsyncThunk('deleteUser', async (data) =>{
  const user = await fetch(`${process.env.REACT_APP_API_URL}/users/${data.user.id}`, {
    method: 'DELETE',
    headers: {"Content-Type": "application/json", token: data.token}
  })

  return(user.json())
})

export const userSlice = createSlice({
  name: 'user',
  initialState: {
    loading: false,
    data: [],
    error: null
  },
  reducers:{},
  extraReducers: (builder) => {
    builder.addCase(getUsers.fulfilled, (state, action) => {
      state.data = action.payload
    });

    builder.addCase(createUser.fulfilled, (state,action) => {
      state.data = action.payload
    });

    builder.addCase(updateUser.fulfilled, (state, action) => {
      state.data = action.payload
    });

    builder.addCase(deleteUser.fulfilled, (state, action) =>{
      state.data = action.payload
    })

  }
})

export default userSlice.reducer;