import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

export const getBatteries = createAsyncThunk('getBatteries', async (data) => {
  console.log(console.log(data))
  let searchString = `?page=${data.page}`;
  for(const key of Object.keys(data.params)){
    if(data.params[key].length !== 0){
      searchString = searchString + `&q[${key}]=${data.params[key]}`
    }
  }

  const batteries = await fetch(`${process.env.REACT_APP_API_URL}/batteries${searchString}`, {
    method: 'GET',
    headers: {"Content-Type": "application/json", token: data.token}
  })
  
  return(batteries.json())
})

export const batteriesSlice = createSlice({
  name: 'batteries',
  initialState: {
    loading: false,
    data: [], 
    error: null
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getBatteries.fulfilled, (state, action) => {
      state.data = action.payload
    });
  }
})

export default batteriesSlice.reducer