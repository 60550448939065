import {createSlice, createAsyncThunk} from '@reduxjs/toolkit';

export const getCellReports = createAsyncThunk('getCellReports', async (data) => {
  console.log(data)

  let searchString = `?page=${data.page}&pagesize=${data.pageSize}&`
  
  for(let key of Object.keys(data.searchParams)){
    if(data.searchParams[key].length !== 0){
     searchString = searchString + `&q[${key}]=${data.searchParams[key]}`
    }
  }

  const cellReports = await fetch(`${process.env.REACT_APP_API_URL}/cellreports${searchString}`, {
    method: 'GET',
    headers: {'ContentType': 'application/json', token: data.token}
  })

  return(cellReports.json())
})

export const cellReportSlice = createSlice({
  name: 'cellReports',
  initialState: {
    loading: true,
    data: [], 
    error: null
  },
  reducers: {}, 
  extraReducers: (builder) => {
    builder.addCase(getCellReports.fulfilled, (state, action) => {
      state.data = action.payload
    });
  }
})

export default cellReportSlice.reducer;