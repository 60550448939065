import React, {useState, useEffect} from 'react'
import { Modal, Button, Alert, Form, Spinner} from 'react-bootstrap'
import { Typeahead } from 'react-bootstrap-typeahead'
import { useDispatch, useSelector } from 'react-redux'

import { getCustomers } from '../../state/CustomerSlice'
import { getSuppliers } from '../../state/SuppliersSlice'
import { getCompanies, newCompany } from '../../state/CompaniesSlice'

import { updateParams } from '../../scripts/helperMethods'

function TypeSpecificModal({showModal, hideModal, current_user}) {
  const dispatch = useDispatch();
  const customer = useSelector(state => state.customers.data);
  const supplier = useSelector(state => state.supplier.data);
  const company = useSelector(state => state.companies.data)
  const [params, setParams] = useState({name: '', type: 'Supplier'});
  const [errors, setErrors] = useState([]);
  const [loading, setLoading] = useState(false);
  const [saved, setSaved] = useState(false);

  //  triggers when canvas is made
  useEffect(() => {
    get(); // gets relevant data
    getCompany()
  }, [])

  useEffect(() => {
    if(!!company && !!company.message &&  company.message === 'Company Succesfully saved'){ // if api deliveres succes message
      setSaved(true) // adds saved
      get(); // gets relevant data 
      getCompany() // gets company
    }
  }, [company])
  
  // gets relevant data 
  const get = () => {
    dispatch(getCustomers({token: current_user.token})); // gets all  customers
    dispatch(getSuppliers({token: current_user.token})); // gets all suppliers
  }

  // reload triggers if you make a new company
  const getCompany = () =>  dispatch(getCompanies({token: current_user.token, searchParams: {}})) 

  // closes the modal
  const closeModal = () => {
    setLoading(false) // closes loading spinner
    setSaved(false) // resets saved message
    setParams({name: '', type: 'Supplier'}); // resets params
    hideModal(); // calls navbars close modla function
  }

  // saves company
  const save = () => {
    setLoading(true); // starts spinner animation
    dispatch(newCompany({token: current_user.token, params})); // calls apis post endpoint
  }

  const handleName = (data) => handleParams('name', data[0]) // sets name on save

  // updates params
  const handleParams = (key, value) => {
    const data = {...params} // makes cpoy of params
    data[key] = value; // sets value of object key
    handleErrors(data) // checks for errors
    setParams(data)// updates params
  }

  // makes a list of used names 
  const handleList = () => {
    if(!!supplier && !!customer){
      const values = params.type === 'Supplier' ? supplier : customer // checks if its companies or suppliers we are making a list of 
      return values.map(val => val.name) // itterates over all db elements and returns a list of names
    }
    return []
  }
  
  // makes a list of errors 
  const handleErrors = (data) => {
    let errorMessages =  []; // makes empty array 
    const values = data.type === 'Supplier' ? supplier : customer // checks if its a list of customers or suppliers
    const names = values.map(val => val.name) // makes a list of names based on either customers or suppliers
    if(names.includes(data.name)){errorMessages.push('Customer or supplier  allready exists')} // if the typed in name is in the list error is added
    if(names.includes(data.name[0])){errorMessages.push('Customer or supplier  allready exists')} // if the typed in name is in the list error is added
    setErrors(errorMessages) // sets error messages
  }

  return (
    <Modal show={showModal} onHide={closeModal}>
    <Modal.Header closeButton>
      <Modal.Title>Create customer</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      {saved && <h4>Company succesfully saved !</h4>} 
      {!saved && 
        <div>
          <div className='mb-4'>
            <Form.Label>Type</Form.Label>
            <Form.Select onChange={e => handleParams('type',  e.target.value)}>
              <option value="Supplier">Supplier</option>
              <option value="Customer">Customer</option>
            </Form.Select>
          </div>
          <div>
            <Form.Label>Customer name</Form.Label>
            <Typeahead id="basic-typeahead-single" options={handleList()} onChange={handleName} onBlur={e => handleName([e.target.value])}/>
          </div>
          <br />
          {errors.length !== 0 && errors.map(error => <Alert key={error} variant='danger' className='w-100'>{error}</Alert>) }
        </div>
      }
    </Modal.Body>
    <Modal.Footer>
      <Button variant="default" onClick={e => closeModal()} >{saved === false ? 'Cancel' : 'Close'}</Button>
      {saved === false && <Button onClick={e => save()} disabled={loading}>{loading ? <Spinner animation="border" /> : 'Save'}</Button>}
    </Modal.Footer>
  </Modal>
  )
}

export default TypeSpecificModal