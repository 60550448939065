import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

export const getBatteryParams = createAsyncThunk('getBatteryParams', async (data) => {
  let searchString  = '?'
  if(!!data.params){
    for(let key of  Object.keys(data.params)){
      if(data.params[key].length !== 0){
        searchString = searchString + `q[${key}]=${data.params[key]}&`
      }
    }
  }

  const batparams = await fetch(`${process.env.REACT_APP_API_URL}/battery_parameters${searchString}`, {    
    method: 'GET',
    headers: {"Content-Type": "application/json", token: data.token}
  })

  return(batparams.json())
})

export const saveBatteryParams = createAsyncThunk('saveBatteryParams', async (data) => {
  const batparams = await fetch(`${process.env.REACT_APP_API_URL}/battery_parameters`, {    
    method: 'POST',
    headers: {"Content-Type": "application/json", token: data.token},
    body: JSON.stringify({battery_parameter: data.params})
  })

  return(batparams.json())
})

export const batteryParamsSlice = createSlice({
  name: 'batteryParams',
  initialState: {
    loading: false,
    data: [],
    error: null
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getBatteryParams.fulfilled, (state, action) =>{
      state.data = action.payload
    })
  }
})

export default batteryParamsSlice.reducer;