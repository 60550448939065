import React from 'react'
import {Container, Form, Button} from 'react-bootstrap'
import { updateParams } from '../../scripts/helperMethods'

import { translator } from '../../scripts/translator'

function SearchWidget({searchParams, searchHeaders, setSearchParams, search, resetSearch}) {
  
  const updateSearch = (key, value) => updateParams(searchParams, setSearchParams, key, value)

  const handleKeypress = (e) => { if(e.key === 'Enter'){ search() } }

  return (
    <Container className='my-5  mx-auto d-flex flex-wrap justify-content-between'>
      {searchHeaders.map((header, index) => {return(
        <div key={index}>
          {header.params.length === 1 &&
            <div className='mb-3'>
             {header.type !== 'check' && <Form.Label>{header.label}</Form.Label>}
              {header.type === 'text' && <Form.Control className='search-width' id={header['params']}   value={searchParams[header['params'][0]]} onChange={e => updateSearch(header['params'][0], e.target.value)}  placeholder={header.label} onKeyPress={e => handleKeypress(e)}/>}
              
              {header.type === 'select' && 
                <Form.Select className='search-width' value={searchParams[header['params'][0]]} id={header['params']}  onChange={e => updateSearch(header['params'][0], e.target.value)}>
                <option value="">{translator('select')} {header.label}</option>
                  {header.options.map(option => <option key={option.value} value={option.value}>{option.label}</option>)}
                </Form.Select>
              }
              {header.type === 'check' && 
                <div className='d-flex mt-4'>
                  <Form.Label>{header.label}</Form.Label>
                  <div className='m-1'/>
                  <Form.Check checked={searchParams[header['params'][0]]} id={header['params']}  onChange={e => updateSearch(header['params'][0], e.target.checked)} type='switch'/>
                  
                </div>
              }
              
            </div>
          }
          {header.params.length > 1 && 
            <div>
              {header.type !== 'check' && <Form.Label>{header.label}</Form.Label>}
              <div className='d-flex '>
                
                {header.type !== 'json' &&  header['params'].map((param, index) => {return(
                  <div className='mb-3'>
                    {header.type === 'date' && <Form.Control className='search-width' id={param} value={searchParams[param]} onChange={e => updateSearch(param, e.target.value)}  type='date'/>}
                    {header.type === 'text' && <Form.Control className='search-width' id={param} value={searchParams[param]} onChange={e => updateSearch(param, e.target.value)}  onKeyPress={e => handleKeypress(e)}/>}
                    {header['subLables'] !== null && <small className='form-text text-muted'>{header['subLables'][index]}</small>}
                  </div>
                )})}
                
                {header.type === 'json' && <Form.Control className='search-width' id={header['params']}  value={searchParams[header['params'][1]]} onChange={e => updateSearch(header['params'][0], `{"${header['params'][1]}":"${e.target.value}"}`)}  placeholder={header.label} onKeyPress={e => handleKeypress(e)}/>}
                
              </div>
            </div>
          }
        </div>
        
      )})}
      <div className='d-flex w-100 justify-content-between mt-5' >
        <div className='d-flex'>
          <Button onClick={e => search()} id='search' > {translator('search')} </Button>
          <div className='m-2'/>
          <Button variant='danger' onClick={e => resetSearch()}>{translator('clear')}</Button>
        </div>
      </div>
    </Container>
  )
}

export default SearchWidget