import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { SaveToLocalStorage, LoadCurrentSession, DestroyCurrentSession } from '../scripts/SessionHelper'

export const login = createAsyncThunk('login', async (data) => {
  const currentUser = await fetch(`${process.env.REACT_APP_API_URL}/login`,{
    method: 'POST',
    headers: {"Content-Type": "application/json"},
    body: JSON.stringify(data)
  })

  return(currentUser.json())
})


export const sessionSlice = createSlice({
  name: 'session',
  initialState: {
    loading: false,
    data: LoadCurrentSession(),
    error: null
  },
  reducers: {
    logout(state, action){
      DestroyCurrentSession()
      state.data = LoadCurrentSession()
    }
  },
  extraReducers: (builder) => {
    builder.addCase(login.fulfilled, (state, action) =>{ 
      SaveToLocalStorage(action.payload)
      state.data = LoadCurrentSession()
    });

  }

});



export const {logout} = sessionSlice.actions;

export default sessionSlice.reducer;