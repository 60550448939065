import React from 'react';
import {Routes, Route} from 'react-router-dom';

import SessionRoutes from './SessionRoutes';
import AdminRoutes from './AdminRoutes';
import SupervisorRoutes from './SupervisorRoutes';
import HaidiRoutes from './HaidiRoutes';
import CustomerRoutes from './CustomerRoutes';
import LoginComponent from '../components/sessions/LoginComponent';
import OverviewIndexComponent from '../components/overview/OverviewIndexComponent';
import AquaReportsIndexComponent from '../components/aquaReports/AquaReportsIndexComponent';
import AquaReportShowComponent from '../components/aquaReports/AquaReportShowComponent';
import CellReportsIndexComponent from '../components/cellReports/CellReportsIndexComponent';
import DashboardIndexComponent from '../components/dashboard/DashboardIndexComponent';
import BatteriesIndexComponent from '../components/upgradeManifest/batteries/BatteriesIndexComponent';
import UsersIndexComponent from '../components/users/UsersIndexComponent';
import OverrideCodesComponent from '../components/overideCodes/OverrideCodesComponent';
import BatteryParametersIndexComponent from '../components/upgradeManifest/Batteryparameters/BatteryParametersIndexComponent'
import BatteyFirmwaresindexComponent from '../components/upgradeManifest/batteryFirmwares/BatteyFirmwaresindexComponent';
import UpgradeManifestIndexComponent from '../components/upgradeManifest/UpgradeManifestIndexComponent';
import RmaIndexComponent from '../components/rma/RmaIndexComponent';
import CompanyIndexComponent from '../components/companies/CompanyIndexComponent';
import ChargerIndexComponent from '../components/chargers/ChargerIndexComponent';
import WesReportsIndexComponent from '../components/wesReports/WesReportsIndexComponent';
import BatteryIndexComponent from '../components/batteries/BatteryIndexComponent';
import BmsModelIndexComponent from '../components/bmsModel/BmsModelIndexComponent';
import IpBmsIndexComponent from '../components/4g/IpBmsIndexComponent';
import CellsIndexComponent from '../components/cells/CellsIndexComponent';

function RoutesController() {
 
  return (

    <Routes>
      <Route path='/login' exact element={<LoginComponent/>}/>

      <Route element={<SessionRoutes/>}>
        <Route path='/' exact element={<OverviewIndexComponent/>}/>
        <Route path='/overview' exact element={<OverviewIndexComponent/>}/>
        <Route path='/aqua_reports' exact element={<AquaReportsIndexComponent/>}/>
        <Route path='/aqua_report' exact element={<AquaReportShowComponent/>}/>
        <Route path='/wes_reports' exact element={<WesReportsIndexComponent/>} />
        <Route path='/cell_reports' exact element={<CellReportsIndexComponent/>}/>
        <Route path='/batteries' exact element={<BatteryIndexComponent/>}/>
        <Route path='/bms_models' exact element={<BmsModelIndexComponent/>} />
        <Route path='/dashboard' exact element={<DashboardIndexComponent/>}/>
        <Route path='/cells' exact element={<CellsIndexComponent/>}/>
        
        <Route path='/override_codes' exact element={<OverrideCodesComponent/>}/>
      </Route>
      <Route element={<AdminRoutes/>}>
        <Route path='/upgrade/batteries' exact element={<BatteriesIndexComponent/>}/>
        <Route path='/battery_parameters' exact element={<BatteryParametersIndexComponent/>}/>
        <Route path='/battery_firmwares' exact element={<BatteyFirmwaresindexComponent/>}/>
        <Route path='/upgrade_manifests' exact element={<UpgradeManifestIndexComponent/>}/>
        <Route path='/4g' exact element={<IpBmsIndexComponent/>}/>
        <Route path='/users' exact element={<UsersIndexComponent/>}/>
      </Route>
      <Route element={<SupervisorRoutes/>}>
        <Route path='/rma' exact element={<RmaIndexComponent/>}/>
        <Route path='/companies' exact element={<CompanyIndexComponent/>} />
        <Route path='/chargers' exact element={<ChargerIndexComponent/>} />
      </Route>
      <Route element={<HaidiRoutes/>}></Route>
      <Route element={<CustomerRoutes/>}></Route>
    </Routes>
  )
}

export default RoutesController;