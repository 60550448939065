import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

export const getCells = createAsyncThunk('getCells', async (data) => {
  let searchString = `?page=${data.page}&pagesize=${data.pageSize}&`

  for(let key of Object.keys(data.searchParams)){
    if(data.searchParams[key].length !== 0){
     searchString = searchString + `&q[${key}]=${data.searchParams[key]}`
    }
  }
   const cells = await fetch(`${process.env.REACT_APP_API_URL}/cells${searchString}`, {
    method: 'GET',
    headers: {'ContentType': 'application/json', token: data.token}
  })

  return(cells.json())
}) 

export const createCell = createAsyncThunk('createCell', async (data) =>{
  const cell = await fetch(`${process.env.REACT_APP_API_URL}/cells`, {
    method: 'POST',
    body: data.params,
    headers: { token: data.token}
  })

  return(cell.json())
})

export const updateCell = createAsyncThunk('updateCell', async (data) =>{
  const cell = await fetch(`${process.env.REACT_APP_API_URL}/cells/${data.id}`, {
    method: 'PATCH',
    body: data.params,
    headers: { token: data.token}
  })

  return(cell.json())
})

export const CellsSlice = createSlice({
  name: 'cells',
  initialState: {
    loading: true,
    data: [],
    error: null
  }, 
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getCells.fulfilled, (state, action) => {
      state.data = action.payload
    });
    builder.addCase(createCell.fulfilled, (state, action) =>{
      state.data = action.payload
    });
    builder.addCase(updateCell.fulfilled, (state, action) => {
      state.data = action.payload
    })
  }
})

export default CellsSlice.reducer;