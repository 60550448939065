import './App.scss';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import {useSelector} from 'react-redux'

import NavbarCoponent from './components/widgets/NavbarCoponent';
import RoutesController from './routes/RoutesController';

function App() {
  const user = useSelector(state => state.session)

  return (
    <div className="App">
      {user  && <NavbarCoponent/>}
      
      <RoutesController/>
    </div>
  );
}

export default App;
