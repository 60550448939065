import React from 'react'
import {Card, Table} from 'react-bootstrap'

function StatisticsTableComponent({fcDiffCutOffValues}) {

  return (
    <Card>
      <Card.Body>
        <Table striped  size="sm" variant="tight" className="pb-5">
          <thead>
            <tr>
              <th>Model</th>
              <th>{'1σ (removed elements)'}</th>
              <th>{'1.1σ (removed elements)'}</th>
              <th>{'1.2σ (removed elements)'}</th>
              <th>{'1.3σ (removed elements)'}</th>
              <th>{'1.4σ (removed elements)'}</th>
              <th>{'1.5σ (removed elements)'}</th>
              <th>{'2σ (removed elements)'}</th>
              <th>{'3σ (removed elements)'}</th>
              <th>Sample size</th>
            </tr>
          </thead>
          <tbody>
            {Object.keys(fcDiffCutOffValues).map((model, index) => {return(
              <tr key={index} >
                <td>{fcDiffCutOffValues[model].model_link}</td>
                <td>{fcDiffCutOffValues[model].dev1} ({fcDiffCutOffValues[model].dev1_removed})</td>
                <td>{fcDiffCutOffValues[model].dev2} ({fcDiffCutOffValues[model].dev2_removed})</td>
                <td>{fcDiffCutOffValues[model].dev3} ({fcDiffCutOffValues[model].dev3_removed})</td>
                <td>{fcDiffCutOffValues[model].dev4} ({fcDiffCutOffValues[model].dev4_removed})</td>
                <td>{fcDiffCutOffValues[model].dev5} ({fcDiffCutOffValues[model].dev5_removed})</td>
                <td>{fcDiffCutOffValues[model].dev6} ({fcDiffCutOffValues[model].dev6_removed})</td>
                <td>{fcDiffCutOffValues[model].dev7} ({fcDiffCutOffValues[model].dev7_removed})</td>
                <td>{fcDiffCutOffValues[model].dev8} ({fcDiffCutOffValues[model].dev8_removed})</td>
                <td>{fcDiffCutOffValues[model].alldata.length} </td>
              </tr>
            )})}
          </tbody>
        </Table>
      </Card.Body>
    </Card>
  )
}

export default StatisticsTableComponent