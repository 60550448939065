import React, {useState} from 'react'
import { Container, Modal, Button, Form} from 'react-bootstrap'

import { updateParams } from '../../../scripts/helperMethods'
import { translator } from '../../../scripts/translator'
  
function BatteryFirmwareModal({showModal, hideModal, saveFirmware, batteryModels, bmsModels}) {
  const [params, setParams] = useState({description: '', version: 0.0, battery_model_id: 0, file: '', bms_model_id: 0})

  return (
    <Modal show={showModal} onHide={hideModal}>
        <Modal.Header closeButton>
          <Modal.Title>{translator('new battery firmware')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container>
            <Form.Label>{translator('description')}</Form.Label>
            <Form.Control value={params['description']} onChange={e => updateParams(params, setParams, 'description', e.target.value)} />
            <br />
            <Form.Label>{translator('version')}</Form.Label>
            <Form.Control value={params['version']} onChange={e => updateParams(params, setParams, 'version', e.target.value)} /> 
            <br/>
            <Form.Label>{translator('battery model')}</Form.Label>
            <Form.Select value={params['battery_model_id']} onChange={e => updateParams(params, setParams, 'battery_model_id', e.target.value)}>
              <option value="0">{translator('select model')}</option>
              {batteryModels.map(model => <option key={model.name} value={model.id}>{model.name}</option>)}
            </Form.Select>
            <br/>
            <Form.Label>{translator('file')}</Form.Label>
            <Form.Control onChange={e => updateParams('file', e.target.files[0])} type='file'/>
            <br />
            <Form.Label>{translator('bms model')}</Form.Label>
            <Form.Select value={params['bms_model_id']} onChange={e => updateParams(params, setParams, 'bms_model_id', e.target.value)}>
              <option value="0">{translator('select model')}</option>
              {bmsModels.bms_models.map(model => <option key={model.name} value={model.id}>{model.name}</option>)}
            </Form.Select>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="default" onClick={hideModal}>{translator('cancel')}</Button>
          <Button onClick={e => saveFirmware(params)}>{translator('save')}</Button>
        </Modal.Footer>
      </Modal>
  )
}

export default BatteryFirmwareModal