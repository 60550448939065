import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

export const getBmsModels = createAsyncThunk('getBmsModels', async (data) => {
  let searchString  = '?'
  if(!!data.params){
    for(let key of  Object.keys(data.params)){
      if(data.params[key].length !== 0){
        searchString = searchString + `q[${key}]=${data.params[key]}&`
      }
    }
  }

  const batteryModels = await fetch(`${process.env.REACT_APP_API_URL}/bms_models${searchString}`, {
    method: 'GET',
    headers: {"Content-Type": "application/json", token: data.token}
  })

  return(batteryModels.json())
})

export const createBmsModel = createAsyncThunk('createBmsModels', async (data) => {
  const BmsModlels = await fetch(`${process.env.REACT_APP_API_URL}/bms_models`, {
    method: 'POST',
    headers: {token: data.token}, 
    body: data.params
  })

  return(BmsModlels.json())
})

export const updateBmsModel = createAsyncThunk('updateBmsModels', async (data) => {
  const BmsModlels = await fetch(`${process.env.REACT_APP_API_URL}/bms_models/${data.id}`, {
    method: 'PATCH',
    headers: { token: data.token}, 
    body: data.params
  })

  return(BmsModlels.json())
})

export const bmsModelsSlice = createSlice({
  name: 'bmsModels',
  initialState: {
    loading: false,
    data: [],
    error: null
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getBmsModels.fulfilled, (state, action) => {
      state.data = action.payload
    });
    
    builder.addCase(createBmsModel.fulfilled, (state, action) =>{
      state.data = action.payload
    });

    builder.addCase(updateBmsModel.fulfilled, (state, action) =>{
      state.data = action.payload
    });
  }
})

export default bmsModelsSlice.reducer;