import React,{ useEffect, useState} from 'react'
import {Button, Container, Form, Col, Spinner} from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';

import { getUpgradeManifest, saveUpgradeManifest } from '../../state/UpgradeManifestSlice';
import { getBatteryFirmware } from '../../state/BatteryFirmwareSlice';
import { getBatteryParams } from '../../state/BatteryParamsSlice';

import UpgradeManifestModal from './UpgradeManifestModal';
import SimpleTable from '../widgets/SimpleTable';
import SearchWidget from '../widgets/SearchWidget';
import { translator } from '../../scripts/translator';

function UpgradeManifestIndexComponent() {
  const dispatch = useDispatch();
  const maifests = useSelector(state => state.upgradeManifest.data);
  const firmware = useSelector(state => state.batteryFirmware.data);
  const params = useSelector(state => state.batteryParams.data);
  const currentUser = useSelector(state => state.session.data);
  const [searchParams, setSearchParams] = useState({id_eq: '', battery_firmware_id_eq: '', battery_parameter_id: ''})
  const [tableElements] = useState({id: 'id', 'description': 'description',  'mandatory': 'mandatory', 
                                    'allow downgrade': 'allow_downgrade', 'battery firmware': 'battery_firmware_id', 
                                    'battery parameter': 'battery_parameter_id',  'created at': 'created_at', 
                                    'updated at': 'updated_at'})
  const [displayModal, setDisplayModal] = useState(false)
  const [loading, setLoading] = useState(true)
  const [searchHeaders, setSearchHeaders] = useState([])

  useEffect(() => {
    getData();
  },[]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if(maifests.length !== 0 ){
      setLoading(false)
    }
  },[maifests])

  useEffect(() => {
    if(maifests.length !== 0 && firmware.length !== 0 && params.length !== 0){
      setSearchHeaders([
                        {label: translator('id'), subLables: null, params: ['id_eq'], options: null, type: 'text'},
                        {label: translator('firmware'), subLables: null, params: ['battery_firmware_id_eq'], options: firmware.battery_firmwares.map(fw => ({label: fw.id, value: fw.id})), type: 'select'},
                        {label: translator('battery parameter'), subLables: null, params: ['battery_parameter_id'], options: params.battery_parameters.map(manifest => ({label: manifest.id, value: manifest.id})), type: 'select'}
                      ])
    }
  }, [maifests, firmware])

  const getData = () => {
    setLoading(true)
    hideModals();
    dispatch(getUpgradeManifest({searchParams, token: currentUser.token}));
    dispatch(getBatteryFirmware({searchParams: {}, token: currentUser.token}));
    dispatch(getBatteryParams({searchParams: {}, token: currentUser.token}));
  }

  const hideModals= () => setDisplayModal(false);

  const saveManifest = (data) => {
    dispatch(saveUpgradeManifest({params: data, token: currentUser.token}));
    getData();
  }
  
  
  const resetSearch = () => {
    setSearchParams({id_eq: '', battery_firmware_id_eq: '', battery_parameter_id: ''});
    getData()
  }

  return (
    <Container fluid>
        <br />
        <br />
        <Container>
          <h1><span className="material-symbols-outlined">search</span></h1>
          <SearchWidget searchParams={searchParams} searchHeaders={searchHeaders} setSearchParams={setSearchParams} search={getData} resetSearch={resetSearch}/>
        </Container>
        <br />
        <br />

        <Button onClick={e => setDisplayModal(true)}>{translator('create parameter')}</Button>
        <br />
        <br />
        {loading === true && <Col className='d-flex justify-content-center'><Spinner size='lg' animation="grow" /></Col>}
        {loading === false && <SimpleTable tableElements={tableElements}  data={maifests.upgrade_manifests} searchParams={searchParams} setOrder={null}/> }
         
        
       {displayModal === true && <UpgradeManifestModal showModal={displayModal} hideModal={hideModals} saveManifest={saveManifest} firmware={firmware} params={params}/>}
    </Container>
  )
}

export default UpgradeManifestIndexComponent