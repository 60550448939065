import React, {useState} from 'react'
import { Container, Modal, Button, Form} from 'react-bootstrap'

import { translator } from '../../../scripts/translator'

function BatteryParametersModal({showModal, hideModal, saveParams, batteryModels, bmsModels}) {
  const [params, setParams] = useState({description: '', version: 0.0, battery_model_id: 0, parametersdata: '', bms_model_id: 0})
  
  const updateParams = (key, value) => {
    const newParams = {...params};
    newParams[key] = value;
    setParams(newParams);
  }

  return (
    <Modal show={showModal} onHide={hideModal}>
        <Modal.Header closeButton>
          <Modal.Title>{translator('new battery params')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container>
            <Form.Label>{translator('description')}</Form.Label>
            <Form.Control value={params['description']} onChange={e => updateParams('description', e.target.value)} />
            <br />
            <Form.Label>{translator('version')}</Form.Label>
            <Form.Control value={params['version']} onChange={e => updateParams('version', e.target.value)} /> 
            <br/>
            <Form.Label>{translator('Battery model')}</Form.Label>
            <Form.Select value={params['battery_model_id']} onChange={e => updateParams('battery_model_id', e.target.value)}>
              <option value="0">{translator('select model')}</option>
              {batteryModels.map(model => <option key={model.name} value={model.id}>{model.name}</option>)}
            </Form.Select>
            <br/>
            <Form.Label>{translator('parameters data')}</Form.Label>
            <Form.Control value={params['parametersdata']} onChange={e => updateParams('parametersdata', e.target.value)}  as='textarea'/>
            <br />
            <Form.Label>{translator('bms model')}</Form.Label>
            <Form.Select value={params['bms_model_id']} onChange={e => updateParams('bms_model_id', e.target.value)}>
              <option value="0">{translator('select model')}</option>
              {bmsModels.bms_models.map(model => <option key={model.name} value={model.id}>{model.name}</option>)}
            </Form.Select>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="default" onClick={hideModal}>{translator('cancel')}</Button>
          <Button onClick={e => saveParams(params)}>{translator('save')}</Button>
        </Modal.Footer>
      </Modal>
  )
}

export default BatteryParametersModal