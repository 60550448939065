import React, {useState, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Container, Row, Col, Table, Button, Form, Alert, FormCheck, Spinner} from 'react-bootstrap';

import { getBatteries } from '../../../state/BatteriesSlice';
import { getBatteryModels } from '../../../state/BatteryModelsSlice';
import { getUpgrades } from '../../../state/AvailableBatteryUpgradeSlice';
import { assignBatteryUpgrade, removeBatteryUpgrade, resetBatteryUpgrade } from '../../../state/HandleBatteryUpgradeSlice';

import SearchWidget from '../../widgets/SearchWidget';
import { translator } from '../../../scripts/translator';

function BatteriesIndexComponent() {
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useState({battery_model_name_eq: '', serialnumber_lteq: '', serialnumber_gteq: '' });
  const [searchHeaders, setSearchHeaders] = useState([
                                    
                                  ])
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [selectedBatteries, setSelectedBatteries] = useState([ 
                                                              {label: translator('battery model'), subLabels: null, params: ['battery_model_name_eq'], options: null, type: 'select' },
                                                              {label: translator('serial number') , subLables: ['Less than or equal', 'More than or equal'], params: ['serialnumber_lteq', 'serialnumber_gteq'],  options: null, type: 'text'}
                                                            ]);
  const [selecttedUpgrade, setSelectedUpgrade] = useState();
  const batteryModels = useSelector(state => state.batteryModels.data);
  const batteries = useSelector(state => state.batteries.data);
  const availableBatteryUpgrades = useSelector(state => state.availableBatteryUpgrades.data);
  const upgradeMessage = useSelector(state => state.handleBatteryUpgrade.data);
  const currentUser = useSelector(state => state.session.data);
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    getModels();
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if(upgradeMessage.length !== 0){
     setTimeout(() => resetMessage(), 3000);
    }
  },[upgradeMessage]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    console.log(batteries)
    if(batteries.length !== 0){
      setLoading(false)
    }
    if(batteryModels.length !== 0 ){
      setSearchHeaders([
        {label: translator('battery model'), subLabels: null, params: ['battery_model_name_eq'], options: batteryModels.map(model => ({label: model.name, value: model.name})), type: 'select' },
        {label: translator('serial number'), subLables: ['Less than or equal', 'More than or equal'], params: ['serialnumber_lteq', 'serialnumber_gteq'],  options: null, type: 'text'}
      ])
    }
  },[batteries, batteryModels])
  

  const resetMessage = () => dispatch(resetBatteryUpgrade());


  const getModels = () => {
    dispatch(getBatteryModels({token: currentUser.token}));
  }

  const search = () => {
    setLoading(true)
    setPage(0)
    dispatch(getBatteries({token: currentUser.token, params: searchParams, page}))
    //dispatch(getUpgrades({modelName: searchParams.battery_model_name_eq, token: currentUser.token}))
  }

  const resetSearch = () => {
    setSearchParams({battery_model_name_eq: '', serialnumber_lteq: '', serialnumber_gteq: '' })
    getBatteryModels();
   
  }

  const handleSelectedBattery = (id, value) =>{
    let ids = [...selectedBatteries];
    value === true ? ids.push(id) : ids.splice(ids.indexOf(id), 1);
    setSelectedBatteries(ids)
  }

  const updateBatteries = (param) => {
    if(param === 'assign'){dispatch(assignBatteryUpgrade({selectedBatteries, selecttedUpgrade, token: currentUser.token}))}
    if(param === 'remove'){dispatch(removeBatteryUpgrade({selectedBatteries, selecttedUpgrade, token: currentUser.token}))}
    search();
  }

  const paginator = () =>{
    const pageTotal = Math.round(batteries.length/pageSize ) !== 0 ? Math.round(batteries.length/pageSize ) -1 : 1
    return (
      <div className='d-flex align-items-center'>
        <Button variant='light' disabled={page === 0}  className='btn-sm' onClick={e => setPage(page - pageSize)}>{'<<'}</Button>
          Page {page === 0 ? 1 : page /10 } of {pageTotal}
        <Button variant='light' disabled={(page === 0 ? 1 : page / 10) === pageTotal} className='btn-sm' onClick={e => setPage(page + pageSize)}>{'>>'}</Button>
      </div>
    )
  }

  const handleSelectAll = (param) =>  param ? setSelectedBatteries(batteries.map(battery => battery.id)) : setSelectedBatteries([]);

  return (
    <Container fluid>
      
      <SearchWidget searchParams={searchParams} searchHeaders={searchHeaders} setSearchParams={setSearchParams} search={search} resetSearch={resetSearch} />
      <Row>
        <Col>
          <br />
            {loading === true && <Col className='d-flex justify-content-center'><Spinner size='lg' animation="grow" /></Col>}
            {(loading === false && batteries.length !== 0) && 
              <div>
                <div className='d-flex justify-content-between'>
                  {paginator()}
                  <div>
                    <Form.Label>{translator('page size')}</Form.Label>
                    <Form.Select className='search-width' onChange={e => setPageSize(e.target.value)}>
                    <option value={10}>10</option>
                    <option value={50}>50</option>
                    <option value={100}>100</option>
                  </Form.Select>
                  </div>
                  
                </div>
                <br />
                <Table striped>
                  <thead>
                    <tr>
                      <th className='text-center'>{translator('select')} <FormCheck onChange={e => handleSelectAll(e.target.checked)}/></th>
                      <th className='text-center'>{translator('note')}</th>
                      <th className='text-center'>{translator('model')}</th>
                      <th className='text-center'>{translator('bms model')}</th>
                      <th className='text-center'>{translator('serial number')}</th>
                      <th className='text-center'>{translator('upgrades')}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {batteries.length !== 0 &&  batteries.batteries.slice(page, page +pageSize).map((battery, index) => {return(
                      <tr key={index}>
                        <td className='text-center'><Form.Check checked={selectedBatteries.includes(battery.id) || false} onChange={e => handleSelectedBattery(battery.id,  e.target.checked)}/></td>
                        <td className='text-center'>{battery.note}</td>
                        <td className='text-center'>{battery.model}</td>
                        <td className='text-center'>{battery.bms_model_id}</td>
                        <td className='text-center'>{battery.serialnumber}</td>
                        <td className='text-center'>
                          {battery.upgrades.length !== 0 && battery.upgrades.map(upgrade => <p key={upgrade.description}>{upgrade.description}</p>)}
                        </td>
                      </tr>
                    )})}
                  </tbody>
                </Table>
                {paginator()}
              </div>
            }
            
        </Col>
        <Col>
          <br />
          <br />
          {loading === true && <Col className='d-flex justify-content-center'><Spinner size='lg' animation="grow" /></Col>}
          {(loading === false && batteries.length !== 0) && 
            <div className='d-flex flex-column'>
              <h3>{translator('assign selected manifest to selected batteries')}</h3>
              {upgradeMessage.length !== 0 && 
                <Alert variant={upgradeMessage === 'sucess' ? 'success' : 'danger'}>{upgradeMessage}</Alert>
              }
              
              <br />
              <Button onClick={e => updateBatteries('assign')}>{translator('assign Manifest to batteries')}</Button>
              <br />
              <Button variant='danger' onClick={e => updateBatteries('remove')}>{translator('remove selected upgrade from selected batteries')}</Button>
              <br />
              <Table>
                <thead>
                  <tr>
                    <th>{translator('select')}</th>
                    <th>{translator('description')}</th>
                    <th>{translator('mandatory')}</th>
                    <th>{translator('allow downgrade')}</th>
                  </tr>
                </thead>
                <tbody>
                  {availableBatteryUpgrades.map((upgrade, index) => {return(
                    <tr key={index}>
                      <td><Form.Check checked={selecttedUpgrade === upgrade.id} onChange={e => setSelectedUpgrade(upgrade.id)}/></td>
                      <td>{upgrade.description}</td>
                      <td>{upgrade.mandatory === true ? 'true' : 'false'}</td>
                      <td>{upgrade.allow_downgrade === true ? 'true' : 'false'}</td>
                    </tr>
                  )})}
                </tbody>
              </Table>

            </div>
          }
        </Col>
      </Row>
        <br />
        <br />
    </Container>
  )
}

export default BatteriesIndexComponent