import React, {useState, useEffect} from 'react'
import {Table, ButtonGroup, Dropdown, DropdownButton, OverlayTrigger, Popover, Button} from 'react-bootstrap'

import { translator } from '../../scripts/translator'

function SimpleTable({tableElements, data, searchParams, setOrder, openEdit, openDelete, openModal, show}) {
  const [dateValues] = useState(['testdate', 'created_at', 'updated_at'])
  const [fileValues] = useState(['files'])
  const [popoverValues] = useState(['customer_issue_description', 'root_cause_analysis', 'notes' ])
  

  useEffect(() => {
    //console.log(data)
  },[data ])
  const pointer = (element) => searchParams['s'] === `${element} asc` ? <span onClick={e => setOrder(element)} className="material-symbols-outlined"> arrow_drop_down </span> : <span onClick={e => setOrder(element)} className="material-symbols-outlined"> arrow_drop_up </span>
  
  const showOverlay = (text) => <OverlayTrigger trigger={["hover", 'focus']} placement="left" overlay={showPopover(text)}><p className='pointer'>{`${text.slice(0, 9)} ...`}</p></OverlayTrigger>

  // makes a text box for fault description (to save space in the table)
  const showPopover = (text) => <Popover id="popover-basic"><Popover.Body>{text}</Popover.Body></Popover>

  const handleData = (report, value) => {
    if(dateValues.includes(value)){return report[value].replace('T', ' ').replace('Z', '').slice(0, -7)}
    if(fileValues.includes(value)){return(<ButtonGroup><DropdownButton size="sm" drop='up' variant="outline-secondary" as={ButtonGroup} title={`docs ${report[value].length}`} id="bg-nested-dropdown">{report[value].map((file, index) => <Dropdown.Item key={'file' + index}  href={file.url} className="text-decoration-underline text-primary" target="_blank">{file.name}</Dropdown.Item>)}</DropdownButton></ButtonGroup>)}
    if(popoverValues.includes(value)){return report[value].length !== 0 ?  showOverlay(report[value]) : ''}
    return report[value]
  }

  return (
    <Table striped hover size='sm' className='table-font'>
        <thead>
          <tr>
            {setOrder !== null && Object.keys(tableElements).map((key, index) => {return(
              <th key={index + key} className='pointer' onClick={e => setOrder(tableElements[key])}> {translator(key)} {pointer(tableElements[key])} </th>
            )})}
            {setOrder === null && Object.keys(tableElements).map((key, index) => {return(
              <th key={index + key}> {translator(key)}  </th>
            )})}
          </tr>
        </thead>
        <tbody>
          {data && data.map((report, index) => {
            return(
              <tr key={index} id={index}>
                {Object.values(tableElements).map((value, index) => {return(
                  <td key={value + index}  >
                    <div className='d-flex'>
                      {(value === 'actions' && !!show) && <Button id='delete' className='btn-sm d-flex action-button' onClick={e => show(report)}><span className="material-symbols-outlined pointer" >open_in_new</span></Button>}
                      {(value === 'actions' && !!openDelete)&& <Button variant='danger' id='delete' className='btn-sm d-flex action-button' onClick={e => openDelete(report)}><span className="material-symbols-outlined pointer" >delete</span></Button>}
                      {(value === 'actions' && !!openEdit)&& <Button variant='warning' id='edit' className='btn-sm d-flex action-button' onClick={e => openEdit(report)}><span className="material-symbols-outlined pointer"  >edit</span></Button> }
                      {(value === 'actions' && !!openModal ) && <Button id='open' className='btn-sm d-flex action-button' onClick={e => openModal(report)}><span className="material-symbols-outlined">preview</span></Button> }
                      {value !== 'actions' && handleData(report, value)}
                    </div>
                  </td>
                )})}
              </tr>
            )
          })}
        </tbody>
      </Table>
  )
}

export default SimpleTable