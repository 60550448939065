import React, { useEffect, useState } from 'react';
import { Container, Button, Col, Spinner, Form } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

import { deleteUser, getUsers, createUser, updateUser } from '../../state/userSlice';
import { getCompanies } from '../../state/CompaniesSlice';

import { clearParams, setOrder } from '../../scripts/helperMethods';

import DeleteModal from '../widgets/modals/DeleteModal';
import UserModal from './UserModal';
import SimpleTable from '../widgets/SimpleTable';
import SearchWidget from '../widgets/SearchWidget';
import { translator } from '../../scripts/translator';

function UsersIndexComponent() {
  const dispatch = useDispatch();
  const [deleteMessage, setDeleteMessage] = useState('');
  const [displayDeleteModal, setDisplayDeleteModal] = useState(false);
  const [displayUserModal, setDisplayUserModal] = useState(false)
  const [deletableUser, setDeletetableUser] = useState({});
  const [user, setUser] = useState(null);
  const [searchParams, setSearchParams] = useState({email_i_cont: '', role_eq: '', company_id_eq: '', s: 'email asc'})
  const [loading, setLoading] = useState(true);
  const [tableHeaders] = useState({'actions': 'actions', 'company': 'company_id', 'role': 'role', 'email': 'email'})
  const [searchHeaders, setSearchHeaders] = useState([])

  const current_user = useSelector(state => state.session.data);
  const users = useSelector(state => state.user.data);
  const companies = useSelector(state => state.companies.data)

  // triggers when current_user changes
  useEffect(() => {
    if (!!current_user) { // checks if current user is not undefined and not null 
      callUsers(); // gets all data related to users
    }
  }, [])

  // triggers when users changes
  useEffect(() => {
    if(!!users.message && users.message === "User successfully saved"){ // if user has a success message
      callUsers(); //update users list
    }
    if(Array.isArray(users.users) && users.users.length !== 0 ){ // if there are users
      setLoading(false) // closes loading spinner
    }
  }, [users])

  useEffect(() => {
    if(!!companies.companies && companies.companies.length !== 0 ){
      setSearchHeaders([
        {label: 'Email', subLables: null, params: ['email_i_cont'], options: null, type: 'text'},
        {label: 'Company', subLables: null, params: ['company_id_eq'], options: companies.companies.map(company => ({label: company.name, value: company.id})), type: 'select'}, 
        {label: 'Role', subLables: null, params: ['role_eq'], options: [{label: 'tv', value: 0}, {label: 'Haidi', value: 1}, {label: 'Supervisor', value: 2}, 
                                                                            {label: 'Admin', value: 3}, {label: 'Customer', value: 4}, {label: 'Api', value: 5} ], type: 'select'} 
      ])
    }
  }, [companies])

  useEffect(() => {
    callUsers()
  },[searchParams['s']])

  // sets up relevant data
  const callUsers = () => { 
    setLoading(true) // starts loading spinner
    dispatch(getCompanies({token: current_user.token, searchParams: {}})) // gets all companies
    dispatch(getUsers({token: current_user.token, searchParams})) // gets all users
  }

  // closes all modals
  const hideModals = () => {
    setDisplayUserModal(false)
    setDisplayDeleteModal(false);
  }

  // sets searc back to default values
  const resetSearch = () => clearParams(searchParams, setSearchParams)

  // opens delete modal
  const openDeleteModal = (user) => {
    setDeletetableUser(user) // sets what user should be deleted
    setDeleteMessage(`Are you sure you want to delete ${user.email} this action cannot be undone`) // makes a delete message
    setDisplayDeleteModal(true) // open the delete modal
  }
 

  // deletes user
  const deleteConfirmedUser = (user) => {
    dispatch(deleteUser({user: user, token: current_user.token})) // calls delete endpoint
    hideModals(); // closes all modal
    setDeletetableUser('') // resets delete user
  }

  const saveUser = (params) => {
    delete(params['password_confirmation'])
    if(!!user){
      dispatch(updateUser({id: user.id, user: params, token: current_user.token}));
    }else{
      dispatch(createUser({user: params, token: current_user.token}));
    }
    hideModals()
  }

  // opens modal in edit configuration
  const openEdit = (user) => {
    setUser(user) //sets the user that should be updated
    setDisplayUserModal(true)
  }

  // sets handle orders for user params
  const order= (element) => setOrder(searchParams, setSearchParams, element, callUsers)


  return (
    <Container fluid>
      <Container  className='search-container w-50'>
        <h1><span className="material-symbols-outlined">search</span></h1>
        <SearchWidget searchParams={searchParams} searchHeaders={searchHeaders} setSearchParams={setSearchParams} search={callUsers} resetSearch={resetSearch}/>
      </Container>
      <Button onClick={e => setDisplayUserModal(true)}>{translator('create user')}</Button>
      <br />
      <br />
      {loading === true &&
        <Col className='d-flex justify-content-center'>
          <Spinner size='lg' animation="grow" />
        </Col>
      }
      {loading === false &&
        <SimpleTable tableElements={tableHeaders} data={Array.isArray(users.users) ? users.users : []} searchParams={searchParams} setOrder={order} openEdit={openEdit} openDelete={openDeleteModal}/>  
      }
      {displayDeleteModal === true &&  <DeleteModal showModal={displayDeleteModal} confirmModal={deleteConfirmedUser} hideModal={hideModals} element={deletableUser} message={deleteMessage}/>}
      {<UserModal showModal={displayUserModal} hideModal={hideModals} save={saveUser} user={user} companies={companies} currentUser={current_user}/>}
    </Container>
  )
}


export default UsersIndexComponent

