import React, {useState, useEffect} from 'react'
import {Button, Row, Col, Container, Spinner, Table, ButtonGroup, DropdownButton, Dropdown} from 'react-bootstrap'
import {useDispatch, useSelector} from 'react-redux'

import SimpleTable from '../widgets/SimpleTable'
import NewBmsModelModal from './NewBmsModelModal'
import SearchWidget from '../widgets/SearchWidget'

import { setOrder } from '../../scripts/helperMethods'

import { getBmsModels, createBmsModel, updateBmsModel } from '../../state/bmsModelsSlice';
import { translator } from '../../scripts/translator'

function BmsModelIndexComponent() {
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const [page, setPage] = useState(0)
  const [currentBms, setCurrentBms] = useState(null)
  const [openNewModal, setOpenNewModal] = useState(false)
  const [searchParams, setSearchParams] = useState({name_i_cont: '', series_i_cont: '', wst_legacy_name_i_cont: '', wst_name_i_cont: '', continuous_discharge_current_eq: '', 
                                                    continuous_charge_current_eq: '', peak_discharge_current_eq: '',communication_i_cont: '', note_i_cont:'', s: ''})
  const [searchHeaders] = useState([
                                      {label: translator('name'), subLables: null, params: ['name_i_cont'], options: null, type: 'text'},
                                      {label: translator('series'), subLables: null, params: ['series_i_cont'], options: null, type: 'text'},
                                      {label: translator('wst_legacy_name'), subLables: null, params: ['wst_legacy_name_i_cont'], options: null, type: 'text'},
                                      {label: translator('wst_name'), subLables: null, params: ['wst_name_i_cont'], options: null, type: 'text'},
                                      {label: translator('continuous_discharge_current'), subLables: null, params: ['continuous_discharge_current_eq'], options: null, type: 'text'},
                                      {label: translator('communication'), subLables: null, params: ['communication_i_cont'], options: null, type: 'text'},
                                      {label: translator('note'), subLables: null, params: ['note_i_cont'], options: null, type: 'text'},
                                    ])

  const currentUser = useSelector(state => state.session.data)
  const bmsModels = useSelector(state => state.bmsModels.data)

  useEffect(() => {
    setLoading(true)
    fetchBmsModels()
  },[page])

  useEffect(() => {
    if(bmsModels.length !== 0){setLoading(false)}
    if(!!bmsModels.message){fetchBmsModels()}
  }, [bmsModels])

  const clearSearch = () => setSearchParams({name_i_cont: '', series_i_cont: '', wst_legacy_name_i_cont: '', wst_name_i_cont: '',  continuous_discharge_current_eq: '', 
                                             continuous_charge_current_eq: '', peak_discharge_current_eq: '', communication_i_cont: '', note_i_cont:'', s: ''})

  const fetchBmsModels = () => dispatch(getBmsModels({token: currentUser.token, params: searchParams}))
 
  const order= (element) => setOrder(searchParams, setSearchParams, element, fetchBmsModels)

  const hideModal = () => {
    setCurrentBms(null)
    setOpenNewModal(false)
  }

  const save = (params) =>{
    if(!(params.datasheet instanceof File)){delete params['datasheet']}
    const formData = new FormData()

    for(let key of Object.keys(params)){
      formData.append(`bms_model[${key}]`, params[key])
    }

    setOpenNewModal(false)
    !!currentBms ? dispatch(updateBmsModel({params: formData, id: currentBms.id, token: currentUser.token})) : dispatch(createBmsModel({params: formData, token: currentUser.token}))
    setCurrentBms(null)
  }

  const openEdit =  (bms)=> {
    setCurrentBms(bms)
    setOpenNewModal(true)
  }

  const downloadsCount = (bms) => {
    let count = 0;
      if(!!bms.datasheet){count++ }
    return(count)
  }

  

  return (
    <Container fluid> 
    <br />
    <br />
    <br />
    <Container>
      <h3>Search</h3>
      <SearchWidget searchParams={searchParams} searchHeaders={searchHeaders} setSearchParams={setSearchParams} search={fetchBmsModels} resetSearch={clearSearch} />
    </Container>
    <br />
    <Row className='mx-auto'>
      <Col>
        <br />
        {loading === true &&
          <Col className='d-flex justify-content-center'>
            <Spinner size='lg' animation="grow" />
          </Col>
        }
        <Button onClick={e => setOpenNewModal('true')}>{translator('create bms model')}</Button>
        <br />
        <br />
        {loading === false &&
          <Table striped hover size='sm' className='table-font'>
            <tr>
              <th>{translator('actions')}</th>
              <th>{translator('id')}</th>
              <th>{translator('name')}</th>
              <th>{translator('series')}</th>
              <th>{translator('wst legacy name')}</th>
              <th>{translator('wst name')}</th>
              <th>{translator('continuous discharge current')}</th>
              <th>{translator('continuous charge current')}</th>
              <th>{translator('peak discharge current')}</th>
              <th>{translator('communication')}</th>
              <th>{translator('note')}</th>
              <th>AFE</th>
              <th>Current sense resistance</th>
              <th>{translator('date')}</th>
              <th>{translator('files')}</th>
            </tr>
            {!!bmsModels.bms_models && bmsModels.bms_models.map(bms => {return(
              <tr>
                <td><Button variant='warning' id='edit' className='btn-sm d-flex action-button' onClick={e => openEdit(bms)}><span className="material-symbols-outlined pointer"  >edit</span></Button></td>
                <td>{bms.id}</td>
                <td>{bms.name}</td>
                <td>{bms.series}</td>
                <td>{bms.wst_legacy_name}</td>
                <td>{bms.wst_name}</td>
                <td>{bms.continuous_discharge_current}</td>
                <td>{bms.continuous_charge_current}</td>
                <td>{bms.peak_discharge_current}</td>
                <td>{bms.communication}</td>
                <td>{bms.note}</td>
                <td>{bms.afe}</td>
                <td>{bms.current_sense_resistance}</td>
                <td>{bms.created_at}</td>
                
                <td>
                  <ButtonGroup id='docs'>
                    <DropdownButton  size="sm" drop='up' variant="outline-secondary" as={ButtonGroup} title={`docs ${downloadsCount(bms)}`} id="bg-nested-dropdown">
                      
                      {!!bms.datasheet && <Dropdown.Item download href={bms.datasheet.url} className="text-decoration-underline text-primary" target="_blank">{translator('datasheet')}</Dropdown.Item>}
                    
                    </DropdownButton>
                  </ButtonGroup>
                </td>
              </tr>
            )})}
          </Table>
        }
        <br />
      </Col>
    </Row>
    <br />
    <br />
    <br />
    <NewBmsModelModal showModal={openNewModal} hideModal={hideModal} save={save} currentBms={currentBms}/>
  </Container>
  )
}

export default BmsModelIndexComponent