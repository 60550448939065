import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

export const getChargers = createAsyncThunk('getChargers', async (data) => {

  let searchString  = '?'
  if(!!data.params){
    for(let key of  Object.keys(data.params)){
      if(data.params[key].length !== 0){
        searchString = searchString + `q[${key}]=${data.params[key]}&`
      }
    }
  }

  const charger = await fetch(`${process.env.REACT_APP_API_URL}/chargers${searchString}`, {
    method: 'GET',
    headers: {"Content-Type": "application/json", token: data.token}
  })

  return(charger.json())
})

export const createCharger = createAsyncThunk('createCharger', async (data) => {
  const charger = await fetch(`${process.env.REACT_APP_API_URL}/chargers`, {
    method: 'POST',
    body: JSON.stringify({charger: data.params}),
    headers: {"Content-Type": "application/json", token: data.token}
  })

  return(charger.json())
} )

export const chargerSlice = createSlice({
  name: 'charger',
  initialState: {
    loading: false,
    data: [],
    error: null
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getChargers.fulfilled, (state, action) => {
      state.data = action.payload
    })
    builder.addCase(createCharger.fulfilled, (state, action) => {
      state.data = action.payload
    })
  }
})

export default chargerSlice.reducer