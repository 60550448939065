import React from 'react'

function WstLogoComponent() {
  return (

    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px" viewBox="0 0 56.69293 56.62128" style={{enableBackground: "new 0 0 56.69293 56.62128", height: "40px"}} >

      <g>
        <path style={{fill: '#022E70'}} d="M7.08661,28.31067c0-11.263,8.76501-20.45581,19.84253-21.18835V0C11.93237,0.73865,0,13.13068,0,28.31067
          C0,43.4906,11.93237,55.88263,26.92914,56.62128v-7.12231C15.85162,48.76642,7.08661,39.57361,7.08661,28.31067z"/>
        <path style={{fill: '#00001F'}} d="M56.69293,28.31067C56.69293,13.13068,44.76056,0.73859,29.76379,0v7.12231
          c11.07758,0.73254,19.84253,9.92529,19.84253,21.18835c0,11.263-8.76495,20.45575-19.84253,21.18829v7.12231
          C44.76056,55.88269,56.69293,43.4906,56.69293,28.31067z"/>
        <path style={{fill: '#F0F0F0'}} d="M39.58716,26.89331h7.1145c-0.30688-4.02094-1.89661-7.67657-4.37115-10.56232l-5.03467,5.03467
          C38.51392,22.93298,39.32861,24.82465,39.58716,26.89331z"/>
        <path style={{fill: '#C2B59B'}} d="M39.58716,29.72797c-0.64069,5.12634-4.69702,9.18268-9.82336,9.82336
          c-0.46533,0.05817-0.93622,0.0979-1.4173,0.0979s-0.95203-0.03973-1.41736-0.0979
          c-2.06866-0.25854-3.96033-1.07324-5.52765-2.29132l-5.03467,5.03467c2.88574,2.47455,6.54138,4.06427,10.56232,4.37115
          c0.4693,0.03583,0.93884,0.07159,1.41736,0.07159c0.47845,0,0.948-0.03577,1.4173-0.07159
          c9.04303-0.69012,16.24774-7.89484,16.93787-16.93787H39.58716z"/>
        <path style={{fill: '#D6D6D6'}} d="M29.76379,9.95544c-0.4693-0.03583-0.93884-0.07159-1.4173-0.07159
          c-0.47852,0-0.94806,0.03577-1.41736,0.07159C17.41675,10.6814,9.91968,18.61237,9.91968,28.31067
          c0,4.578,1.67938,8.75702,4.44275,11.97961l5.03467-5.03467c-1.49207-1.91986-2.38922-4.3252-2.38922-6.94495
          c0-5.78107,4.32959-10.54187,9.92126-11.24072c0.46533-0.05817,0.93628-0.0979,1.41736-0.0979s0.95197,0.03973,1.4173,0.0979
          c2.06866,0.25854,3.96033,1.07324,5.52765,2.29132l5.03467-5.03467C37.44037,11.85205,33.78467,10.26233,29.76379,9.95544z"/>
      </g>
      <g>
      </g>
      <g>
      </g>
      <g>
      </g>
      <g>
      </g>
      <g>
      </g>
    </svg>
  )
}

export default WstLogoComponent