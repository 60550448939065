import React, {useState, useEffect} from 'react'
import { Container, Modal, Button, Form, Alert} from 'react-bootstrap'
import {useDispatch, useSelector} from 'react-redux'

import { getCustomers } from '../../state/CustomerSlice'

import { translator } from '../../scripts/translator'

import QspecModal from '../widgets/modals/QspecModal'

function PurchaseOrderLinesModal({showModal, hideModal, savePol, currentPol, purchaseOrderId, currentUser}) {
  const dispatch = useDispatch();
  const [showQspec, setShowQspec] = useState(false)
  const [qspecFocus, setQspecFocus] = useState(false)
  const [params, setParams] = useState({company_id: null, product_number: '', amount: 0, aqua_enabled: true, wes_enabled: true,  amount_completed: 0, 
                                        po_line_id: '', expected_production_done_date: '', actual_production_done_date: '',  purchase_order_id: 
                                        purchaseOrderId, note: '',  serialNumberBegin: 0, serialNumberEnd: '', internal_order_for_pack: false, 
                                        all_received_date: '',  total_shipped_amount: 0, cell_quality_report: null, technical_documents: [],  
                                        qspec: '', wes_spec: '', acir: 0, voltage: 0})
  const [showQspecs, setShowQspecs] = useState(false)
  const [showWes, setShowWes] = useState(false)
  const [errorMessages, setErrorMessages] = useState([])
  const [warnings, setWarnings] = useState([])
  const customers = useSelector(state => state.customers.data)

  useEffect(() => {
    getCompanies();
    handleErrors(params)
    handleWarnings(params)
  },[])
  
  useEffect(() => {
    if(showModal === false){ resetParams() }
    if(Object.keys(currentPol).length !== 0){ setPolParams() }
  },[showModal, currentPol]) // eslint-disable-line react-hooks/exhaustive-deps

  const getCompanies = () => dispatch(getCustomers({token: currentUser.token}))

  const setPolParams = () =>{
    const data = {...params}
    for(let key of Object.keys(params)){   
      data[key] = currentPol[key]
    }
    data['qspec'] = !!currentPol.qspecs && currentPol.qspecs.length !== 0 ? stringifyQspec(currentPol.qspecs[currentPol.qspecs.length -1]['data']) : ''
    data['wes_spec'] = !!currentPol.wes_specs && currentPol.wes_specs.length !== 0 ? stringifyWesSpec(currentPol.wes_specs[currentPol.wes_specs.length -1]['data']) : ''
    setParams(data)
    handleErrors(data)
    handleWarnings(data)
  } 

  const stringifyQspec = (qspec) => JSON.stringify(qspec).replaceAll(',', ', \n')
  const stringifyWesSpec = (wes_spec) => JSON.stringify(wes_spec).replaceAll(',', ', \n')

  const updateParams = (key, value) => {
    const data = {...params};
    if(key === 'technical_documents'){
      data[key] = [...params[key], value]
    }else{
      key === 'aqua_enabled' ? data[key] = !params[key] : data[key] = value;
    }
    setParams(data);
    handleErrors(data);
    handleWarnings(data);
  }

  const deleteDocument = async (file) => {
    const data = {...params};
    data['technical_documents'] = data['technical_documents'].filter(document => file !== document);
    setParams(data);

    if(typeof file.id  != 'undefined'){
      const data  = await fetch( `${process.env.REACT_APP_API_URL}/purchase_order_lines/delete_file?id=${file.id}`, {
        method: 'GET',
        headers: {token: currentUser.token}
      })
      const response = await data.json()
    }
  }

  const resetParams = () => setParams({company_id: null, product_number: '', amount: 0, aqua_enabled: true, wes_enabled: true,  amount_completed: 0, 
                                        po_line_id: '', expected_production_done_date: '', actual_production_done_date: '',  purchase_order_id: 
                                        purchaseOrderId, note: '',  serialNumberBegin: 0, serialNumberEnd: '', internal_order_for_pack: false, 
                                        all_received_date: '',  total_shipped_amount: 0, cell_quality_report: null, technical_documents: [],  
                                        qspec: '', wes_spec: '', acir: 0, voltage: 0});
  
  const handlePol = () => {
    const data = {...params}
    data['qspec'] = params['qspec'].replace('""', 0)
    data['wes_spec'] = params['wes_spec'].replace('""', 0)
    if(errorMessages.length === 0){
      savePol(data);
      hideModal()
    }else{
      setErrorMessages(errorMessages)
      setTimeout(() => {
        setErrorMessages([]);
        setWarnings([])
      }, 5000)
    }
  }

  const handleWarnings = (data) => {
    let messages = []
    try{
      if(!JSON.parse(data.qspec)['internalResistanceTest'] || JSON.parse(data.qspec)['internalResistanceTest'] === false){
        messages.push('Be sure this qspec is for a prototype before you save')
      }
    }catch{
      console.log('somethng wen wrong')
    }
    setWarnings(messages)
  }

  const handleErrors = (data) => {
    let errors = [];
    if(data.po_line_id.length === 0){errors.push(translator('purchase order line id not filled in'))}
    if(data.product_number.length < 4){errors.push(translator('product number too short'))}
    if(data.amount === 0){errors.push(translator('amount not set'))}
    if(data.po_line_id.length === 0){errors.push('po line id not filled in')}
    if(data.product_number.length < 4){errors.push("product number is too short (minimum is 4 characters) and equal an existing product")}
    if(data.amount === 0){errors.push('Amount not set')}
    // if(data['aqua_enabled'] === true && data['qspec'].length === 0){errors.push('aqua tests cannot be enabled while qspec field is empty')}
    // if(data['wes_enabled'] === true && data['wes_spec'].length === 0){errors.push('Wes tests cannot be enabled while wes spec field is empty')}
    if(data['qspec'].length !== 0) { 
      try {
        JSON.parse(data['qspec'])
      } catch (error) {
        errors.push('Qspec is not JSON')
      }
    }
    if(data['wes_spec'].length !== 0) { 
      try {
        JSON.parse(data['wes_spec'])
      } catch (error) {
        errors.push('Wes spec is not JSON')
      }
    }
    setErrorMessages(errors)
  }

  const isValidJson = (data) => {
    try{
      JSON.parse(data)
      return true
    }catch (error) {
      return false
    }
  }

  const openModal = () => setShowQspec(true)  

  const closeModal = () => setShowQspec(false)
  

  return (
    <Modal size='xl' show={showModal} onHide={hideModal}>
        <Modal.Header closeButton>
          <Modal.Title>{Object.keys(currentPol).length !== 0 ? 'Update Purchase Order Line' : 'New Purchase Order Line'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          
          <Container className='w-100 d-flex flex-wrap justify-content-between' >
            <div style={{minWidth: '450px'}}>
              <div className='mb-4'>
                <Form.Label>{translator('cusotmer')}</Form.Label>
                <Form.Select id='customer' value={params.company_id || '' } onChange={e => updateParams('company_id', parseInt(e.target.value))} >
                  <option value=''>{translator('select customer')}</option>
                  {Array.isArray(customers) && customers.map( (customer, index) => <option key={customer.name + index}  value={customer.id}>{customer.name}</option>)}
                </Form.Select>
              </div>
              <div className='mb-4'>
                <Form.Label>{translator('po line id')}</Form.Label>
                <Form.Control id='pol-id' value={params.po_line_id || ''}  onChange={e => updateParams('po_line_id', e.target.value)} type='number'/>
              </div>
              <div className='mb-4'>
                <Form.Label>{translator('product number')}</Form.Label>
                <Form.Control id='product-number' value={params.product_number || ''}  onChange={e => updateParams('product_number', e.target.value)}/>
              </div>
              <div className='mb-4'>
                <Form.Label>{translator('aqua enabled')}</Form.Label>
                <Form.Check id='aqua-enabled' checked={params.aqua_enabled}  onChange={e => updateParams('aqua_enabled', e.target.checked)} type='switch'/>
              </div>
              <div className='mb-4'>
                <Form.Label>{translator('wes enabled')}</Form.Label>
                <Form.Check id='wes-enabled' checked={params.wes_enabled}  onChange={e => updateParams('wes_enabled', e.target.checked)} type='switch'/>
              </div>
              <div className='mb-4'>
                <Form.Label>{translator('internal order for pack')}</Form.Label>
                <Form.Check id='internal-order-for-pack' checked={params.internal_order_for_pack}  onChange={e => updateParams('internal_order_for_pack', e.target.checked)} type='switch'/>
              </div>
              <div className='mb-4'>
                <Form.Label>{translator('note')}</Form.Label>
                <Form.Control id='note' value={params.note || ''}  onChange={e => updateParams('note', e.target.value)} as='textarea'/>
              </div>
              <div className='mb-4'>
                <Form.Label>{translator('acir')}</Form.Label>
                <Form.Control id='note' value={params.acir || ''}  onChange={e => updateParams('acir', e.target.value)} type='number'/>
              </div>
              <div className='mb-4'>
                <Form.Label>{translator('voltage')} (mv)</Form.Label>
                <Form.Control id='note' value={params.voltage || ''}  onChange={e => updateParams('voltage', e.target.value)} type='number'/>
              </div>
              <div className='mb-4'>
                <Form.Label>{translator('expected production done date')}</Form.Label>
                <Form.Control id='expected-production-done-date' value={params.expected_production_done_date || ''} onChange={e => updateParams('expected_production_done_date', e.target.value)} type='date' />
              </div>
              <div className='mb-4'>
                <Form.Label>{translator('amount')}</Form.Label>
                <Form.Control id='amount' value={params.amount || ''}  onChange={e => updateParams('amount', e.target.value)} type='number'/>
              </div>
              <div  className='mb-4'>
                <Form.Label>{translator('amount complete')}</Form.Label>
                <Form.Control id='amount-completed' value={params.amount_completed || ''}  onChange={e => updateParams('amount_completed', e.target.value)} type='number'/>
              </div>
              <div  className='mb-4'>
                <Form.Label>{translator('serial number begins')}</Form.Label>
                <Form.Control id='serial-number-begins' value={params.serialNumberBegin || ''}  onChange={e => updateParams('serialNumberBegin', e.target.value)}/>
              </div>
              <div className='mb-4'>
                <Form.Label>{translator('serial number end')}</Form.Label>
                <Form.Control id='serial-number-end' value={params.serialNumberEnd || ''}  onChange={e => updateParams('serialNumberEnd', e.target.value)}/>
              </div>
            </div>
            <div style={{minWidth: '450px'}}>
              <div  className='mb-5'>
                <Form.Label>{translator('cell quality report')}</Form.Label>
                <Form.Control id='cell-quality-report' onChange={e => updateParams('cell_quality_report', e.target.files[0])} type='file'/>
              </div>
              <div className='mb-5'>
                <Form.Label>{translator('technical documents')}</Form.Label>
                <Form.Control id='technical-documents' onChange={e => updateParams('technical_documents', e.target.files[0] )} type='file'/>
              </div>
            
              {params.technical_documents.length !== 0 && params.technical_documents.map((document, index) => { return(
                <div className='w-100 d-flex justify-content-between' key={document.name + index}>
                  <p>{document.name}</p>
                  <span onClick={e => deleteDocument(document)} className="material-symbols-outlined pointer red-color">delete</span>
                </div>
              )})}

              {!!params.cell_quality_report && <p>{'Cell Quality report (uploaded) '}</p> }

              {(currentUser.role === 'admin' || currentUser.role === 'supervisor') &&
              <>
                  <div>
                    <div className='mb-1'>
                      <Form.Label>{translator('show old qspec')}</Form.Label>
                      <Form.Check id='show-old' checked={showQspecs}  onChange={e => setShowQspecs(e.target.checked)} type='switch'/>
                    </div>
                    <div className='mb-1 w-100 ' id='old-qspecs' style={{maxHeight: '100px', overflowX: 'hidden', overflowY: 'scroll' }}>
                      {(showQspecs === true &&  !!currentPol.qspecs) && currentPol.qspecs.map((spec, index) => <p><a id='old-spec' className='pointer' onClick={e => updateParams('qspec', stringifyQspec(currentPol.qspecs[index].data))}>{spec.created_at.split('T')[0]}</a></p>)}
                    </div>
                    <br />
                    <div>
                      <Form.Label>{translator('qspec')}</Form.Label>
                      <Form.Control id='qspec' style={{height: '250px'}} value={params.qspec || ''}  onChange={e => updateParams('qspec', e.target.value)} as='textarea'/>
                      <br />
                      {(!!params['qspec'] && isValidJson(params['qspec']) ) &&<Button onClick={e => openModal()}>Show Current qspec</Button>}
                    </div>
                  </div>
                  <br />
                  <div>
                    <div>
                      <Form.Label>{translator('show old wes spec')}</Form.Label>
                      <Form.Check id='show-old' checked={showWes}  onChange={e => setShowWes(e.target.checked)} type='switch'/>
                    </div>
                    <div className=' w-100 ' id='old-qspecs' style={{maxHeight: '100px', overflowX: 'hidden', overflowY: 'scroll' }}>
                      {(showWes === true &&  !!currentPol.wes_specs) && currentPol.wes_specs.map((spec, index) => <p><a id='old-spec' className='pointer' onClick={e => updateParams('wes_spec', stringifyQspec(currentPol.wes_specs[index].data))}>{spec.created_at.split('T')[0]}</a></p>)}
                    </div>
                    <br />
                    <div>
                      <Form.Label>{translator('wes spec')}</Form.Label>
                      <Form.Control id='wes-spec' style={{height: '250px'}} value={params.wes_spec || ''}  onChange={e => updateParams('wes_spec', e.target.value)} as='textarea'/>
                    </div>
                  </div>
                </>
              } 
            </div>
           
            <br />
          </Container>
            {warnings.length !== 0 && warnings.map((warning, index) => {return(
              <Alert key={"warning" + index} variant='warning'>
                {warning}
              </Alert>
            )})}
            {errorMessages.length !== 0 && errorMessages.map((message, index) => {return(
              <Alert key={index} variant='danger'>
                {message}
              </Alert>
            )})}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="default" onClick={hideModal}>{translator('cancel')}</Button>
          <Button onClick={e => handlePol(params)} disabled={errorMessages.length !== 0}>
            {translator('save')}
          </Button>
          {(showModal === true && isValidJson(params['qspec'])) && <QspecModal showModal={showQspec} hideModal={closeModal} report={ JSON.parse(params['qspec'])['params']}/>}
        </Modal.Footer>
      </Modal>
  )
}

export default PurchaseOrderLinesModal