import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

export const getBatteryModels = createAsyncThunk('getBatteryModels', async (data) => {
  const batteryModels = await fetch(`${process.env.REACT_APP_API_URL}/battery_models`, {
    method: 'GET',
    headers: {"Content-Type": "application/json", token: data.token}
  })

  return(batteryModels.json())
})

export const batteryModelsSlice = createSlice({
  name: 'batteryModels',
  initialState: {
    loading: true, 
    data: [],
    error: null
  },
  reducers: {
  }, 
  extraReducers: (builder) =>{
    builder.addCase(getBatteryModels.fulfilled, (state, action) => {
      state.data = action.payload
    })
  }
})

export default batteryModelsSlice.reducer