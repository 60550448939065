import React, {useState, useEffect} from 'react'
import { Modal, Button, Alert, Form, Spinner} from 'react-bootstrap'
import { Typeahead } from 'react-bootstrap-typeahead'
import { useSelector } from 'react-redux'

import { updateParams } from '../../scripts/helperMethods'
import { translator } from '../../scripts/translator'

function CompanyModal({showModal, hideModal, currentCompany, save}) {
  const customer = useSelector(state => state.customers.data);
  const supplier = useSelector(state => state.supplier.data);
  const [params, setParams] = useState({name: '', type: 'Supplier'});
  const [errors, setErrors] = useState([]);
  const [loading, setLoading] = useState(false);
  const [usedNames, setUsedNames] = useState([])

  // runs when current company is set
  useEffect(() => {
    if(!!currentCompany){ // if current company issnt null and not undefined
      setUpdate() // populate params
    }
  }, [currentCompany])

  // runs when params get changed
  useEffect(() => {
    handleErrors(); // checks for errors
  },[params])

  // populates params with current company data
  const setUpdate = () => setParams({name: currentCompany['name'], type: currentCompany['type']})
  
  // closes modal when close and cancel buttons are pressed 
  const closeModal = () => {
    setLoading(false)// stops loading spinner
    setParams({name: '', type: 'Supplier'}); // resets params
    hideModal(); // calls index components hide modals 
  }

  // builds a list of used company names
  const handleList = () => {
    const values = params.type === 'Supplier' ? supplier : customer // checks if the list should be made of suppliers or customers 
    const names = values.map(val => val.name) // itterates over either suppliers or customers and makes a list of names 
    return names // returns list of names
  }
  
  // makes a list of errors
  const handleErrors = () => {
    console.log(params.name)
    let errorMessages =  []; // makes empty array 
    const values = params.type === 'Supplier' ? supplier : customer // checks if the errors should be based on suppliers or customers
    const names = values.map(val => val.name) // makes a list of names
    if(names.includes(params['name'])){errorMessages.push('Customer or supplier allready exists')}
    if(names.includes(params['name'][0])){errorMessages.push('Customer or supplier allready exists')} // if typed in name is included in list add errors 
    console.log(errorMessages)
    setErrors(errorMessages) // sets error message state 
  }

  // calls index components 
  const saveCompany = () => save(params)

  return (
    <Modal show={showModal} onHide={closeModal}>
    <Modal.Header closeButton>
      <Modal.Title>{!!currentCompany ? `${translator('update company')} (${currentCompany.name})` : translator('create company') }</Modal.Title>
    </Modal.Header>
    <Modal.Body>
        <div>
          <div className='mb-4'>
            <Form.Label>{translator('type')}</Form.Label>
            <Form.Select value={params['type']} onChange={e => updateParams(params, setParams, 'type',  e.target.value)}>
              <option value="Supplier">Supplier</option>
              <option value="Customer">Customer</option>
            </Form.Select>
          </div>
          <div>
            <Form.Label>{translator('customer name')}</Form.Label>
            <Typeahead id="basic-typeahead-single" options={handleList()} onChange={(text, event) =>updateParams(params, setParams, 'name',text)} onInputChange={(text, event) => updateParams(params, setParams, 'name',text)}/>
          </div>
          <br />
          {errors.length !== 0 && errors.map(error => <Alert key={error} variant='danger' className='w-100'>{error}</Alert>) }
        </div>

      </Modal.Body>
    <Modal.Footer>
      <Button variant="default" onClick={closeModal} >{translator('cancel')}</Button>
      <Button onClick={e => saveCompany()} disabled={errors.length !== 0}>{loading ? <Spinner animation="border" /> : translator('save')}</Button>
    </Modal.Footer>
  </Modal>
  )
}

export default CompanyModal