import React, {useEffect, useState} from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {Container, Col, Row, Spinner} from 'react-bootstrap'

import { getWesReports } from '../../state/WesReportsSlice';

import { setOrder, clearParams } from '../../scripts/helperMethods';
import { translator } from '../../scripts/translator';

import SearchWidget from '../widgets/SearchWidget';
import SimpleTable from '../widgets/SimpleTable';
import WesReportModal from './WesReportModal';

function WesReportsIndexComponent() {
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false)
  const [loading, setLoading] = useState(false)
  const [currentReport, setCurrentReport] = useState({})
  const [downloadLink, setDownloadLink] = useState('')
  const [searchParams, setSearchParams] = useState({report_version_eq: '', purchase_order_line_id_i_cont: '', user_id_eq: '', test_type_eq: '',  model_i_cont: '', serial_number_gteq: '', serial_number_lteq: '', s: '' })
  const [searchHeaders, setSearchHeaders] = useState([
                                                      {label: translator('serial number'), subLables: ['More than or equal to', 'Less than or equal to'], params: ['serial_number_gteq', 'serial_number_lteq'], options: null, type: 'text'},
                                                      {label: translator('report version'), subLables: [], params: ['report_version_eq'], options: null, type: 'text'},
                                                      {label: translator('purchase order line id'), subLables: [], params: ['purchase_order_line_id_i_cont'], options: null, type: 'text'},
                                                      {label: translator('user'), subLables: [], params: ['user_id_eq'], options: [], type: 'select'},
                                                      {label: translator('test type'), subLables: [], params: ['test_type_eq'], options: [{label: 'Superpower', value: 'superpower'}, {label: 'Lithium balance', value: 'lithium balance'}], type: 'select'},
                                                      {label: translator('model'), subLables: [], params: ['model_i_cont'], options: null, type: 'text'},  
                                                    ]) 
  const [tableElements] = useState({id: 'id', date: 'created_at', 'report version': 'report_version', user: 'user', 
                                    'test type': 'test_type', Model: 'model', 'serial number': 'serial_number', 
                                    'purchase order line': 'purchase_order_line',  'actions': 'actions' })
  const wesReports = useSelector(state => state.wesReports.data)
  const currentUser = useSelector(state => state.session.data)

  useEffect(() => {
    setLoading(true)
    getReports()
  },[])

  useEffect(() => {
    if(!!wesReports['wes_reports'] && wesReports['wes_reports'].length !== 0 ){
      setLoading(false)
    }
  }, [wesReports])

  const getReports = () => {
    const params = {...searchParams}
    if(currentUser.role === 'customer'){
      params['purchase_order_line_company_id_eq'] = currentUser.company_id
    }
    let searchString = `?`;
    for (const key of Object.keys(params)) {
      if (params[key].length !== 0) {
        searchString = searchString + `&q[${key}]=${params[key]}`
      }
    }

    setDownloadLink(`${process.env.REACT_APP_API_URL}/wes_reports/download_excel${searchString}` )
    dispatch(getWesReports({searchParams: params, token: currentUser.token}))
  }
  
  const clearSearch = () => clearParams(searchParams, setSearchParams)
  

  const order= (element) => setOrder(searchParams, setSearchParams, element, getReports)

  const openModal = (report) => { 
    setCurrentReport(report.report)
    setShowModal(true)
  }

  const closeModal = () => {
    setCurrentReport({})
    setShowModal(false)
  }

  return (
    <Container fluid>
      <Container >
        <br />
        <br />
        {(!!currentUser && currentUser.role !== 'customer') && <h3>Search</h3>}
        {(!!currentUser && currentUser.role !== 'customer') && <SearchWidget searchParams={searchParams} searchHeaders={searchHeaders} setSearchParams={setSearchParams} search={getReports} resetSearch={clearSearch}/>}
       <div>
        <a href={downloadLink} className='btn btn-primary d-flex' style={{width: '80px', position: 'relative', right: '-100%'}} rel="noopener noreferrer"><span className="material-symbols-outlined pointer" >download</span>{translator('excel')}</a>
       </div>
       
      </Container>
      <br />
      <Row className='mx-auto'>
        <Col>
          <br />
          {loading === true &&
            <Col className='d-flex justify-content-center'>
              <Spinner size='lg' animation="grow" />
            </Col>
          }
          {loading === false &&
            <SimpleTable tableElements={tableElements}  data={wesReports.wes_reports} searchParams={searchParams} setOrder={order} openModal={openModal}/>
          }
          <br />
        </Col>
      </Row>
      <br />
      <br />
      <br />
      <WesReportModal showModal={showModal} hideModal={closeModal} report={currentReport}/>
    </Container>
  )
}

export default WesReportsIndexComponent