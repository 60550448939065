import React, {useEffect,useState} from 'react';
import {Container, Form, Button, Card, Spinner } from 'react-bootstrap';
import {useDispatch, useSelector} from 'react-redux';

import { getOverideCodes, createOverideCodes } from '../../state/OverrideCodesSlice';

import SimpleTable from '../widgets/SimpleTable';
import { translator } from '../../scripts/translator';

function OverrideCodesComponent() {
  const dispatch = useDispatch();
  const currentUser = useSelector(state => state.session.data);
  const data = useSelector(state => state.overrideCodes.data);
  const [loading, setLoading] = useState(true);
  const [token, setToken] = useState('');

  useEffect(() => {
    getData();
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() =>{
    if(data.length !== 0){
      setLoading(false);
    }
  },[data])

  const getData = () => {
    dispatch(getOverideCodes({token: currentUser.token}))
  }

  const generateToken = () => {
    dispatch(createOverideCodes({token: currentUser.token, params: {overridecode: {token: token}}}))
    getData()
  }


  return (
    <Container>
      <br />
      <br />
      {loading === true &&
        <Container className='d-flex justify-content-center'>
          <Spinner size='lg' animation="grow" />
        </Container>
      }
      {loading === false &&
        <Card>
          <Card.Body>
            <Form.Control value={token} onChange={e => setToken(e.target.value)} Placeholder='Token'/>
            <br/>
            <Button className='w-100' onClick={e => generateToken()}>{translator('create override code')}</Button>
            <br />
            <br />
            <SimpleTable tableElements={{'user': 'user', 'token': 'token', 'code': 'code_string'}} data={data} searchParams={null} setOrder={null} openEdit={null} openDelete={null} />
            
          </Card.Body>
        </Card>
      }
    </Container>
  )
}

export default OverrideCodesComponent