import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";


export const getUpgradeManifest = createAsyncThunk('getUpgradeManifest', async (data) => {
  let searchString  = '?'

  if(!!data.params){
    for(let key of  Object.keys(data.params)){
      if(data.params[key].length !== 0){
        searchString = searchString + `q[${key}]=${data.params[key]}&`
      }
    }
  }

  const manifest = await fetch(`${process.env.REACT_APP_API_URL}/upgrade_manifests${searchString}`, {    
    method: 'GET',
    headers: {"Content-Type": "application/json", token: data.token}
  })

  return(manifest.json())
})

export const saveUpgradeManifest = createAsyncThunk('saveUpgrademanifest', async (data) => {
  const manifest = await fetch(`${process.env.REACT_APP_API_URL}/upgrade_manifests`, {    
    method: 'POST',
    headers: {"Content-Type": "application/json", token: data.token},
    body: JSON.stringify({upgrade_manifest: data.params})
  })

  return(manifest.json())
})

export const upgradeManifestSlice = createSlice({
  name: 'upgrademanifest', 
  initialState: {
    loading: false,
    data: [], 
    error: null
  }, 
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getUpgradeManifest.fulfilled, (state, action) => {
      state.data = action.payload
    });
    builder.addCase(saveUpgradeManifest.fulfilled, (state, action) => {
      state.data = action.payload
    })
  }
})

export default upgradeManifestSlice.reducer;