import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

export const getOverview = createAsyncThunk('getOverview', async (data) => {
  let searchString = `?page=${data.page}&pagesize=${data.pageSize}`

  for(const key of Object.keys(data.searchParams)){
    if(data.searchParams[key].length !== 0){
     searchString = searchString + `&q[${key}]=${data.searchParams[key]}`
    }
  }

  const overview = await fetch(`${process.env.REACT_APP_API_URL}/overviews${searchString}`, {
    method: 'GET',
    headers: {'ContentType': 'application/json', token: data.token}
  })

  return(overview.json())
})

export const overviewSlice = createSlice({
  name: 'overview',
  initialState: {
    loading: false,
    data: [],
    error: null
  },
  reducers: {},
  extraReducers: (builder) => { 
    builder.addCase(getOverview.fulfilled, (state, action) => {
      state.data = action.payload
    });
  }
})

export default overviewSlice.reducer;