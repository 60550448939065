import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

export const createShipment = createAsyncThunk('createShipment', async (data) => {
  const shipment = await fetch(`${process.env.REACT_APP_API_URL}/shipments`, {
    method: 'POST',
    headers: {token: data.token},
    body: data.params
  })

  return(shipment.json())
})

export const updateShipment = createAsyncThunk('updateShipment', async (data) => {
  const shipment = await fetch(`${process.env.REACT_APP_API_URL}/shipments/${data.id}`, {
    method: 'PUT',
    headers: {token: data.token},
    body: data.params
  })

  return(shipment.json())
})

export const deleteShipment = createAsyncThunk('deleteShipment', async (data) =>{
  const shipment = await fetch(`${process.env.REACT_APP_API_URL}/shipments/${data.id}`, {
    method: 'DELETE',
    headers: {token: data.token}
  })

  return(shipment.json())
})

export const deleteFiles = createAsyncThunk('deleteFiles', )

export const shipmentSlice = createSlice({
  name: "shipment",
  initialState: {
    loading: true,
    data: '',
    error: null
  },
  reducers: {
    resetShipment(state, action){
      state.data = ''
    }
  },
  extraReducers: (builder) =>{
    builder.addCase(createShipment.fulfilled, (state, action) => {
      state.data = action.payload
    });
    builder.addCase(updateShipment.fulfilled, (state, action) => {
      state.data = action.payload
    });
    builder.addCase(deleteShipment.fulfilled, (state, action) => {
      state.data = action.payload
    });
    builder.addCase(deleteFiles.fulfilled, (state, action) => {
      state.data = action.payload
    })
  }
})

export const {resetShipment} = shipmentSlice.actions;

export default shipmentSlice.reducer;