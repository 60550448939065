import React, {useState, useEffect} from 'react'
import { Modal, Button, Card, Form, Table, Tab, Tabs} from 'react-bootstrap'
import { translator } from '../../../scripts/translator'

import { qspecHelper } from '../../../scripts/QspecHelper'

function QspecModal({showModal, hideModal, report}) {
  const [activeTab, setActiveTab] = useState('General settings')
  const [qspecPositions] = useState({'ov threshold': ['0x07', 2], 'ov recovery': ['0x07', 6], 'ov delay': ['0x07', 3], 'ov recovery delay': ['0x07', 4], 'uv threshold': ['0x07', 4], 
                                      'uv recovery': ['0x07', 7], 'uv delay': ['0x07', 5], 'uv recovery delay': ['0x07', 26], 'occ threshold': ['0x07', 1], 'occ delay': ['0x07', 22], 
                                      'ocd1 threshold': ['0x07', 20], 'ocd1 delay': ['0x07', 21], 'ocd2 threshold': ['0x07', 8], 'ocd2 delay': ['0x07', 9], 'sc threshold': ['0x07', 23], 
                                      'sc delay': ['0x07', 24], 'balv threshold': ['0x07', 18], 'balv delta': ['0x07', 19],  'balance time': ['0x0B', 16], 
                                      'occ recovery auto recovery delay': ['0x0B', 12], 'occ recovery auto recovery lock': ['0x0B', 13], 'sc auto recovery delay': ['0x0B', 14], 
                                      'sc auto recovery lock': ['0x0B', 15], 'pack voltage high': ['0x0D', 2], 'pack voltage low': ['0x0D', 4], 'cell voltage high': ['0x0D', 5], 
                                      'cell voltage low': ['0x0D', 6], 'chg threshold': ['0x0D', 7], 'dsg threshold': ['0x0D', 8], 'cell temp alarm otc charge': ['0x0D', 9], 
																			'soc low threshold': ['0x0D', 21],'cell temp alarm otc discharge': ['0x0D', 11], 'cell temp alarm utc charge': ['0x0D', 10], 'cell temp alarm utc discharge': ['0x0D', 12], 
                                      'ambient temp otc': ['0x0D', 19], 'ambient temp utc': ['0x0D', 20], 'mos temp otc': ['0x0D', 13], 'mos temp ot recovery': ['0x0D', 20],
																			'shut down voltage': ['0x70', 0], 'shut down delay': ['0x07', 2], 'mos otp': ['0x70', 3], 'mos otp recovery': ['0x70', 4], 
																			'mos ot delay': ['0x70', 5], 'ambient otp': ['0x70', 10], 'ambient otp recovery': ['0x70', 10], 'ambient utp': ['0x70', 13], 
																			'self dsg rate': ['0x07', 6], 'cycle cap': ['0x07', 7], 'pack full charge voltage': ['0x6E', 1], 'pack full charge current': ['0x6E', 3], 
																			'full charge delay': ['0x6E', 10], 'tov threshold': ['0x0B', 3], 'tov recovery': ['0x0B', 4], 'tov delay': ['0x0B', 10], 
																			'tov recovery delay': ['0x0B', 18], 'tuv threshold': ['0x0B', 6], 'tuv recovery': ['0x0B', 9], 'tuv delay': ['0x0B', 11], 
																			'tuv recovery delay': ['0x0B', 19], 'ambient utp recovery': ['0x70', 13], 'soc 0 voltage': ['0x70', 9]})

  
  const getValue = (name, type) => {
    if(!!report){
      const position = qspecPositions[name]
      const qspecValue = report[position[0]][position[1]]
      if(type === 'dropdown'){return(qspecHelper[name][qspecValue])}
      if(type === 'text'){return(qspecValue)}
    }
  }
	

  return (
    <Modal fullscreen={true} show={showModal} onHide={hideModal}>
      <Modal.Header  closeButton>
        <Modal.Title>{translator('bms parameters')}</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Tabs id="controlled-tab-example" defaultActiveKey={activeTab} onSelect={(k) => setActiveTab(k)}>
					<Tab eventKey='General settings' title='General settings'>
						<div className='d-flex justify-content-between'>
							<Card style={{width: '33%'}}>
								<Card.Header>Parameter 1</Card.Header>
								<Card.Body>
									{[
										'ov threshold', 'ov recovery', 'ov delay', 'ov recovery delay', 
										'uv threshold', 'uv recovery', 'uv delay', 'uv recovery delay',
										'occ threshold', 'occ delay', 'ocd1 threshold', 'ocd1 delay',
										'ocd2 threshold', 'ocd2 delay', 'sc threshold', 'sc delay',
										'balv threshold', 'balv delta'      
									].map((element) => {return(
										<div className='d-flex justify-content-between align-items-center mb-2'>
											<Form.Label>{translator(element)}</Form.Label>
											<Form.Select className='w-50' value={!!report &&  getValue(element, 'dropdown')} disabled={true}>
												{qspecHelper[element].map(data => <option value={data}>{data}</option>)}
											</Form.Select>
										</div>
									)})}
								<Card>
									<Card.Body>
										<Table>
											<tr>
												<th style={{width: '33%'}}></th>
												<th className='w-25'>Charge</th>
												<th className='w-25'>Discharge</th>
											</tr>
											<tr>
												<td >{translator('ot')}</td>
												<td ><Form.Select value={!!report && qspecHelper['ot'][report['0x07'][14]]} disabled={true}>{qspecHelper['ot'].map(data => <option value={data}>{data}</option>)}</Form.Select></td>
												<td ><Form.Select value={!!report && qspecHelper['ot'][report['0x07'][10]]} disabled={true}>{qspecHelper['ot'].map(data => <option value={data}>{data}</option>)}</Form.Select></td>
											</tr>
											<tr>
												<td>{translator('ot recovery')}</td>
												<td><Form.Select value={!!report && qspecHelper['ot'][report['0x07'][15]]} disabled={true}>{qspecHelper['ot'].map(data => <option value={data}>{data}</option>)}</Form.Select></td>
												<td><Form.Select value={!!report && qspecHelper['ot'][report['0x07'][11]]} disabled={true}>{qspecHelper['ot'].map(data => <option value={data}>{data}</option>)}</Form.Select></td>
											</tr>
											<tr>
												<td >{translator('ut')}</td>
												<td ><Form.Select value={!!report && qspecHelper['ut'][report['0x07'][16]]} disabled={true}>{qspecHelper['ut'].map(data => <option value={data}>{data}</option>)}</Form.Select></td>
												<td ><Form.Select value={!!report && qspecHelper['ut'][report['0x07'][12]]} disabled={true}>{qspecHelper['ut'].map(data => <option value={data}>{data}</option>)}</Form.Select></td>
											</tr>
											<tr>
												<td>{translator('ut recovery')}</td>
												<td><Form.Select value={!!report && qspecHelper['ut'][report['0x07'][17]]} disabled={true}>{qspecHelper['ut'].map(data => <option value={data}>{data}</option>)}</Form.Select></td>
												<td><Form.Select value={!!report && qspecHelper['ut'][report['0x07'][13]]} disabled={true}>{qspecHelper['ut'].map(data => <option value={data}>{data}</option>)}</Form.Select></td>
											</tr>
										</Table>
									</Card.Body>
								</Card>
							</Card.Body>
						</Card>

						<Card style={{width: '40%'}}>
							<Card.Header>Parameter 2</Card.Header>
							<Card.Body>
								<Card className='mb-1'>
									<Card.Header>{translator('static balance')}</Card.Header>
									<Card.Body>
										{['balance time'].map((element) => {return(
											<div className='d-flex justify-content-between align-items-center mb-2'>
												<Form.Label>{translator(element)}</Form.Label>
												<Form.Select className='w-50' value={!!report && getValue(element, 'dropdown')} disabled={true}>
													{qspecHelper[element].map(data => <option value={data}>{data}</option>)}
												</Form.Select>
											</div>
										)})}
									</Card.Body>
								</Card>
								<Card className='mb-1'>
									<Card.Header>{translator('total voltage protect')}</Card.Header>
									<Card.Body>
										{['tov threshold', 'tov recovery'].map((element) => {return(
											<div className='d-flex justify-content-between align-items-center mb-2'>
												<Form.Label>{translator(element)}</Form.Label>
												<Form.Control className='w-50' value={!!report && getValue(element, 'text')} disabled={true}/>
											</div>
										)})}

										{['tov delay', 'tov recovery delay'].map((element) => {return(
											<div className='d-flex justify-content-between align-items-center mb-2'>
												<Form.Label>{translator(element)}</Form.Label>
												<Form.Select className='w-50' value={!!report && getValue(element, 'dropdown')} disabled={true}>
													{qspecHelper[element].map(data => <option value={data}>{data}</option>)}
												</Form.Select>
											</div>
										)})}

										{['tuv threshold', 'tuv recovery'].map((element) => {return(
											<div className='d-flex justify-content-between align-items-center mb-2'>
												<Form.Label>{translator(element)}</Form.Label>
												<Form.Control className='w-50' value={!!report && getValue(element, 'text')} disabled={true}/>
											</div>
										)})}
                  
										{['tuv delay', 'tuv recovery delay'].map((element) => {return(
											<div className='d-flex justify-content-between align-items-center mb-2'>
												<Form.Label>{translator(element)}</Form.Label>
												<Form.Select className='w-50' value={!!report && getValue(element, 'dropdown')} disabled={true}>
													{qspecHelper[element].map(data => <option value={data}>{data}</option>)}
												</Form.Select>
											</div>
										)})}
									</Card.Body>
								</Card>
              
								<Card className='mb-1'>
									<Card.Header>{translator('occ recovery')}</Card.Header>
									<Card.Body>
										{['occ recovery auto recovery delay', 'occ recovery auto recovery lock'].map((element) => {return(
											<div className='d-flex justify-content-between align-items-center mb-2'>
												<Form.Label>{translator(element)}</Form.Label>
												<Form.Select className='w-50' value={!!report && getValue(element, 'dropdown')} disabled={true}>
													{qspecHelper[element].map(data => <option value={data}>{data}</option>)}
												</Form.Select>
											</div>
										)})}
									</Card.Body>
								</Card>

								<Card>
									<Card.Header> {translator('sc recovery')}</Card.Header>
									<Card.Body>
										{['sc auto recovery delay', 'sc auto recovery lock'].map((element) => {return(
											<div className='d-flex justify-content-between align-items-center mb-2'>
												<Form.Label>{translator(element)}</Form.Label>
												<Form.Select className='w-50' value={!!report && getValue(element, 'dropdown')} disabled={true}>
													{qspecHelper[element].map(data => <option value={data}>{data}</option>)}
												</Form.Select>
											</div>
										)})}
									</Card.Body>
								</Card>
							</Card.Body>
						</Card>

						<Card>
							<Card.Header>{translator('alarm parameter')}</Card.Header>
							<Card.Body>
								<Card className='mb-1'>
									<Card.Header>{translator('voltage alarm')}</Card.Header>
									<Card.Body>
										{['pack voltage high', 'pack voltage low'].map((element) => {return(
											<div className='d-flex justify-content-between align-items-center mb-2'>
												<Form.Label>{translator(element)}</Form.Label>
												<Form.Control className='w-50' value={!!report && getValue(element, 'text')} disabled={true}/>
											</div>
										)})}
										{['cell voltage high', 'cell voltage low'].map((element) => {return(
											<div className='d-flex justify-content-between align-items-center mb-2'>
												<Form.Label>{translator(element)}</Form.Label>
												<Form.Select className='w-50' value={!!report && getValue(element, 'dropdown')} disabled={true}>
													{qspecHelper[element].map(data => <option value={data}>{data}</option>)}
												</Form.Select>
											</div>
										)})}
									</Card.Body>
								</Card>
								<Card className='mb-1'>
									<Card.Header>{translator('current alarm')}</Card.Header>
									<Card.Body>
										{['chg threshold', 'dsg threshold'].map((element) => {return(
											<div className='d-flex justify-content-between align-items-center mb-2'>
												<Form.Label>{translator(element)}</Form.Label>
												<Form.Select className='w-50' value={!!report && getValue(element, 'dropdown')} disabled={true}>
													{qspecHelper[element].map(data => <option value={data}>{data}</option>)}
												</Form.Select>
											</div>
										)})}
									</Card.Body>
								</Card>

								<Card className='mb-1'>
									<Card.Header>{translator('cell temp alarm')}</Card.Header>
									<Card.Body>
										<Table>
											<tr>
												<th style={{width: '33%'}}></th>
												<th className='w-25'>Charge</th>
												<th className='w-25'>Discharge</th>
											</tr>
											<tr>
												<td >{translator('cell temp otc')}</td>
												<td >
													<Form.Select value={!!report && getValue('cell temp alarm otc charge', 'dropdown')} disabled={true}>
														{qspecHelper['cell temp alarm otc charge'].map(data => <option value={data}>{data}</option>)}
													</Form.Select>
												</td>
												<td >
													<Form.Select value={!!report && getValue('cell temp alarm otc discharge', 'dropdown')} disabled={true}>
														{qspecHelper['cell temp alarm otc discharge'].map(data => <option value={data}>{data}</option>)}
													</Form.Select>
												</td>
											</tr> 
											<tr>
												<td>{translator('cell temp utc')}</td>
												<td>
													<Form.Select value={!!report && getValue('cell temp alarm utc charge', 'dropdown')} disabled={true}>
														{qspecHelper['cell temp alarm utc charge'].map(data => <option value={data}>{data}</option>)}
													</Form.Select>
												</td>
												<td>
													<Form.Select value={!!report && getValue('cell temp alarm utc discharge', 'dropdown')} disabled={true}>
														{qspecHelper['cell temp alarm utc discharge'].map(data => <option value={data}>{data}</option>)}
													</Form.Select>
												</td>
											</tr>
										</Table>
									</Card.Body>
								</Card>

								<Card className='mb-1'>
									<Card.Header>{translator('ambient temp alarm')}</Card.Header>
									<Card.Body>
										{['ambient temp otc', 'ambient temp utc'].map((element) => {return(
											<div className='d-flex justify-content-between align-items-center mb-2'>
												<Form.Label>{translator(element)}</Form.Label>
												<Form.Select className='w-50' value={!!report && getValue(element, 'dropdown')} disabled={true}>
													{qspecHelper[element].map(data => <option value={data}>{data}</option>)}
												</Form.Select>
											</div>
										)})}
									</Card.Body>
								</Card>

                <Card className='mb-1'>
                  <Card.Header>{translator('mos temp alarm')}</Card.Header>
                  <Card.Body>
                    {['mos temp otc', 'mos temp ot recovery'].map((element) => {return(
                      <div className='d-flex justify-content-between align-items-center mb-2'>
                        <Form.Label>{translator(element)}</Form.Label>
                        <Form.Select className='w-50' value={!!report && getValue(element, 'dropdown')} disabled={true}>
                          {qspecHelper[element].map(data => <option value={data}>{data}</option>)}
                        </Form.Select>
                      </div>
                    )})}
                  </Card.Body>
                </Card>

                <Card className='mb-1'>
                  <Card.Header>{translator('soc alarm')}</Card.Header>
                    <Card.Body>
                      {['soc low threshold'].map((element) => {return(
                        <div className='d-flex justify-content-between align-items-center mb-2'>
                          <Form.Label>{translator(element)}</Form.Label>
                          <Form.Select className='w-50' value={!!report && getValue(element, 'dropdown')} disabled={true}>
                            {qspecHelper[element].map(data => <option value={data}>{data}</option>)}
                          </Form.Select>
                        </div>
                      )})}
                    </Card.Body>
                  </Card>
                </Card.Body>
              </Card>
            </div>
          </Tab>
          <Tab eventKey='Ext. settings' title='Ext. settings'>
            <div className='d-flex justify-content-evenly mt-2'>
							<div className='w-30'>
								<Card>
									<Card.Header>Parameter 1</Card.Header>
									<Card.Body>
										{['shut down voltage'].map((element) => {return(
											<div className='d-flex justify-content-between align-items-center mb-2'>
                        <Form.Label>{translator(element)}</Form.Label>
                        <Form.Select className='w-50' value={!!report && getValue(element, 'dropdown')} disabled={true}>
                          {qspecHelper[element].map(data => <option value={data}>{data}</option>)}
                        </Form.Select>
                      </div>
                    )})}
										{['shut down delay'].map((element) => {return(
											<div className='d-flex justify-content-between align-items-center mb-2'>
												<Form.Label>{translator(element)}</Form.Label>
												<Form.Control className='w-50' value={!!report && getValue(element, 'text')} disabled={true}/>
											</div>
										)})}
										{['shut down current'].map((element) => {return(
                      <div className='d-flex justify-content-between align-items-center mb-2'>
                        <Form.Label>{translator(element)}</Form.Label>
                        <Form.Select className='w-50' disabled={true}>
                          {['N/A'].map(data => <option value={data}>{data}</option>)}
                        </Form.Select>
                      </div>
                    )})}
										{['mos otp', 'mos otp recovery', 'mos ot delay', 'ambient otp', 'ambient otp recovery', 'ambient utp','ambient utp recovery'].map((element) => {return(
                      <div className='d-flex justify-content-between align-items-center mb-2'>
                        <Form.Label>{translator(element)}</Form.Label>
                        <Form.Select className='w-50' value={!!report && getValue(element, 'text')} disabled={true}>
                          {qspecHelper[element].map(data => <option value={data}>{data}</option>)}
                        </Form.Select>
                      </div>
                    )})}
							
										{['self dsg rate', 'cycle cap'].map((element) => {return(
											<div className='d-flex justify-content-between align-items-center mb-2'>
												<Form.Label>{translator(element)}</Form.Label>
												<Form.Control className='w-50' value={!!report && getValue(element, 'text')} disabled={true}/>
											</div>
										)})}
									{['soc 0 voltage'].map((element) => {return(
										<div className='d-flex justify-content-between align-items-center mb-2'>
											<Form.Label>{translator(element)}</Form.Label>
											<Form.Control className='w-50' value='3' disabled={true}/>
										</div>
									)})}
									</Card.Body>
								</Card>
								
							</div>
							<Card className='mt-2 w-75'>
								<Card.Header>Full charge protection</Card.Header>
								<Card.Body>
									{['pack full charge voltage', 'pack full charge current', 'full charge delay'].map((element) => {return(
										<div className='d-flex justify-content-between align-items-center mb-2'>
											<Form.Label>{translator(element)}</Form.Label>
											<Form.Control className='w-50' value={!!report && getValue(element, 'text')} disabled={true}/>
										</div>
									)})}
								</Card.Body>
							</Card>
            </div>
          </Tab>
        </Tabs>
      </Modal.Body>
		</Modal>
  )
}

export default QspecModal