import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

export const getBatteryData = createAsyncThunk('getBatteryData', async (data) => {
  
  const battData = await fetch(`${process.env.REACT_APP_API_URL}/ip_bms/show`, {
    method: 'POST',
    body: JSON.stringify({uid: data.uid}),
    headers: {"Content-Type": "application/json"},
  })
  return(battData.json())
})


export const IpBmsSlice = createSlice({
  name: 'IpBms',
  initialState: {
    loading: false,
    data: [],
    error: null
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getBatteryData.fulfilled, (state, action) => {
      state.data = action.payload
    })
  }
})

export default IpBmsSlice.reducer;


