import React, {useState, useEffect} from 'react'
import {Button, Row, Col, Container, Spinner} from 'react-bootstrap'
import {useDispatch, useSelector} from 'react-redux'

import SearchWidget from '../widgets/SearchWidget'
import SimpleTable from '../widgets/SimpleTable'
import Paginator from '../widgets/Paginator'

import { setOrder } from '../../scripts/helperMethods'

import { getBatteries } from '../../state/BatteriesSlice'
import { translator } from '../../scripts/translator'

function BatteryIndexComponent() {
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const [page, setPage] = useState(0)
  const [searchParams, setSearchParams] = useState({serialnumber_lteq: '', serialnumber_gteq: '', note_i_cont: '', purchase_order_line_id_eq: '', aqua_reports_id_eq: '', battery_model_id_eq: '', bms_model_id_eq: '', s: ''})
  const [searchHeaders, setSearchHeaders] = useState([
                                                      {label: translator('serial number'), subLables: [translator('less than or equal'), translator('more than or equal')], params: ['serialnumber_lteq', 'serialnumber_gteq'], options: null, type: 'text'},
                                                      {label: translator('note'), subLables: null, params: ['note_i_cont'], options: null, type: 'text'},
                                                      {label: translator('purchase order line id'), subLables: null, params: ['purchase_order_line_id_eq'], options: [], type: 'text'},
                                                      {label: translator('aqua report id'), subLables: null, params: ['aqua_reports_id_eq'], options: [], type: 'text'},
                                                      {label: translator('battery model'), subLables: null, params: ['battery_model_id_eq'], options: [], type: 'select'},
                                                      {label: translator('bms model'), subLables: null, params: ['bms_model_id_eq'], options: [], type: 'select'}
                                                    ])
  const [tableElements] = useState({'serial number': 'serialnumber', 'bms serial number': 'bmsserial', 'purchase order line': 'purchase_order_line', 'aqua report': 'aquareport_id', 'battery model': 'model', 'bms model': 'bms_model' })
  const currentUser = useSelector(state => state.session.data)
  const batteries = useSelector(state => state.batteries.data)

  useEffect(() => {
    setLoading(true)
    fetchBatteries()
  },[page])

  useEffect(() => {
    if(!!batteries.results){setLoading(false)}
  }, [batteries])

  const fetchBatteries = () => dispatch(getBatteries({token: currentUser.token, page, params: searchParams}))
  
  const clearSearch = () => setSearchParams({serialnumber_lteq: '', serialnumber_gteq: '', note_i_cont: '', purchase_order_line_id_eq: '', aqua_reports_id_eq: '', battery_model_id_eq: '', bms_model_id_eq: '', s: ''})

  const order= (element) => setOrder(searchParams, setSearchParams, element, fetchBatteries)

  return (
    <Container fluid> 
    <br />
    <br />
    <br />
      <Container >
        <h3>{translator('search')}</h3>
        <SearchWidget searchParams={searchParams} searchHeaders={searchHeaders} setSearchParams={setSearchParams} search={fetchBatteries} resetSearch={clearSearch}/>
       <div>
        {/* <a href={downloadLink} className='btn btn-primary d-flex' style={{width: '80px', position: 'relative', right: '-100%'}} rel="noopener noreferrer"><span className="material-symbols-outlined pointer" >download</span>Excel</a> */}
       </div>
       
      </Container>
      <br />
      <Row className='mx-auto'>
        <Col>
          <br />
          {loading === true &&
            <Col className='d-flex justify-content-center'>
              <Spinner size='lg' animation="grow" />
            </Col>
          }
          {loading === false && <Paginator count={batteries.results} pageSize={100} page={page} setPage={setPage}/>}
          {loading === false &&
            <SimpleTable tableElements={tableElements}  data={batteries.batteries} searchParams={searchParams} setOrder={order} />
          }
          {loading === false && <Paginator count={batteries.results} pageSize={100} page={page} setPage={setPage}/>}

          <br />
        </Col>
      </Row>
      <br />
      <br />
      <br />
    </Container>

  )
}

export default BatteryIndexComponent