import {configureStore} from '@reduxjs/toolkit'

import sessionSlice from './SessionSlice';
import userSlice from './userSlice';
import purchaseOrderLineSlice from './PurchaseOrderLineSlice';
import aquaReportsSlice from './AquaReportsSlice';
import availableBatteryUpgrades  from './AvailableBatteryUpgradeSlice';
import  batteriesSlice  from './BatteriesSlice';
import batteryFirmwareSlice from './BatteryFirmwareSlice';
import batteryModelsSlice from './BatteryModelsSlice';
import batteryParamsSlice from './BatteryParamsSlice';
import bmsModelsSlice from './bmsModelsSlice';
import cellReportSlice from './CellReportSlice';
import companiesSlice from './CompaniesSlice';
import customerSlice from './CustomerSlice';
import dashboardSlice from './DashboardSlice';
import handleBatteryUpgradeSlice from './HandleBatteryUpgradeSlice';
import overrideCodesSlice from './OverrideCodesSlice';
import overviewSlice from './OverviewSlice';
import purchaseOrderSlice from './PurchaseOrderSlice';
import rmaSlice from './RmaSlice';
import shipmentSlice from './ShipmentSlice';
import suppliersSlice  from './SuppliersSlice';
import upgradeManifestSlice from './UpgradeManifestSlice';
import chargerSlice from './ChargerSlice';
import chargerModelSlice from './ChargerModelSlice';
import WesReportsSlice from './WesReportsSlice';
import IpBmsSlice from './IpBmsSlice';
import CellsSlice from './CellsSlice';


export const store = configureStore({
  reducer: {
    session: sessionSlice,
    user: userSlice,
    purchaseOrderLine: purchaseOrderLineSlice,
    aquaReports: aquaReportsSlice,
    availableBatteryUpgrades: availableBatteryUpgrades,
    batteries: batteriesSlice,
    batteryFirmware: batteryFirmwareSlice,
    batteryModels: batteryModelsSlice,
    batteryParams: batteryParamsSlice,
    bmsModels: bmsModelsSlice,
    cellReports: cellReportSlice,
    companies: companiesSlice,
    customers: customerSlice,
    dashboard: dashboardSlice,
    handleBatteryUpgrade: handleBatteryUpgradeSlice,
    overrideCodes: overrideCodesSlice,
    overview: overviewSlice,
    purchaseOrder: purchaseOrderSlice,
    rma: rmaSlice,
    shipment: shipmentSlice,
    supplier: suppliersSlice,
    upgradeManifest: upgradeManifestSlice,
    charger: chargerSlice,
    chargerModels: chargerModelSlice,
    wesReports: WesReportsSlice,
    ipBms: IpBmsSlice,
    cells: CellsSlice,
  }
})