import React from 'react';
import {Card} from 'react-bootstrap';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend} from 'chart.js';
import { Bar } from "react-chartjs-2";

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

function TestedCellsComponent({testedCells}) {
  return (
    <Card>
      <Card.Body>
        <h5>Cell tests (last 30 days)</h5>
        <Bar
          height='345px'
          width='1500px'
          options={
            {
              responsive: false,
              maintainAspectRatio: false,
              plugins: {
                legend: {
                  position: 'top'
                },
              },
            }
          }

          data={{
            labels: Object.keys(testedCells),
            datasets: [
              {
                label: 'Tested Cells',
                data: Object.values(testedCells),
                backgroundColor: 'red'
              }
            ]
          }}
        /> 
      </Card.Body>
    </Card>
  )
}

export default TestedCellsComponent