import React from 'react'
import {Form} from 'react-bootstrap'
import { Typeahead } from 'react-bootstrap-typeahead'

import { updateParams } from '../../scripts/helperMethods'
import { translator } from '../../scripts/translator'

function BatteryForm({batteryFormFields, updateBatteryForm, batteryModels }) {
  
  const updateForm = (key, value) =>{
    const params = {...batteryFormFields}
    params[key] = value;
    updateBatteryForm(params)
    if(key === 'ov_number' || key === 'uv_number' || key === 'sc_number' || key === 'cycle_number'){calculateOvUvCycleRatio(params)}
  }

  const handleList = () => batteryModels.map((model) => model.name)

  const calculateOvUvCycleRatio = (params) => {
    const data = {...params}
    const countMargin = 3;
    let cycle = data['cycle_number']

    if (cycle <= 0 || cycle.length === 0 ){ cycle = 1}

    data['ov_cycle_ratio']  = data['ov_number']  <= countMargin ? 0 : ((data['ov_number'] - countMargin) / cycle).toFixed(3);
    data['uv_cycle_ratio']  = data['uv_number']  <= countMargin ? 0 : ((data['uv_number'] - countMargin) / cycle).toFixed(3);
    data['sc_cycle_ratio'] = (data['sc_number'] / cycle).toFixed(3)

    updateBatteryForm(data)
  }

  const handleModel = (data) => updateForm('product_model', data)

  
  return (
    <div className='w-100 d-flex flex-wrap justify-content-between'>
      
      <div className='w-100 m-2'>
        <Form.Label>{translator('model')}</Form.Label>
        <Typeahead  id="basic-typeahead-single model-number" options={handleList()} onChange={handleModel} placeholder={batteryFormFields['product_model']} />
      </div>

      <div className='modal-field-large m-2'>
        <Form.Label>{translator('serial number')}</Form.Label>
        <Form.Control id='serial-number' value={batteryFormFields['serial_number'] || ''} onChange={e => updateForm('serial_number', e.target.value)} type='number'/>
      </div>
      
      <div className='modal-field-large m-2'>
        <Form.Label>{translator('comm.')}</Form.Label>
        <Form.Select id='comm' value={ batteryFormFields['comunication'] || ''} onChange={e => updateForm('comunication', e.target.value)}>
          <option value="N/A">N/A</option>
          <option value="Fault">Fault</option>
          <option value="Ok">OK</option>
        </Form.Select>
      </div>

      <div className='modal-field-large m-2'>
        <Form.Label>{translator('chg')}</Form.Label>
        <Form.Select id='chg' value={ batteryFormFields['chg'] || ''} onChange={e => updateForm('chg', e.target.value)}>
          <option value="N/A">N/A</option>
          <option value="Fault">Fault</option>
          <option value="Ok">OK</option>
        </Form.Select>
      </div>

      <div className='modal-field-large m-2'>
        <Form.Label>{translator('dsg')}</Form.Label>
        <Form.Select id='dsg' value={batteryFormFields['dsg'] || ''} onChange={e => updateForm('dsg', e.target.value)}>
          <option value="N/A">N/A</option>
          <option value="Fault">Fault</option>
          <option value="Ok">OK</option>
        </Form.Select>
      </div>

      <div className='modal-field-large m-2'>
        <Form.Label>{translator('casing voltage')}</Form.Label>
        <Form.Select id='casing-voltage' value={batteryFormFields['casing_voltage'] || ''} onChange={e => updateForm('casing_voltage', e.target.value)}>
          <option value={false}>No</option>
          <option value={true}>Yes</option>
        </Form.Select>
      </div>

      <div className='modal-field-large m-2'>
        <Form.Label>{translator('cycle number')}</Form.Label>
        <Form.Control id='cycle-number' value={batteryFormFields['cycle_number'] || ''} onChange={e => updateForm('cycle_number', e.target.value)} type='number'/>
      </div>

      <div className='modal-field-large m-2'>
        <Form.Label>{translator('ov number')}</Form.Label>
        <Form.Control id='ov-number' value={batteryFormFields['ov_number'] || ''} onChange={e => updateForm('ov_number', e.target.value)} type='number'/>
      </div>

      <div className='modal-field-large m-2'>
        <Form.Label>{translator('uv number')}</Form.Label>
        <Form.Control id='uv-number' value={batteryFormFields['uv_number'] ||  ''} onChange={e => updateForm('uv_number', e.target.value)} type='number'/>
      </div>

      <div className='modal-field-large m-2'>
        <Form.Label>{translator('sc number')}</Form.Label>
        <Form.Control id='sc-number' value={batteryFormFields['sc_number'] || ''} onChange={e => updateForm('sc_number', e.target.value)} type='number'/>
      </div>

      <div className='modal-field-large m-2'>
        <Form.Label>{translator('ov cycle ratio')}</Form.Label>
        <Form.Control  className={batteryFormFields['ov_cycle_ratio'] > 0.05 ? 'border-danger' : 'border-success'} value={batteryFormFields['ov_cycle_ratio']}  disabled={true}/>
      </div>

      <div className='modal-field-large m-2'>
        <Form.Label>{translator('uv cycle ratio')}</Form.Label>
        <Form.Control className={batteryFormFields['uv_cycle_ratio'] > 0.05 ? 'border-danger' : 'border-success'} value={batteryFormFields['uv_cycle_ratio']}  disabled={true}/>
      </div>

      <div className='modal-field-large m-2'>
        <Form.Label>{translator('sc cycle ratio')}</Form.Label>
        <Form.Control className={batteryFormFields['sc_cycle_ratio'] > 0.05 ? 'border-danger' : 'border-success'}  value={batteryFormFields['sc_cycle_ratio']}  disabled={true}/>
      </div>
    </div>
  )
}

export default BatteryForm