import React, {useState, useEffect} from 'react'
import { Modal, Button, Alert, Form } from 'react-bootstrap'

import { translator } from '../../scripts/translator'
import { updateParams, clearParams } from '../../scripts/helperMethods'


function NewBmsModelModal({showModal, hideModal, save, currentBms}) {
  const [formFields, setFormFields] = useState({name: '', series: "", wst_legacy_name: "", wst_name: "", 
                                    continuous_discharge_current: "", continuous_charge_current: "", 
                                    peak_discharge_current: "", communication: "", note: "", current_sense_resistance: '', afe:'',  file: null})
  
  useEffect(() =>{
    if(currentBms !== null){

      setFormFields({'name': currentBms.name, "series": currentBms.series, "wst_legacy_name": currentBms.wst_legacy_name, "wst_name": currentBms.wst_name,  
                     "continuous_discharge_current": currentBms.continuous_discharge_current, "continuous_charge_current": currentBms.continuous_charge_current,  
                      "peak_discharge_current": currentBms.peak_discharge_current, "communication": currentBms.communication, "note": currentBms.note, current_sense_resistance: '', 
                      afe: ''})
    }
  },[currentBms])

  const closeModal = () =>{
    clearParams(formFields, setFormFields)
    hideModal()
  }

  return (
    <Modal show={showModal} onHide={closeModal}>
      <Modal.Header closeButton>
        <Modal.Title>{translator('create bms model')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <>
          <Form.Label>{translator('name')}</Form.Label>
          <Form.Control value={formFields['name']} onChange={e => updateParams(formFields, setFormFields,'name', e.target.value)} />
            <br />
        </>
        <>
          <Form.Label>{translator('series')}</Form.Label>
          <Form.Control value={formFields['series']} onChange={e => updateParams(formFields, setFormFields,'series', e.target.value)} />
            <br />
        </>
        <>
          <Form.Label>{translator('wst_legacy_name')}</Form.Label>
          <Form.Control value={formFields['wst_legacy_name']} onChange={e => updateParams(formFields, setFormFields,'wst_legacy_name', e.target.value)} />
            <br />
        </>
        <>
          <Form.Label>{translator('wst_name')}</Form.Label>
          <Form.Control value={formFields['wst_name']} onChange={e => updateParams(formFields, setFormFields,'wst_name', e.target.value)} />
            <br />
        </>
        <>
          <Form.Label>{translator('continuous_discharge_current')}</Form.Label>
          <Form.Control value={formFields['continuous_discharge_current']} onChange={e => updateParams(formFields, setFormFields,'continuous_discharge_current', e.target.value)} />
            <br />
        </>
        <>
          <Form.Label>{translator('continuous_charge_current')}</Form.Label>
          <Form.Control value={formFields['continuous_charge_current']} onChange={e => updateParams(formFields, setFormFields,'continuous_charge_current', e.target.value)} />
            <br />
        </>
        <>
          <Form.Label>{translator('peak_discharge_current')}</Form.Label>
          <Form.Control value={formFields['peak_discharge_current']} onChange={e => updateParams(formFields, setFormFields,'peak_discharge_current', e.target.value)} />
            <br />
        </>
        <>
          <Form.Label>{translator('communication')}</Form.Label>
          <Form.Control value={formFields['communication']} onChange={e => updateParams(formFields, setFormFields,'communication', e.target.value)} />
            <br />
        </>
        <>
          <Form.Label>{translator('note')}</Form.Label>
          <Form.Control value={formFields['note']} onChange={e => updateParams(formFields, setFormFields,'note', e.target.value)} as='textarea'/>
            <br />
        </>
        <>
          <Form.Label>{'AFE'}</Form.Label>
          <Form.Control value={formFields['afe']} onChange={e => updateParams(formFields, setFormFields,'afe', e.target.value)} />
            <br />
        </>
        <>
          <Form.Label>Current sense resistance</Form.Label>
          <Form.Control value={formFields['current_sense_resistance']} onChange={e => updateParams(formFields, setFormFields,'current_sense_resistance', e.target.value)} />
            <br />
        </>
        <div>
          <Form.Label>{translator('datasheet')}</Form.Label>
          <Form.Control onChange={e => updateParams(formFields, setFormFields, 'datasheet', e.target.files[0])} type="file"/>
          <br />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="default" onClick={() => closeModal()}>{translator('cancel')}</Button>
        <Button onClick={() => save(formFields)}>{translator('save')}</Button>
      </Modal.Footer>
    </Modal>
  )
}

export default NewBmsModelModal