import React, {useState, useEffect} from 'react'
import {Container} from 'react-bootstrap'

function AquaReportShowComponent() {
  const [data] = useState(localStorage.getItem('aquaReport'))

  useEffect(() => {
    console.log(data)
  },[data])
  return (
    <Container className='d-flex align-items-center'>
      <pre>{data}</pre>
  
    </Container>
    
  )}

export default AquaReportShowComponent