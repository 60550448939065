import React, {useState, useEffect} from 'react'
import {Container, Card, Form, Button, Alert, Spinner} from 'react-bootstrap'
import {useDispatch, useSelector} from 'react-redux'
import { useNavigate } from 'react-router';

import {login} from '../../state/SessionSlice'

import {translator} from '../../scripts/translator'

function LoginComponent() {
  const navigate = useNavigate()
  const dispatch = useDispatch();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const user = useSelector(state => state.session.data)
  

  useEffect(() => {
    if(user && user.token){
      navigate('/')
    }

    if(user && user.error){
      setError(user.error)
    }

    setLoading(false)
  },[user, navigate])

  const handleKeypress = (e) => {
    if(e.key === 'Enter'){
      signIn();
    }
  }

  const signIn = () => {
    setLoading(true);
    dispatch(login({email, password}));
  }
  

  return (
    <Container>
      <br/>
      <br/>
      <Card>
        <Card.Body >
          {error.length !== 0 && 
            <Alert variant='danger'>
              {error}
            </Alert>
          }
          <br />
          <h5>{translator('login')}</h5>
          <br/>
          <Form.Label id='email-label'>{translator('email')}</Form.Label>
          <Form.Control id='email' value={email} onChange={e => setEmail(e.target.value)} placeholder={translator('email')}/> 
          <br />
          <Form.Label id='password-label'>{translator('password')}</Form.Label>
          <Form.Control id='password' value={password} onKeyPress={e => handleKeypress(e)} onChange={e => setPassword(e.target.value)} type='password' placeholder={translator('password')}/>
          <br/>
          <Button onClick={e => signIn()} disabled={loading}>{loading === false ? translator('login') : <Spinner size='sm' animation="grow" />}</Button>
        </Card.Body>
      </Card>
    </Container>
  )
}

export default LoginComponent