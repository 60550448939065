import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

export const createPo = createAsyncThunk('createPo', async (data) =>{
  const po = await fetch(`${process.env.REACT_APP_API_URL}/purchase_orders`, {
    method: 'POST',
    headers: {'Content-Type': 'application/json', token: data.token},
    body: JSON.stringify(data.params)
  })

  return(po.json())
})

export const updatePo = createAsyncThunk('updatePo', async (data) =>{
  const po = await fetch(`${process.env.REACT_APP_API_URL}/purchase_orders/${data.id}`, {
    method: 'PUT',
    headers: {'Content-Type': 'application/json', token: data.token},
    body: JSON.stringify(data.params)
  })

  return(po.json())
})

export const deletePo = createAsyncThunk('deletePo', async (data) => {
  const po = await fetch(`${process.env.REACT_APP_API_URL}/purchase_orders/${data.id}`, {
    method: 'DELETE',
    headers: {token: data.token}
  })

  return(po.json())
})

export const purchaseOrderSlice = createSlice({
  name: 'purchaseOrder',
  initialState: {
    loading: false,
    data: '',
    error: null
  }, 
  reducers: {
    resetPurchaseorder(state, action){
      state.data = ''
    }
  },
  extraReducers: (builder) => {
    builder.addCase(createPo.fulfilled, (state, action) => {
      state.data = action.payload
    });
    builder.addCase(updatePo.fulfilled, (state, action) => {
      state.data = action.payload
    });
    builder.addCase(deletePo.fulfilled, (state, action) => {
      state.data = action.payload
    })
  }
})

export const {resetPurchaseorder} = purchaseOrderSlice.actions;

export default purchaseOrderSlice.reducer;