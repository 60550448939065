import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

export const getBatteryFirmware = createAsyncThunk('getBatteryFirmware', async (data)  => {
  let searchString  = '?'
  if(!!data.params){
    for(let key of  Object.keys(data.params)){
      if(data.params[key].length !== 0){
        searchString = searchString + `q[${key}]=${data.params[key]}&`
      }
    }
  }

  const firmware = await fetch(`${process.env.REACT_APP_API_URL}/battery_firmwares${searchString}`, {    
    method: 'GET',
    headers: {"Content-Type": "application/json", token: data.token}
  })
  
  return(firmware.json())
})

export const saveBatteryFirmware = createAsyncThunk('saveBatteryFirmware', async (data) => {
  const firmware = await fetch(`${process.env.REACT_APP_API_URL}/battery_firmwares`, {    
    method: 'POST',
    headers: {token: data.token},
    body: data.params
  })

  return(firmware.json())
})

export const batteryFirmwareSlice = createSlice({
  name: 'batteryFirmware',
  initialState: {
    loading: false,
    data: [],
    error: null
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getBatteryFirmware.fulfilled, (state, action) =>{
      state.data = action.payload
    });
    builder.addCase(saveBatteryFirmware.fulfilled, (state, action) => {
      state.data = action.payload
    })
  }
})

export default batteryFirmwareSlice.reducer;