import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

export const getDashboard = createAsyncThunk('getDashboard', async (data) => {
  const reports = await fetch(`${process.env.REACT_APP_API_URL}/dashboard`, {    
    method: 'GET',
    headers: {"Content-Type": "application/json", token: data.token}
  })
  return(reports.json())
})

export const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState: {
    loading: false,
    data: [],
    error: null
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getDashboard.fulfilled, (state, action) => {
      state.data = action.payload
    })
  }
})

export default dashboardSlice.reducer;