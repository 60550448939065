import React, {useState} from 'react';
import {Table, Button, ButtonGroup, DropdownButton, Dropdown, OverlayTrigger, Tooltip, Popover} from 'react-bootstrap';
import {useDispatch, useSelector} from 'react-redux';

import ShipmentsModal from './ShipmentsModal';
import DeleteModal from '../widgets/modals/DeleteModal';

import { createShipment, updateShipment, deleteShipment } from '../../state/ShipmentSlice';
import { translator } from '../../scripts/translator';

function ShipmentsWidget({shipments, pol}) {
  const dispatch = useDispatch();
  const [displayModal, setDisplayModal] = useState(false)
  const [displayDeleteModal, setDisplayDeleteModal] = useState(false)
  const [currentShipment, setCurrentShipment] = useState({});
  const [tableElements] = useState({Note: 'note', Dest: 'destination_country', Type: 'method', Qty: 'amount_shipped', 'ZAO-DEP': 'created_at', ETA: 'shipper_expected_delivery_date', 'Rcv?': 'received_date', Tracking: 'tracking'})
  const currentUser = useSelector(state => state.session.data);

  const closeModal = () => {
    setDisplayModal(false);
    setDisplayDeleteModal(false)
    setCurrentShipment({});
  }

  const saveShipment = (params) => {
    if(!(params.bill_of_lading instanceof File)){delete params.bill_of_lading }
    if(!(params.commercial_invoice instanceof File)){delete params.commercial_invoice}
    if(!(params.packing_list instanceof File)){delete params.packing_list }
    if(!(params.packing_details instanceof File)){delete params.packing_details }
    if(params.documents.length === 0){delete params.documents }
    const formData = new FormData();
    for(let key of Object.keys(params)){
      if(key === 'documents'){
        for(let i = 0; i < params['documents'].length; i++){
          formData.append(`shipment[documents_attributes][${i}][note]`, params['documents'][i]['note']);
          formData.append(`shipment[documents_attributes][${i}][file]`, params['documents'][i]['file']);
        }
      }else{
        formData.append(`shipment[${key}]`, params[key])
      }
    }

    !!currentShipment ? dispatch(updateShipment({id: currentShipment.id, params: formData, token: currentUser.token})) : dispatch(createShipment({params: formData, token: currentUser.token}));
    setDisplayModal(false)
  }

  const openUpdate = (shipment) => {
    setCurrentShipment(shipment);
    setDisplayModal(true);
  }

  const downloadsCount = (shipment) => {
    let count = 0;
    if(!!shipment.bill_of_lading){count++ }
    if(!!shipment.commercial_invoice){count++ }
    if(!!shipment.packing_list){count++ }
    if(!!shipment.packing_details){count++ }
    if(!!shipment.documents && shipment.documents.length !== 0){count = count + shipment.documents.length}
    return(count)
  }

  const replaceTrackingLinks = (string) => {
    let htmlstring = `${string}`;
    htmlstring = htmlstring.replace(/(1Z[0-9A-Z]{16})/ig, `<a key=$1 href=https://www.ups.com/track?track=yes&trackNums=$1 target="_blank">UPS</a>`);
    htmlstring = htmlstring.replace(/\b([0-9]{12})/ig, `<a key=$1 href=https://www.fedex.com/fedextrack/?trknbr=$1 target="_blank">FedEx</a>`);
    htmlstring = htmlstring.replace(/(HKG[0-9]{8})/ig, `<a key=$1 href=https://transtatus.scangl.com/?hawb=$1 target="_blank">$1</a>`);
    let htmldata = {__html: htmlstring};
    return <p dangerouslySetInnerHTML={htmldata} />;

    
  }

  const openDelete = (shipment) => {
    setCurrentShipment(shipment);
    setDisplayDeleteModal(true);
  } 

  const deleteConfirmedShipment = (shipment) => {
    dispatch(deleteShipment({id: shipment.id, token: currentUser.token}));
    setCurrentShipment(null);
    closeModal();
  } 

  const showTrackingInfo = (tracking) => {
    return(
      <Popover id="popover-basic">
        <Popover.Header as="h3">Tracking data</Popover.Header>
        <Popover.Body>{replaceTrackingLinks(tracking)}</Popover.Body>
      </Popover>
    )
  }

  return (
    <div>
      {shipments.length !== 0 && 
        <Table size='sm' className='table-font'>
          <thead>
            <tr>
              <th>{translator('note')}</th>
              <th>{translator('dest')}</th>
              <th>{translator('type')}</th>
              <th>{translator('qty')}</th>
              <th>{translator('dates')}</th>
              <th>{translator('tracking')}</th>
              <th>{translator('docs')}</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {shipments.map((shipment, index) => {return(
              <tr key={index}>
                <td>
                  {(!!shipment.note) &&   
                    <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">{shipment.note.split('+').map((string, index) => <p key={index}>{string}</p>)}</Tooltip>}>
                      <span className="material-symbols-outlined">info</span>
                    </OverlayTrigger>
                  }
                </td>
                <td>{shipment.destination_country}</td>
                <td>{shipment.method}</td>
                <td>{shipment.amount_shipped}</td>
                <td >
                  <p className='m-0'>{!!shipment.created_at ? `Zao-dep.: ${shipment.created_at.split('T')[0]}` : ''}</p>
                  <p className='m-0'>{!!shipment.shipped_date ? `Shipped: ${shipment.shipped_date.split('T')[0]}` : ''}</p>
                  <p className='m-0'>{!!shipment.expected_delivery_date ? `ETA.: ${shipment.expected_delivery_date.split('T')[0]}` : ''}</p>
                  <p className='m-0'>{!!shipment.received_date ?  `Recv.: ${shipment.received_date.split('T')[0]}` : ''}</p>
               </td>
                <td>
                  {(!!shipment.tracking) && 
                    <OverlayTrigger trigger="click" placement="left" overlay={showTrackingInfo(shipment.tracking)}>
                      <Button variant="light" className='btn-sm'>more</Button>
                    </OverlayTrigger>
                  }
                </td>
                <td style={{width:'10%'}}>
                  <ButtonGroup id='docs'>
                    <DropdownButton  size="sm" drop='up' variant="outline-secondary" as={ButtonGroup} title={`docs ${downloadsCount(shipment )}`} id="bg-nested-dropdown">
                      {!!shipment.bill_of_lading && <Dropdown.Item download href={shipment.bill_of_lading.url} className="text-decoration-underline text-primary" target="_blank">{translator('bill of lading')}</Dropdown.Item>}
                      {!!shipment.commercial_invoice && <Dropdown.Item download href={shipment.commercial_invoice.url} className="text-decoration-underline text-primary" target="_blank">{translator('commercial invoice')}</Dropdown.Item>}
                      {!!shipment.packing_list && <Dropdown.Item download href={shipment.packing_list.url} className="text-decoration-underline text-primary" target="_blank">{translator('packing list')}</Dropdown.Item>}
                      {!!shipment.packing_details && <Dropdown.Item download href={shipment.packing_details.url} className="text-decoration-underline text-primary" target="_blank">{translator('packing details')}</Dropdown.Item>}
                      {!!shipment.documents && shipment.documents.map((doc, index) => <Dropdown.Item download key={doc.filename + index} href={doc.url} className="text-decoration-underline text-primary" target="_blank">{doc.filename}</Dropdown.Item> )}
                    </DropdownButton>
                  </ButtonGroup>
                </td>
                {(!!currentUser && currentUser.role !== 'customer') && 
                <td style={{width: '10%'}}>
                  <div className='d-flex table-buttons'>
                    <Button id='edit-shipment' variant='warning' size='sm' className='d-flex' onClick={e => openUpdate(shipment)}><span className="material-symbols-outlined">edit</span></Button>
                    {currentUser.role === 'admin' && <Button id='delete-shipment' size='sm' variant='danger' className='d-flex' onClick={e => openDelete(shipment)}><span className="material-symbols-outlined">delete</span></Button>}
                  </div>
                 
                </td>
                }
              </tr>
            )})}
          </tbody>
        </Table>
      }
      {displayDeleteModal === true && <DeleteModal showModal={displayDeleteModal} confirmModal={deleteConfirmedShipment} hideModal={closeModal} element={currentShipment} message={`${translator('are you sure you want to delete shipment')} ${currentShipment.id}`}/>}
      {displayModal === true && <ShipmentsModal  showModal={displayModal} hideModal={closeModal} save={saveShipment} pol={pol} currentShipment={currentShipment}/> }
    </div>
    
  )
}

export default ShipmentsWidget