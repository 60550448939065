import React, {useEffect, useState} from 'react'
import {Container, Row, Col, Spinner} from 'react-bootstrap'
import {useDispatch, useSelector} from 'react-redux'

import { getDashboard } from '../../state/DashboardSlice';

import TestedBatteriesComponent from './TestedBatteriesComponent';
import TestedCellsComponent from './TestedCellsComponent';
import ProducedModelsComponent from './ProducedModelsComponent';
import ProducedCellsComponent from './ProducedCellsComponent';
import StatisticsTableComponent from './StatisticsTableComponent';

function DashboardIndexComponent() {
  const dispatch = useDispatch();
  const data = useSelector(state => state.dashboard.data)
  const currentUser = useSelector(state => state.session.data)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    getData();
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if(Object.keys(data).length !== 0 ){
      setLoading(false)
    }
    
  }, [data])

  const getData = () => {
    setLoading(true)
    dispatch(getDashboard({token: currentUser.token}))
  }

  return (
    <Container fluid>
      {loading === true &&
        <Row>
          <Col className='d-flex justify-content-center'>
            <Spinner size='lg' animation="grow" />
          </Col>
        </Row>     
      }

      {loading === false &&
        <div>
          <Row>
            <Col className='col-10'>
            {!!data.tested_aqua_reports && <TestedBatteriesComponent testedAquaReports={data.tested_aqua_reports} />}
            </Col>
            <Col className='col-2'>
              {!!data.produced_models && <ProducedModelsComponent producedModels={data.produced_models}/> }
            </Col>
          </Row>
          <br />
          <Row>
            <Col className='col-10'>
            {!!data.tested_cells && <TestedCellsComponent testedCells={data.tested_cells} />}
            </Col>
            <Col className='col-2'>
              {!!data.produced_cells && <ProducedCellsComponent producedCells={data.produced_cells}/> }
            </Col>
          </Row>
          <br />
          <Row>
            <Col>
              {!!data.fc_diff_cut_off_values && <StatisticsTableComponent fcDiffCutOffValues={data.fc_diff_cut_off_values}/>}
            </Col>
          </Row>
        </div> 
      }
    </Container>
  )
}

export default DashboardIndexComponent