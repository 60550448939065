import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

export const getRmas = createAsyncThunk('getRma', async (data) =>{
  
  let searchString = `?page=${data.page}&pagesize=${data.pageSize}`;
  for (const key of Object.keys(data.searchParams)) {
    if (data.searchParams[key].length !== 0) {
      searchString = searchString + `&q[${key}]=${data.searchParams[key]}`
    }
  }
  
  const rma = await fetch(`${process.env.REACT_APP_API_URL}/rmas${searchString}`, {
    method: 'GET',
    headers: { "Content-Type": "application/json", token: data.token }
  })

  return(rma.json())
})

export const createRma = createAsyncThunk('createRma', async (data) => {
  const rma = await fetch(`${process.env.REACT_APP_API_URL}/rmas`, {
    method: 'POST',
    headers: { token: data.token },
    body: data.params
  })

  return(rma.json())
})

export const updateRma = createAsyncThunk('updateRma', async (data) => {
  const rma = await fetch(`${process.env.REACT_APP_API_URL}/rmas/${data.id}`, {
    method: 'PUT',
    headers: { token: data.token },
    body: data.params
  })

  return(rma.json())
})

export const deleteRma = createAsyncThunk('deleteRma', async (data) => {
  const rma = await fetch(`${process.env.REACT_APP_API_URL}/rmas/${data.id}`, {
    method: 'DELETE',
    headers: { token: data.token },
  })

  return(rma.json())
})

export const rmaSlice = createSlice({
  name: 'rma', 
  initialState: {
    loading: false,
    data: [],
    eroor: null
  },
  reducers: {},
  extraReducers: (builder) => {

    builder.addCase(getRmas.fulfilled, (state, action) => {
      console.log('data', action)
      state.data = action.payload
    });

    builder.addCase(createRma.fulfilled, (state, action) => {
      state.data = action.payload
    });

    builder.addCase(updateRma.fulfilled, (state, action) => {
      state.data = action.payload
    });
    
    builder.addCase(deleteRma.fulfilled, (state, action) => {
      state.data = action.payload
    })
  }
})

export default rmaSlice.reducer;