import React from 'react'
import {Card, Table} from 'react-bootstrap'

function ProducedModelsComponent({producedModels}) {
  return (
    <Card>
      <Card.Body>
        <h5>Produced Batteries (last 30 days)</h5>
        <Table>
          <thead>
            <tr>
              <th>Model</th>
              <th>Quantity</th>
            </tr>
          </thead>
          <tbody>
            {Object.keys(producedModels).map((model, index) => {return(
              <tr key={index}>
                <td>{model}</td>
                <td>{producedModels[model]}</td>
              </tr>
            )})}
          </tbody>
        </Table>
        
      </Card.Body>
    </Card>
  )
}

export default ProducedModelsComponent