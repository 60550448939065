import React, { useState, useEffect } from 'react'
import {Button, ButtonGroup, DropdownButton, Dropdown, Row, Col, Container, Spinner, Table} from 'react-bootstrap'
import {useDispatch, useSelector} from 'react-redux'

import SearchWidget from '../widgets/SearchWidget'
import CellModal from './CellModal'

import { getCells, createCell, updateCell } from '../../state/CellsSlice'

import { translator } from '../../scripts/translator'

function CellsIndexComponent() {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true)
  const [openModal, setOpenModal] = useState(false)
  const [pageSize] = useState(100)
  const [page] = useState(1) 
  const [currentCell, setCurrentCell] = useState(null)
  const [searchParams, setSearchParams] = useState({model_number_i_cont: ''})
  const [searchHeaders] = useState([{label: translator('model number'), subLables: null, params: ['model_number_i_cont'], options: null, type: 'text'}])
  const current_user = useSelector(state => state.session.data)
  const cells = useSelector(state => state.cells.data)
  
  useEffect(() => {
    getData()
  },[])

  useEffect(() => {
    if(cells.cells){setLoading(false)}
    if(!cells.cells){getData() }
  },[cells])

  const clearSearch = () => setSearchParams({model_number_i_cont: ''})

  const getData = () => dispatch(getCells({page, pageSize, searchParams, token: current_user.token})) 

  const downloadsCount = (cell) => {
    let count = 0;
    if(!!cell.ocv_table){count++ }
    if(!!cell.datasheet){count++ }
    return(count)
  }

  const openEditModal = (cell) => {
    setCurrentCell(cell)
    setOpenModal(true)
  }

  const hideModal= () => {
    setCurrentCell(null)
    setOpenModal(false)
  }

  const save = (params) => {
    if(!(params.ocv_table instanceof File)){delete params['ocv_table']}
    if(!(params.datasheet instanceof File)){delete params['datasheet']}
    const formData = new FormData()

    for(let key of Object.keys(params)){
      formData.append(`cell[${key}]`, params[key])
    }

    !!currentCell ? dispatch(updateCell({id: currentCell.id, params: formData, token: current_user.token})) : dispatch(createCell({ params: formData, token: current_user.token}))
    hideModal()
    setLoading(true)
  }

  return (
    <Container fluid> 
      <br />
      <br />
      <br />
      
      <Row className='mx-auto'>
        <Col>
          <br />
          <Container>
            <h3>Search</h3>
            <SearchWidget searchParams={searchParams} searchHeaders={searchHeaders} setSearchParams={setSearchParams} search={getData} resetSearch={clearSearch} />
          </Container>
          <Button onClick={e => setOpenModal('true')}>{translator('create cell')}</Button>
          {loading === true &&
            <Col className='d-flex justify-content-center'>
              <Spinner size='lg' animation="grow" />
            </Col>
          }
          <br />
          <br />
          {loading === false &&
          
            <Table  striped hover size='sm' className='table-font'>
              <tr>
                <th>{translator('actions')}</th>
                <th>{translator("model number")}</th>
                <th>{translator("brand")}</th>
                <th>{translator("chemestry")}</th>
                <th>{translator("max voltage")}</th>
                <th>{translator("min voltage")}</th>
                <th>{translator("capacity")}</th>
                <th>{translator("charge rate")}</th>
                <th>{translator("min discharge rate")}</th>
                <th>{translator("max discharge rate")}</th>
                <th>{translator("diameter")}</th>
                <th>{translator("lenght")}</th>
                <th>{translator("thickness")}</th>
                <th>{translator("height")}</th>
                <th>{translator('weight')}</th>
                <th>{translator('energy')}</th>
                <th>{translator("energy density")}</th>
                <th>{translator('files')}</th>
              </tr>
              {cells.cells.map(cell => {return(
                <tr>
                  <td><Button variant='warning' id='edit' className='btn-sm d-flex action-button' onClick={e => openEditModal(cell)}><span className="material-symbols-outlined pointer"  >edit</span></Button></td>
                  <td>{cell["model_number"]}</td>
                  <td>{cell["brand"]}</td>
                  <td>{cell["chemestry"]}</td>
                  <td>{cell["max_voltage"]}</td>
                  <td>{cell["min_voltage"]}</td>
                  <td>{cell["capacity"]}</td>
                  <td>{cell["charge_rate"]}</td>
                  <td>{cell["min_discharge_rate"]}</td>
                  <td>{cell["max_discharge_rate"]}</td>
                  <td>{cell["diameter"]}</td>
                  <td>{cell["lenght"]}</td>
                  <td>{cell['thickness']}</td>
                  <td>{cell["height"]}</td>
                  <td>{cell['weight']}</td>
                  <td>{cell['energy']}</td>
                  <td>{cell["energy_density"]}</td>
                  <td>
                  <ButtonGroup id='docs'>
                    <DropdownButton  size="sm" drop='up' variant="outline-secondary" as={ButtonGroup} title={`docs ${downloadsCount(cell)}`} id="bg-nested-dropdown">
                      {!!cell.ocv_table && <Dropdown.Item download href={cell.ocv_table.url} className="text-decoration-underline text-primary" target="_blank">{translator('ocv table')}</Dropdown.Item>}
                      {!!cell.datasheet && <Dropdown.Item download href={cell.datasheet.url} className="text-decoration-underline text-primary" target="_blank">{translator('datasheet')}</Dropdown.Item>}
                      
                    </DropdownButton>
                  </ButtonGroup>
                  </td>
                </tr>
              )})}
              
            </Table>
            
          }
          <br />
        </Col>
      </Row>
      <br />
      <br />
      <br />
     {openModal  && <CellModal showModal={openModal} hideModal={hideModal} save={save} currentCell={currentCell}/>}
    </Container>
  )
}

export default CellsIndexComponent