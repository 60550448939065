import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

export const getUpgrades = createAsyncThunk('getUpgrades', async (data) => {
  const reports = await fetch(`${process.env.REACT_APP_API_URL}/upgrade_manifests/find_battery_upgrade_manifests?model_name=${data.modelName}`, {    
    method: 'GET',
    headers: {"Content-Type": "application/json", token: data.token}
  })

  return(reports.json())
})

export const availableBatteryUpgrades = createSlice({
  name: 'availableBatteryUpgrade',
  initialState: {
    loading: false,
    data: [],
    error: null
  },
  reducers: {},
  extraReducers: (builder) =>{
    builder.addCase(getUpgrades.fulfilled, (state, action) => {
      state.data = action.payload
    })
  }
})

export default availableBatteryUpgrades.reducer