import {createSlice, createAsyncThunk} from '@reduxjs/toolkit'

export const getChargerModels = createAsyncThunk('getChargerModels', async (data) => {
  const charger = await fetch(`${process.env.REACT_APP_API_URL}/charger_models`, {
    method: 'GET',
    headers: {"Content-Type": "application/json", token: data.token}
  })

  return(charger.json())
})

export const chargerModelsSlice = createSlice({
  name: 'chargerModels',
  initialState: {
    loading: false,
    data: [],
    error: null
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getChargerModels.fulfilled, (state, action) => {
      state.data = action.payload
    })
  }

})

export default chargerModelsSlice.reducer