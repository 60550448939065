import React, {useState, useEffect} from 'react'
import { Modal, Button, Alert, Form } from 'react-bootstrap'

import { updateParams, clearParams } from '../../scripts/helperMethods'
import { translator } from '../../scripts/translator'

function UserModal({showModal, hideModal, save, user, companies, currentUser}) {
  const [params, setParams] = useState({email: '', password: '', password_confirmation: '', role: '', company_id: ''})
  const [roles] = useState({'Select role': '', 'Admin': 'admin', 'Supervisor': 'supervisor', 'Haidi': 'haidi', 'Customer': 'customer', 'Api': 'api'})
  const [errors, setErrors] = useState([]);

  useEffect(() =>{
    clearParams(params, setParams)
  },[hideModal])

  useEffect(() => {
    if(!!user ){ setUserParams()}
  },[user])

  useEffect(() => {checkErrors(params)}, [params])

  const resetParams = () => {
    console.log('running')
    setParams({email: '', password: '', password_confirmation: '', role: '', company_id: ''})
  }

  const setUserParams = () => setParams({email: user.email, password: '', password_confirmation: '', role: user.role, company_id: user.company_id})

  const checkErrors = (data) => {
    let alerts = []
    if(data['password'] !== data['password_confirmation']){alerts.push('password and password confirmation are different')}
    setErrors(alerts)
  }

  return (
    <Modal show={showModal} onHide={hideModal}>
      <Modal.Header closeButton>
        <Modal.Title>{!!user ? 'Update user' : 'Create user'}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className='mb-4'>
          <Form.Label>{translator('email')}</Form.Label>
          <Form.Control value={params['email'] } onChange={e => updateParams(params, setParams, 'email', e.target.value)}/>
        </div>

        <div className='mb-4'>
          <Form.Label>{translator('password')}</Form.Label>
          <Form.Control value={params['password'] } onChange={e => updateParams(params, setParams, 'password', e.target.value)}/>
        </div>

        <div className='mb-4'>
          <Form.Label>{translator('password confirmation')}</Form.Label>
          <Form.Control value={params['password_confirmation'] } onChange={e => updateParams(params, setParams, 'password_confirmation', e.target.value)}/>
        </div>

       {currentUser.role === 'admin' &&  <div className='mb-4'>
          <Form.Label>{translator('role')}</Form.Label>
          <Form.Select id='roles-select' value={params['role'] } onChange={e => updateParams(params, setParams ,'role', e.target.value)}> 
            {Object.keys(roles).map(key => <option key={key} value={roles[key]}>{key}</option>)}
          </Form.Select>
        </div>}

        {currentUser.role === 'admin' &&  <div className='mb-4'>
          <Form.Label>{translator('company')}</Form.Label>
          <Form.Select id='companies-select' value={params['company_id'] } onChange={e => updateParams(params, setParams, 'company_id', e.target.value)}> 
            <option value="">{translator('select company')}</option>
            {!!companies.companies && companies.companies.map((company,index) => <option key={company.name + index} value={company.id}>{company.name}</option>)}
          </Form.Select>
        </div>}
        
          
          {errors.length !== 0 && errors.map(error => <Alert variant='danger' key={error}>{error}</Alert>)}
          
          
      </Modal.Body>
      <Modal.Footer>
        <Button variant="default" onClick={hideModal} >{translator('cancel')}</Button>
        <Button onClick={e => save(params)}>{translator('save')}</Button>
      </Modal.Footer>
    </Modal>
  )
}

export default UserModal
