import React, {useState} from 'react'
import { Container, Modal, Button, Form} from 'react-bootstrap'

import { updateParams } from '../../scripts/helperMethods'
import { translator } from '../../scripts/translator'

function UpgradeManifestModal({showModal, hideModal, saveManifest, firmware, params}) {
  const [data, setData] = useState({allow_downgrade: false, mandatory: false, battery_firmware_id: 0, battery_parameter_id: 0, description: ''})

  return (
    <Modal show={showModal} onHide={hideModal}>
        <Modal.Header closeButton>
          <Modal.Title>{translator('new upgrade manifest')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container>
            <Form.Label>{translator('description')} *</Form.Label>
            <Form.Control value={params['description']} onChange={e => updateParams(data, setData, 'description', e.target.value)} />
            <br />
            <Form.Label>{translator('allow downgrade')}</Form.Label>
            <Form.Check checked={data['allow_downgrade']}  onChange={e => updateParams(data, setData, 'allow_downgrade', e.target.checked)} type='switch'/>
            <br/>
            <Form.Label>{translator('mandatory')}</Form.Label>
            <Form.Check checked={data['mandatory']}  onChange={e => updateParams(data, setData, 'mandatory', e.target.checked)} type='switch'/>
            <br/>
            <Form.Label>{translator('battery firmware')} *</Form.Label>
            <Form.Select value={params['battery_firmware_id']} onChange={e => updateParams(data, setData, 'battery_firmware_id', e.target.value)}>
              <option value={0}>{translator('select firmware')}</option>
              {firmware.battery_firmwares.map((ware, index) => { return(
                <option key={ware.description} value={ware.id}>{ware.description}</option>
              )})}
            </Form.Select>
            <br />
            <Form.Label>{translator('battery params')} *</Form.Label>
            <Form.Select value={params['battery_parameter_id']} onChange={e => updateParams(data, setData, 'battery_parameter_id', e.target.value)}>
              <option value={0}>{translator('select param')}</option>
              {params.battery_parameters.map((ware, index) => { return(
                <option key={ware.description} value={ware.id}>{ware.description}</option>
              )})}
            </Form.Select>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="default" onClick={hideModal}>{translator('cancel')}</Button>
          <Button onClick={e => saveManifest(data)}>{translator('save')}</Button>
        </Modal.Footer>
      </Modal>
  )
}

export default UpgradeManifestModal