import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Container, Form, Button, Spinner} from 'react-bootstrap';

import { getCellReports } from '../../state/CellReportSlice';

import { setOrder } from '../../scripts/helperMethods';
import { translator } from '../../scripts/translator';

import SimpleTable from '../widgets/SimpleTable';
import Paginator from '../widgets/Paginator';
import SearchWidget from '../widgets/SearchWidget';

function CellReportsIndexComponent() {
  const dispatch = useDispatch();
  const currentUser = useSelector(state => state.session.data);
  const data = useSelector(state => state.cellReports.data);
  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(true);
  const [downloadLink, setDownloadLink] = useState((`${process.env.REACT_APP_API_URL}/cellreports/download_csv`))
  const [searchParams, setSearchParams] = useState({ model_i_cont: '', purchase_order_line_purchase_order_wst_order_number_i_cont : '', vendor_i_cont: '', barcode_i_cont: '', testdate_gteq: '',testdate_lteq: '', production_date_gteq: '', production_date_lteq: '', ir_cell_gteq: '', ir_cell_lteq: '', s: 'created_at desc' });
  const [searchHeaders] = useState([ 
                                    {label: translator('model'), subLables: null, params: ['model_i_cont'], type: 'text', options: null}, 
                                    {label: translator('io'), subLables: null, params: ['purchase_order_line_purchase_order_wst_order_number_i_cont'], type: 'text', options: null},
                                    {label: null, subLables: null, params: ['vendor_i_cont'], type: 'select', options: [{label: 'Gotion', value: 'Gotion'}, {label: 'Eve power', value: 'Eve'}]},
                                    {label: translator('barcode'), subLables: null, params: ['barcode_i_cont'], options: null, type: 'text'},
                                    {label: translator('test date'), subLables: [translator('less than or equal'), translator('more than or equal')], params: ['testdate_lteq', 'testdate_gteq'], options: null, type: 'date' },
                                    {label: translator('prod date'), subLables: [translator('less than or equal'), translator('more than or equal')], params: ['production_date_gteq', 'production_date_lteq'], type: 'date', options: null},
                                    {label: translator('ir'), subLables: ['More than or equal', 'Less than or equal'], params: ['ir_cell_gteq', 'ir_cell_lteq'], options: null, type: 'text'}
                                  ])
  const [tableElements] = useState({'test date': 'testdate', io: 'po', 'prod date': 'production_date', manufacturer: 'manufacturer', model: 'model', voltage: 'voltage', barcode: 'barcode', ir: 'ir_cell'});

  useEffect(() => {
    getReports();
  }, [page]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if(data['cellreports'] !== undefined  && data['cellreports'].length !== 0){
      setLoading(false);
    }
  },[data])

  const getReports = () => {
    dispatch(getCellReports({page, pageSize: 100, searchParams, token: currentUser.token}));
    generateCsvDownloadLink();
    setLoading(true);
  }

  const clearSearch = () => {
    setPage(0);
    let params = { ...searchParams };
    for (let key of Object.keys(params)) {
      params[key] = '';
    }
    setSearchParams(params);
    dispatch(getCellReports({page, pageSize: 100, searchParams: params, token: currentUser.token}));
  }

  const order= (element) => setOrder(searchParams, setSearchParams, element, getReports)

  const generateCsvDownloadLink = () => {
    let searchString = '?'
    for(const key of Object.keys(searchParams)){
      if(searchParams[key].length !== 0){
       searchString = searchString + `&q[${key}]=${searchParams[key]}`
      }
    }
    const searchUrl = `${process.env.REACT_APP_API_URL}/cellreports/download_csv${searchString}`
    setDownloadLink(searchUrl)
  }

  return (
    <Container fluid>
      <Container className='my-5  mx-auto'>
        <h3>{translator('search')} ( {translator('results')}: {data.cellreports_total} )</h3>
        <SearchWidget searchParams={searchParams} searchHeaders={searchHeaders} setSearchParams={setSearchParams} search={getReports} resetSearch={clearSearch}/>
       <div>
        <a href={downloadLink} className='btn btn-primary d-flex' style={{width: '80px', position: 'relative', right: '-100%'}} rel="noopener noreferrer"><span className="material-symbols-outlined pointer" >download</span>Csv</a>
       </div>
       
      </Container>
      <br />
      <Row className='mx-auto'>
        <Col>
          <Paginator count={data.cellreports_total} pageSize={100} page={page} setPage={setPage}/>
          <br />
          {loading === true &&
            <Col className='d-flex justify-content-center'>
              <Spinner size='lg' animation="grow" />
            </Col>
          }
          {loading === false &&
            <SimpleTable tableElements={tableElements}  data={data.cellreports} searchParams={searchParams} setOrder={order}/>
          }
          <br />
          <Paginator count={data.cellreports_total} pageSize={100} page={page} setPage={setPage}/>
        </Col>
      </Row>
      <br />
      <br />
      <br />
    </Container>
  )
}

export default CellReportsIndexComponent