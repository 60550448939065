import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

export const assignBatteryUpgrade = createAsyncThunk('assignBatteryUpgrade', async (data) => {
  const upgrade = await fetch(`${process.env.REACT_APP_API_URL}//upgrade_manifests/assign_upgrade_to_batteries`, {    
    method: 'POST',
    headers: {"Content-Type": "application/json", token: data.token},
    body: JSON.stringify({manifest: data.upgrade, batteries: data.batteries})
  })
  
  return(upgrade.json())
})

export const removeBatteryUpgrade = createAsyncThunk('removeBatteryUpgrade', async (data) => {
  const upgrade = await fetch(`${process.env.REACT_APP_API_URL}//upgrade_manifests/remove_upgrade_from_batteries`, {    
    method: 'POST',
    headers: {"Content-Type": "application/json", token: data.token},
    body: JSON.stringify({manifest: data.upgrade, batteries: data.batteries})
  })

  return(upgrade.json())
})

export const handleBatteryUpgradeSlice = createSlice({
  name: 'handleBatteryUpgrade',
  initialState: {
    loading: false,
    data: [],
    error: null
  },
  reducers: {
    resetBatteryUpgrade(state, action){
      state.data = []
    }
  },
  extraReducers: (builder) => {
    builder.addCase(assignBatteryUpgrade.fulfilled, (state, action) => {
      state.data = action.payload
    });
    builder.addCase(removeBatteryUpgrade.fulfilled, (state, action) => {
      state.data = action.payload
    })
  }
})

export const {resetBatteryUpgrade} = handleBatteryUpgradeSlice.actions

export default handleBatteryUpgradeSlice.reducer;