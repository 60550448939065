import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

export const newPol = createAsyncThunk('newPol', async (data) => {
  console.log(data)
  const pol = await fetch(`${process.env.REACT_APP_API_URL}/purchase_order_lines`, {
    method: 'POST',
    headers: {token: data.token},
    body: data.params
  })

  return(pol.json())
})

export const updatePol = createAsyncThunk('updatePol', async (data) => {
  const pol = await fetch(`${process.env.REACT_APP_API_URL}/purchase_order_lines/${data.id}`, {
    method: 'PUT',
    headers: {token: data.token},
    body: data.params
  })

  return(pol.json(9))
})

export const deletePol = createAsyncThunk('deletePol', async (data) => {
  const pol = await fetch(`${process.env.REACT_APP_API_URL}/purchase_order_lines/${data.id}`, {
    method: 'DELETE',
    headers: {token: data.token},
  })

  return(pol.json())
})

export const purchaseOrderLineSlice = createSlice({
  name: 'purchaseOrderLine',
  initialState: {
    loading: false,
    data: '',
    error: null
  },
  reducers: {
    resetPol(state, action){
      state.data = ''
    }
  }, 
  extraReducers: (builder) => {
    builder.addCase(newPol.fulfilled, (state, action) => {
      state.data = action.payload
    });

    builder.addCase(updatePol.fulfilled, (state, action) => {
      state.data = action.payload
    });

    builder.addCase(deletePol.fulfilled, (state, action) => {
      state.data = action.payload
    })
  }
})

export const {resetPol} = purchaseOrderLineSlice.actions;

export default purchaseOrderLineSlice.reducer