import React, {useState, useEffect} from 'react'
import {Container, Form, Button, Table, OverlayTrigger, Popover, Col, Spinner, } from 'react-bootstrap'
import {useDispatch, useSelector} from 'react-redux';

import RmaModal from './RmaModal';
import DetailsWidget from './DetailsWidget';
import DeleteModal from '../widgets/modals/DeleteModal';
import SearchWidget from '../widgets/SearchWidget';
import Paginator from '../widgets/Paginator';

import { getRmas, createRma, updateRma, deleteRma } from '../../state/RmaSlice';
import { getUsers } from '../../state/userSlice';
import { getCustomers } from '../../state/CustomerSlice';
import { getSuppliers } from '../../state/SuppliersSlice';
import { getBatteryModels } from '../../state/BatteryModelsSlice';

import { setOrder } from '../../scripts/helperMethods';
import { translator } from '../../scripts/translator';

function RmaIndexComponent() {
  const dispatch = useDispatch()
  const [searchParams, setSearchParams] = useState({rma_type_eq: '', wst_rma_number_lteq: '', wst_rma_number_gteq: '', customer_id_eq: '', checked_by_id_eq: '', specific_rma_data_jcont: '{}', warranty_supplier_status_eq: '', s: 'received_date desc'});
  const [showFormModal, setShowFormModal] = useState(false);
  const [displayDeleteModal, setDisplayDeleteModal] = useState(false)
  const [currentRma, setCurrentRma] = useState(null)
  const [loading, setLoading] = useState(true)
  const [activeAccordions, setActiveAccordions] = useState([]);
  const [page, setPage] = useState(0)
  const [pageSize, setPageSize] = useState(20);
  const [downloadLink, setDownloadLink] = useState((`${process.env.REACT_APP_API_URL}/rmas/download_excel`))
  const [searchHeaders, setSearchHeaders] = useState([
                                                      {label: ('type'), subLables: null, params: ['rma_type_eq'], options: null, type: 'text'},
                                                      {label: ('created by'), subLables: null, params: ['checked_by_id_eq'], options: [], type: 'select'},
                                                      {label: ('customer'), subLables: null, params: ['customer_id_eq'], options: [], type: 'select'},
                                                      {label: ('wst rma number'), subLables: ['Less than or equal', 'More than or equal'], params: ['wst_rma_number_lteq', 'wst_rma_number_gteq'], options: null, type: 'text'},
                                                      {label: ('serial number'), subLables: null, params: ['specific_rma_data_jcont', "serial_number"], options: null, type: 'json'},
                                                      {label: ('model'), sublables: null, params: ['specific_rma_data_jcont', 'product_model'], options: null, type: 'json'},
                                                      {label: ('within warranty'), sublables: null, params: ['warranty_wst_status_eq'], options: [{label: 'Unknown', value: 0}, {label: 'Yes', value: 10}, {label: 'No', value: 20}], type: 'select'},
                                                      {label: ('within supplier warranty'), sublables: null, params: ['warranty_supplier_status_eq'], options: [{label: 'Unknown', value: 0}, {label: 'Yes', value: 10}, {label: 'No', value: 20}], type: 'select'}
                                                    ])
  const rmas = useSelector(state => state.rma.data);
  const users = useSelector(state => state.user.data);
  const customers = useSelector(state => state.customers.data);
  const suppliers = useSelector(state => state.supplier.data);
  const batteryModels = useSelector(state => state.batteryModels.data)
  const currentUser = useSelector(state => state.session.data);


  useEffect(() => {
    getElements()
    getRma()
  },[])

  

  useEffect(() => {
    if(rmas.message && (rmas.message === translator('rma successfully saved') || rmas.message === translator('rma successfully deleted')) ){
      getRma()
    }else{
      if(rmas.rma && rmas.rma.length !== 0 ){
        setLoading(false)
      }
    }
  },[rmas])

  useEffect(() => {
    if(customers.length !== 0 && users.length !== 0){
      setSearchHeaders([
        {label: ('type'), subLables: null, params: ['rma_type_eq'], options: null, type: 'text'},
        {label: ('created by'), subLables: null, params: ['checked_by_id_eq'], options: users.users.map(user => ({label: user.email, value: user.id})), type: 'select'},
        {label: ('customer'), subLables: null, params: ['customer_id_eq'], options: customers.map(customer => ({label: customer.name, value: customer.id })), type: 'select'},
        {label: ('wst rma number'), subLables: ['Less than or equal', 'More than or equal'], params: ['wst_rma_number_lteq', 'wst_rma_number_gteq'], options: null, type: 'text'},
        {label: ('serial number'), subLables: null, params: ['specific_rma_data_jcont', "serial_number"], options: null, type: 'json'},
        {label: ('model'), sublables: null, params: ['specific_rma_data_jcont', 'product_model'], options: null, type: 'json'},
        {label: ('within warranty'), sublables: null, params: ['warranty_wst_status_eq'], options: [{label: 'Unknown', value: 0}, {label: 'Yes', value: 10}, {label: 'No', value: 20}], type: 'select'},
        {label: ('within supplier warranty'), sublables: null, params: ['warranty_supplier_status_eq'], options: [{label: 'Unknown', value: 0}, {label: 'Yes', value: 10}, {label: 'No', value: 20}], type: 'select'}
      ])
    }
  }, [customers, users])

  useEffect(() => {
    getRma()
  },[page])
  

  const getElements = () => {
    dispatch(getUsers({token: currentUser.token, searchParams: {}}));
    dispatch(getCustomers({token: currentUser.token}));
    dispatch(getSuppliers({token: currentUser.token}));
    dispatch(getBatteryModels({token: currentUser.token}));
  }

  const getRma = () => {
    let searchString = `?`;
    for (const key of Object.keys(searchParams)) {
      if (searchParams[key].length !== 0) {
        searchString = searchString + `&q[${key}]=${searchParams[key]}`
      }
    }

    setDownloadLink(`${process.env.REACT_APP_API_URL}/rmas/download_excel${searchString}` )

    setLoading(true)
    dispatch(getRmas({searchParams, page, pageSize, token: currentUser.token}));
  }

  const resetSearch = () => {
    setSearchParams({rma_type_eq: '', wst_rma_number_eq: '', customer_id_eq: '', checked_by_id_eq: '', s: 'received_date desc'})
    getRma();
  }

  const openDeleteModal = (rma) =>{
    setCurrentRma(rma)
    setDisplayDeleteModal(true)
  } 

  const hideModal = () => {
    setShowFormModal(false)
    setDisplayDeleteModal(false)
    setCurrentRma(null)
  }

  const editRma = (rma) => {
    setCurrentRma(rma);
    setShowFormModal(true)
  }
  
  // handles sending data to flow api
  const saveRma = (params) => {
    setLoading(true)
    const formData = new FormData() // needs to create a variable that is new form data, due to files beign uploaded
    for( let key of Object.keys(params)){ //iterates over all the updated params
      if(key === 'files'){ //checks if the param is the changed files
        for(let i = 0; i < params['files'].length; i++){// if so iterates over each file
          if(!!params[key][i].size){ // checks if the file is actually a file based on one of the file params
            formData.append(`rma[${key}][]`, params[key][i]) // add file with the right parameter key for api to read it
          }
        }
      }else if(key === 'specific_rma_data'){
        for(let dataKey of Object.keys(params['specific_rma_data'])){
          formData.append(`rma[${key}][${dataKey}]`, params[key][dataKey])
        }
      }else{ // if its not a file 
        formData.append(`rma[${key}]`, params[key]) // adds param with right param key for api to read
      }
    }
    if(currentRma !== null){ // if the current rma state is ste, it mean that the user is updating an existing rma
      dispatch(updateRma({id: currentRma.id, params: formData, token: currentUser.token})); // call update action
    }else{ // if current rma is empty, it means that the user is trying to create a new rma
      dispatch(createRma({params:formData, token: currentUser.token})); // calls new rma action
    }
    hideModal() // closes modal and makes current rma to empty
    // setLoading(true) // starts the spinner
  }

  const rmaNumbers = () => !!rmas.rma && rmas.rma.map(rma => rma.wst_rma_number)

  const handleAccordions = (checked) => {
    checked === true ? setActiveAccordions( rmas.rma.map((rma, index) => index )) : setActiveAccordions([]);
    getRma();
  }

  const deleteConfirmedRma = (rma) => {
    hideModal();
    dispatch(deleteRma(rma.id, currentUser.token));
  } 

  const showOverlay = (text) => <OverlayTrigger trigger="click" placement="left" overlay={<Popover id="popover-basic"><Popover.Body>{text}</Popover.Body></Popover>}><p className='pointer'>{`${text.slice(0, 9)} ...`}</p></OverlayTrigger>
  
  const pointer = (element) => searchParams['s'] === `${element} asc` ? <span className="material-symbols-outlined"> arrow_drop_down </span> : <span className="material-symbols-outlined"> arrow_drop_up </span>
 

  return (
    <Container fluid>
      <Container className='w-50' style={{marginTop: '100px', marginBottom: '50px'}}>
        <h1><span className="material-symbols-outlined">search</span></h1>
        <SearchWidget searchParams={searchParams} searchHeaders={searchHeaders} setSearchParams={setSearchParams} search={getRma} resetSearch={resetSearch}/>
        
        <br />
        <div className='d-flex justify-content-between'>
          <div className='d-flex'>
            <Form.Label>{translator('open all fields')}</Form.Label>
            <div className='m-1'/>
            <Form.Check id='open-all' checked={activeAccordions.length !== 0 } onChange={e => handleAccordions(e.target.checked)} type='switch'/>
          </div>
          <a href={downloadLink} className='btn btn-primary d-flex' rel="noopener noreferrer"><span className="material-symbols-outlined pointer" >download</span>Excel</a>

        </div>
      </Container>
      <Button onClick={e => setShowFormModal(true)}>{translator('new rma')}</Button>
      <br />
      <br />
      {loading === true && <Col className='d-flex justify-content-center'><Spinner size='lg' animation="grow" /></Col> }
      {loading === false && 
        <>
          <Paginator page={page} pageSize={pageSize} setPage={setPage}/>
          <br />
          <Table striped hover size="sm" className="table-font" >
            <thead>
              <tr>
                <th>{translator('actions')}</th>
                <th className='pointer' onClick={e => setOrder(searchParams, setSearchParams, 'wst_rma_number', getRma)}>{translator('wst rma number')}  {pointer('wst_rma_number')}</th>
                <th className='pointer' onClick={e => setOrder(searchParams, setSearchParams, 'delivery_date', getRma)}>{translator('delivery date')} {pointer('delivery_date')} </th>
                <th className='pointer' onClick={e => setOrder(searchParams, setSearchParams,'received_date', getRma)}>{translator('received date')} {pointer('received_date')} </th>
                <th className='pointer' onClick={e => setOrder(searchParams, setSearchParams,'completed_date', getRma)}>{translator('completed date')} {pointer('completed_date')} </th>
                <th className='pointer' onClick={e => setOrder(searchParams, setSearchParams, 'completed_date', getRma)}>{translator('serial number')} {pointer('completed_date')}</th>
                <th className='pointer' onClick={e => setOrder(searchParams, setSearchParams, 'completed_date', getRma)}>{translator('status')} {pointer('completed_date')}</th>
                <th className='pointer' onClick={e => setOrder(searchParams, setSearchParams, 'completed_date', getRma)}>{translator('model')} {pointer('completed_date')}</th>
                <th className='pointer' onClick={e => setOrder(searchParams, setSearchParams, 'completed_date', getRma)}>{translator('cusotmer')} {pointer('completed_date')}</th>
                <th className='pointer' onClick={e => setOrder(searchParams, setSearchParams, 'completed_date', getRma)}>{translator('fault')} {pointer('completed_date')}</th>
                <th>{translator('details')}</th>
              </tr>
            </thead>
            <tbody>
              {!rmas.message && rmas.rma.map((rma, index) => {
                return(
                  <tr key={index}>
                    <td>
                      <div className='d-flex table-buttons'>
                        {currentUser.role === 'admin' && <Button variant='danger' className='btn-sm d-flex action-button' onClick={e => openDeleteModal(rma)}><span className="material-symbols-outlined">delete</span></Button>}
                        <Button variant='warning' id='edit' className='btn-sm d-flex action-button' onClick={e => editRma(rma)}><span className="material-symbols-outlined">edit</span></Button>
                      </div>
                    </td>
                    <td>{rma.wst_rma_number}</td>
                    <td>{rma.delivery_date}</td>
                    <td>{rma.received_date}</td>

                    <td>{rma.completed_date}</td>
                    <td>{rma['specific_rma_data']['serial_number']}</td>
                    <td>{rma.status}</td>
                    <td>{rma.specific_rma_data['product_model'].length !== 0 ? rma.specific_rma_data['product_model']: 'N/A' }</td>
                    <td>{rma.customer}</td>
                    <td>{rma.fault_description.length !== 0 ? showOverlay(rma.fault_description) : ''}</td>
                    <td style={{width: '65%'}}><DetailsWidget activeAccordions={activeAccordions} rma={rma} index={index}/></td>                  
                  </tr>
                )
              })}
            </tbody> 
          </Table>
        </>
      }

      {showFormModal === true && <RmaModal showModal={showFormModal} hideModal={hideModal} suppliers={suppliers} customers={customers} batteryModels={batteryModels} currentUser={currentUser} currentRma={currentRma} save={saveRma} faults={rmas.fault_descriptions} rmaNumbers={rmaNumbers()}/>}
      {displayDeleteModal === true && <DeleteModal showModal={displayDeleteModal} confirmModal={deleteConfirmedRma} hideModal={hideModal} element={currentRma} message={`are you sure you want to delete RMA ${!!currentRma && currentRma.wst_rma_number} ?`}/>}
    </Container>
  )
}

export default RmaIndexComponent