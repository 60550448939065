import React from 'react';
import {Button} from 'react-bootstrap';

import { translator } from '../../scripts/translator';

function Paginator({count, pageSize, page, setPage}) {

  return (
    <div className="d-flex">
      <Button variant='light' className='btn-sm' disabled={page === 0}  onClick={e => setPage(page - 1)}> {'<<'} </Button>
      <div>{translator('Page')} {page + 1}</div>
      <Button variant='light' className='btn-sm' disabled={page === Math.round(count/pageSize)} onClick={e => setPage(page + 1)}> {'>>'} </Button>
    </div>
  )
}

export default Paginator