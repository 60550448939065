import React,{ useEffect, useState} from 'react'
import {Button, Container, Form, Spinner, Col} from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';

import { getBatteryParams, saveBatteryParams } from '../../../state/BatteryParamsSlice';
import { getBatteryModels } from '../../../state/BatteryModelsSlice';
import { getBmsModels } from '../../../state/bmsModelsSlice';

import BatteryParametersModal from './BatteryParametersModal';
import SimpleTable from '../../widgets/SimpleTable';
import SearchWidget from '../../widgets/SearchWidget';
import { translator } from '../../../scripts/translator';

function BatteryParametersIndexComponent() {
  const dispatch = useDispatch();
  const params = useSelector(state => state.batteryParams.data);
  const batteryModels = useSelector(state => state.batteryModels.data);
  const bmsModels = useSelector(state => state.bmsModels.data);
  const currentUser = useSelector(state => state.session.data);
  const [searchParams, setSearchParams] = useState({id_eq: '', battery_model_name_eq: '', bms_model_name_eq: ''})
  const [tableElements] = useState({id: 'id', description: 'description', version: 'version', 'battery model': 'model', 
                                    'created at': 'created_at', 'updated at': 'updated_at', parameters: 'parametersdata', 
                                    'bms model': 'bms_model'})
  const [searchHeaders, setSearchHeaders] = useState([])
  const [displayModal, setDisplayModal] = useState(false)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    getData();
  },[]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if(params.length !== 0){
      setLoading(false)
    }
  },[params])

  useEffect(() => {
    if(bmsModels.length !== 0 && batteryModels.length !== 0){
      setSearchHeaders([
        {label: translator('id'), subLables: null, params: ['id_eq'], options: null, type: 'text'},
        {label: translator('battery model'), subLables: null, params: ['battery_model_name_eq'], options: batteryModels.map(model => ({label: model.name, value: model.name})), type: 'select'},
        {label: translator('bms model'), subLables: null, params: ['bms_model_name_eq'], options: bmsModels.bms_models.map(model => ({label: model.name, value: model.name})), type: 'select'},
      ])
    }
  },[batteryModels, bmsModels])

  const getData = () => {
    setLoading(true)
    hideModals();
    dispatch(getBatteryParams({searchParams, token: currentUser.token}));
    dispatch(getBatteryModels({token: currentUser.token}));
    dispatch(getBmsModels({token: currentUser.token}));
  }

  const hideModals= () => setDisplayModal(false);

  const saveParams = (data) => {
    dispatch(saveBatteryParams(data, currentUser.token));
    getData();
  }

  const setSearch = (key, value) => {
    const params = {...searchParams};
    params[key] = value;
    setSearchParams(params);
  }
  
  const resetSearch = () => setSearchParams({id_eq: '', battery_model_name_eq: '', bms_model_name_eq: ''});

  return (
    <Container fluid>
        <br />
        <br />
        <Container>
          <h1><span className="material-symbols-outlined">search</span></h1>
          <SearchWidget searchParams={searchParams} searchHeaders={searchHeaders} setSearchParams={setSearchParams} search={getData} resetSearch={resetSearch}/>
        </Container>
        <br />
        <br />

        <Button onClick={e => setDisplayModal(true)}>{translator('create parameter')}</Button>
        <br />
        <br />
        {loading === true && <Col className='d-flex justify-content-center'><Spinner size='lg' animation="grow" /></Col>}
        {loading === false && <SimpleTable tableElements={tableElements}  data={params.battery_parameters} searchParams={searchParams} setOrder={null}/>}
         
        {displayModal === true && <BatteryParametersModal showModal={displayModal} hideModal={hideModals} saveParams={saveParams} batteryModels={batteryModels} bmsModels={bmsModels}/>}
    </Container>
  )
}

export default BatteryParametersIndexComponent