import React, {useState} from 'react'
import {Modal, Container, Form, Button} from 'react-bootstrap'
import { updateParams, clearParams } from '../../scripts/helperMethods'

import { translator } from '../../scripts/translator'

function ChargerModal({showModal, hideModal, models, save}) {
  const [formFields, setFormFields] = useState({charger_model_id: '', serial_number: '', charger_serial: '', note: ''})

  const saveData = () => {
    save(formFields)
    clearParams(formFields, setFormFields)
    hideModal()
  }

  return (
    <Modal show={showModal} onHide={hideModal} backdrop='static' >
    <Modal.Header closeButton >
      <Modal.Title>{'New Charger'}</Modal.Title>
    </Modal.Header>
    <Modal.Body >
      <Container className='d-flex flex-wrap justify-content-between'>
        <div className="mb-4 w-100">
          <Form.Label>{translator('charger model')}</Form.Label>
          <Form.Select value={formFields['charger_model_id']} onChange={e => updateParams(formFields, setFormFields,'charger_model_id', e.target.value)}>
            <option value="">{translator('select charger model')}</option>
            {models.map((model) => <option key={model.name} value={model.id}>{model.name}</option>)}
          </Form.Select>
        </div>
        <div className="mb-4 w-100">
          <Form.Label>{translator('serial number')}</Form.Label>
          <Form.Control value={formFields['serial_number']} onChange={e =>  updateParams(formFields, setFormFields,'serial_number', e.target.value)} type='number'/>
        </div>
        <div className="mb-4 w-100">
          <Form.Label>{translator('charger serial')}</Form.Label>
          <Form.Control value={formFields['charger_serial']} onChange={e => updateParams(formFields, setFormFields, 'charger_serial', e.target.value)}/>
        </div>
        <div className="mb-4 w-100">
          <Form.Label>{translator('note')}</Form.Label>
          <Form.Control  value={formFields['note']} onChange={e => updateParams(formFields, setFormFields,'note', e.target.value)} type='text-field'/>
        </div>
       
      </Container>
    </Modal.Body>
    <Modal.Footer>
      <Button variant="default" onClick={hideModal}>{translator('cancel')}</Button>
      <Button onClick={saveData}>{translator('save')}</Button>
    </Modal.Footer>
  </Modal>
  )
}

export default ChargerModal