import React from 'react'
import { Modal, Button } from 'react-bootstrap'
import { translator } from '../../../scripts/translator'

function DeleteModal({showModal, hideModal, confirmModal, element, message}) {
  return (
    <Modal show={showModal} onHide={hideModal}>
        <Modal.Header closeButton>
          <Modal.Title>{translator('delete confirmation')}</Modal.Title>
        </Modal.Header>
        <Modal.Body><div className="alert alert-danger">{message}</div></Modal.Body>
        <Modal.Footer>
          <Button variant="default" onClick={hideModal}>
            {translator('cancel')}
          </Button>
          <Button variant="danger" onClick={() => confirmModal(element) }>
            {translator('delete')}
          </Button>
        </Modal.Footer>
      </Modal>
  )
}

export default DeleteModal