export const updateParams = (params, setParams, key, value) =>{
  const data = {...params}
  data[key] = value;
  setParams(data)
}

export const clearParams = (params, setParams) => {
  const data = {}
  for(let key of Object.keys(params)){
    data[key] = '';
  }
  setParams(data)
}

export const setOrder= (searchParams, setSearchParams, element, getData) => {
  let params = {...searchParams}
  if(searchParams['s'].includes(element)){
    searchParams['s'].includes('desc') ? params['s'] = `${element} asc` : params['s'] = `${element} desc`;
  }else{
    params['s'] = `${element} asc`;
  }
  setSearchParams(params)
  getData(params);
}