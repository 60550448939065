import React,{ useEffect, useState} from 'react'
import {Button, Container, Form, Col, Spinner} from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';

import { getBatteryFirmware, saveBatteryFirmware } from '../../../state/BatteryFirmwareSlice';
import { getBatteryModels } from '../../../state/BatteryModelsSlice';
import { getBmsModels } from '../../../state/bmsModelsSlice';

import BatteryFirmwareModal from './BatteryFirmwareModal';
import SimpleTable from '../../widgets/SimpleTable';
import SearchWidget from '../../widgets/SearchWidget';
import { translator } from '../../../scripts/translator';

function BatteyFirmwaresindexComponent() {
  const dispatch = useDispatch();
  const [searchHeaders, setSearchHeaders] = useState([
                                                      {label: translator('id'), subLables: null, params: ['id_eq'], options: null, type: 'text'},
                                                      {label: translator('battery model'), subLables: null, params: ['battery_model_name_eq'], options: [], type: 'select'},
                                                      {label: translator('bms model'), subLables: null, params: ['bms_model_name_eq'], options: [], type: 'select'},
                                                    ])
  const firmware = useSelector(state => state.batteryFirmware.data);
  const batteryModels = useSelector(state => state.batteryModels.data);
  const bmsModels = useSelector(state => state.bmsModels.data);
  const currentUser = useSelector(state => state.session.data);
  const [searchParams, setSearchParams] = useState({id_eq: '', battery_model_name_eq: '', bms_model_name_eq: ''})
  const [tableElements] = useState({id: 'id', description: 'Description', version: 'version', 'battery model': 'model', 
                                    'created at': 'created_at', 'updated at': 'updated_at', 'bms model': 'bms_model'})
  const [displayModal, setDisplayModal] = useState(false)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    getData();
  },[]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if(firmware.length !== 0){
      setLoading(false)
    }
  },[firmware])

  useEffect(() => {
    if(bmsModels.length !== 0 && batteryModels.length !== 0){
      setSearchHeaders([
        {label: translator('id'), subLables: null, params: ['id_eq'], options: null, type: 'text'},
        {label: translator('battery model'), subLables: null, params: ['battery_model_name_eq'], options: batteryModels.map(model => ({label: model.name, value: model.name})), type: 'select'},
        {label: translator('bms model'), subLables: null, params: ['bms_model_name_eq'], options: bmsModels.bms_models.map(model => ({label: model.name, value: model.name})), type: 'select'},
      ])
    }
  },[batteryModels, bmsModels])

  const getData = () => {
    setLoading(true)
    hideModals();
    dispatch(getBatteryFirmware({searchParams, token: currentUser.token}));
    dispatch(getBatteryModels({token: currentUser.token}));
    dispatch(getBmsModels({token: currentUser.token}));
  }

  const hideModals= () => setDisplayModal(false);

  const saveFirmware = (data) => { 
    const formData = new FormData();
    for(let key of Object.keys(data)){
      formData.append(`battery_firmware[${key}]`, data[key])
    }
    dispatch(saveBatteryFirmware(formData, currentUser.token));
    dispatch(getBatteryFirmware(searchParams, currentUser.token));
    hideModals();
  }
  
  const resetSearch = () =>{ 
    setSearchParams({id_eq: '', battery_model_name_eq: '', bms_model_name_eq: ''});
    getData();
  }

  return (
    <Container fluid>
        <br />
        <br />
        <Container>
          <h1><span className="material-symbols-outlined">search</span></h1>
          <SearchWidget searchParams={searchParams} searchHeaders={searchHeaders} setSearchParams={setSearchParams} search={getData} resetSearch={resetSearch}/>
        </Container>
        <br />
        <br />

        <Button onClick={e => setDisplayModal(true)}>{translator('create firmware')}</Button>
        <br />
        <br />
        {loading === true && <Col className='d-flex justify-content-center'><Spinner size='lg' animation="grow" /></Col>}
        {loading === false && <SimpleTable tableElements={tableElements}  data={firmware.battery_firmwares} searchParams={searchParams} setOrder={null}/> }
        

        {displayModal === true &&  <BatteryFirmwareModal showModal={displayModal} hideModal={hideModals} saveFirmware={saveFirmware} batteryModels={batteryModels} bmsModels={bmsModels}/>}
    </Container>
  )
}

export default BatteyFirmwaresindexComponent