import React, {useEffect} from 'react'
import { Modal, Button, Alert, Form } from 'react-bootstrap'
import {useDispatch, useSelector} from 'react-redux' 

import { getBatteryData } from '../../state/IpBmsSlice'

import { translator } from '../../scripts/translator'


function IpBmsModal({showModal, hideModal, uid}) {
  const dispatch = useDispatch()
  const ipBattery = useSelector(state => state.ipBms.data)

  useEffect(() => {
    if(uid.length !== 0){
      getData()
    }
  }, [showModal, uid])

  useEffect(() => {
    console.log('ip>Battery', ipBattery)
  }, [ipBattery])

  const getData = () => dispatch(getBatteryData({uid}))

  return (
    <Modal show={showModal} onHide={hideModal}>
      <Modal.Header closeButton>
        <Modal.Title>{translator('battery')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <strong><pre>{JSON.stringify(ipBattery.data, null, 2)}</pre></strong> 
      </Modal.Body>
      <Modal.Footer>
        <Button variant="default" onClick={hideModal}>{translator('cancel')}</Button>
      </Modal.Footer>
    </Modal>
  )
}

export default IpBmsModal