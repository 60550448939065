import React, {useEffect, useState} from 'react'
import {Container, Spinner, Button} from 'react-bootstrap'
import {useDispatch, useSelector} from 'react-redux'

import { getChargers, createCharger } from '../../state/ChargerSlice'
import SimpleTable from '../widgets/SimpleTable'
import SearchWidget from '../widgets/SearchWidget'
import ChargerModal from './ChargerModal'
import { getChargerModels } from '../../state/ChargerModelSlice'

import { setOrder } from '../../scripts/helperMethods'
import { translator } from '../../scripts/translator'

function ChargerIndexComponent() {
  const dispatch = useDispatch()
  const [searchParams, setSearchParams] = useState({serial_number_lteq: '', serial_number_gteq: '', charger_serial_i_cont: '', charger_model_id_eq: '', s: ''})
  const [searchHeaders, setSearchHeaders] = useState([
                                                      {label: translator('serial number'), subLables: [translator('less than or equal'), translator('more than or equal')], params: ['serial_number_lteq', 'serial_number_gteq'], options: null, type: 'text'},
                                                      {label: translator('charger serial'), subLables: null, params: ['charger_serial_i_cont'], options: null, type: 'text'},
                                                      {label: translator('model'), subLables: null, params: ['charger_model_id_eq'], options: [], type: 'select'},
                                                    ])
  const [loading, setLoading] = useState(true)
  const [showModal, setShowModal] = useState(false)
  const [tableElements] = useState({'serial number': 'serial_number', 'charger serial': 'charger_serial', 'charger model': 'model', note: 'note'})
  const currentUser = useSelector(state => state.session.data)
  const chargers = useSelector(state => state.charger.data)
  const chargerModels = useSelector(state => state.chargerModels.data)

  useEffect(() => {
    if(!!chargers.chargers){
      setLoading(false)
    }else{
      fetchChargers()
      fetchChargerModels()
    }
  },[chargers])

  useEffect(() => {
    if(!!chargerModels.length !== 0 ){
      setSearchHeaders([
                        {label: translator('serial number'), subLables: [translator('less than or equal'), translator('more than or equal')], params: ['serial_number_lteq', 'serial_number_gteq'], options: null, type: 'text'},
                        {label: translator('charger serial'), subLables: null, params: ['charger_serial_i_cont'], options: null, type: 'text'},
                        {label: translator('model'), subLables: null, params: ['charger_model_id_eq'], options: chargerModels.map(model => ({label: model.name, value: model.id})), type: 'select'},
                      ])
    }
  }, [chargerModels])

  const clearSearch = () => setSearchParams({serial_number_lteq: '', serial_number_gteq: '', charger_serial_i_cont: '', charger_model_id_eq: '', s: 'serial_number asc'})

  const fetchChargers = () => {
    setLoading(true)
    dispatch(getChargers({params: searchParams, token: currentUser.token}))
  }

  const fetchChargerModels = () => dispatch(getChargerModels({token: currentUser.token}))

  const hideModal = () => setShowModal(false)

  const save = (params) => dispatch(createCharger({params, token: currentUser.token}))

  const order= (element) => setOrder(searchParams, setSearchParams, element, fetchChargers)

  return (
    <Container fluid>
      <Container>
        <h3>Search</h3>
        <SearchWidget searchParams={searchParams} searchHeaders={searchHeaders} setSearchParams={setSearchParams} search={fetchChargers} resetSearch={clearSearch} />
      </Container>
      {loading === true && 
      <div className='w-100 d-flex justify-content-center'>
        <Spinner size='lg' animation="grow" />
      </div>
      }
      {loading === false &&
        <div>
          <Button onClick={e => setShowModal(true)}>{translator('new charger')}</Button>
          <br />
          <br />
          <SimpleTable tableElements={tableElements}  data={chargers.chargers} searchParams={searchParams} setOrder={order}/> 
        </div>
      }
      <ChargerModal showModal={showModal} hideModal={hideModal} models={chargerModels} save={save} />
    </Container>
  )
}

export default ChargerIndexComponent