import React, {useState, useEffect} from 'react'
import {Accordion, Table, Card, OverlayTrigger, Popover, ButtonGroup, DropdownButton, Dropdown} from 'react-bootstrap'

import SimpleTable from '../widgets/SimpleTable'

function DetailsWidget({activeAccordions, rma, index}) {
  const [specificHeadersBatery] = useState({chg: 'chg', dsg: 'dsg', 'ov number': 'ov_number', 
                                            'sc number': 'sc_number', 'uv number': 'uv_number', 
                                            'comm.': 'comunication', 'cycle number': 'cycle_number', 
                                            'model': 'product_model', 'serial number': 'serial_number', 
                                            'casing voltage': 'casing_voltage', 'ov cycle ratio': 'ov_cycle_ratio', 
                                            'ov cycle ratio': 'ov_cycle_ratio', 'uv cycle ratio': 'uv_cycle_ratio', 
                                            'sc cycle ratio': 'sc_scycle_ratio'});
  
  const [specificHeadersCharger] = useState({'Model': 'product_model', charging: 'charging'})

  const [rmaHeaders] = useState({'checked by': 'checked_by', cusotmer: 'customer', 
                                  'delivery date': 'delivery_date', 'rma type': 'type', 
                                  'customer rma number': 'customer_rma_number', 
                                  'supplier rma number': 'supplier_rma_number', 
                                  'customer issue': 'customer_issue_description', 
                                  'root cause analysis': 'root_cause_analysis', 
                                  'warranty wst status': 'warranty_wst_status', 'notes': 'notes', 
                                  'replacement percentage': 'replacement_percentage', 'supplier': 'supplier', 
                                  'warranty supplier status': 'warranty_supplier_status', 
                                  'replacement spent': 'replacement_spent', 'files': 'files'})

  

  return (
    <Accordion alwaysOpen size="sm" variant="tight" defaultActiveKey={activeAccordions} >
       <Accordion.Item key={index} eventKey={index}  >
            <Accordion.Header><strong>Wst rma number: {rma.wst_rma_number}</strong></Accordion.Header>
            <Accordion.Body className='d-flex flex-wrap justify-content-center'>
              <div className='w-100 justify-content-start'>
                <SimpleTable tableElements={rmaHeaders}  data={[rma]} searchParams={[]} setOrder={null}/>
              </div>
              {rma.type === 'BatteryRma' && 
                <div className='w-100 justify-content-start' >
                  {(rma.specific_rma_data !== null && Object.keys(rma.specific_rma_data)) !== 0 && <SimpleTable tableElements={specificHeadersBatery}  data={[rma.specific_rma_data]} searchParams={[]} setOrder={null}/>}
                </div>
              }
              {rma.type === 'ChargerRma' && 
                <div className='w-100 justify-content-start' >
                  {(rma.specific_rma_data !== null && Object.keys(rma.specific_rma_data)) !== 0 && <SimpleTable tableElements={specificHeadersCharger}  data={[rma.specific_rma_data]} searchParams={[]} setOrder={null}/>}
                </div>
              }
            </Accordion.Body>
          </Accordion.Item>
    </Accordion>
  )
}

export default DetailsWidget