import React, {useState, useEffect} from 'react'
import { Modal, Button, Alert, Form } from 'react-bootstrap'

import { translator } from '../../scripts/translator'
import { updateParams, clearParams } from '../../scripts/helperMethods'

function CellModal({showModal, hideModal, save, currentCell}) {
  const [params, setParams] = useState({model_number: '', brand: '', chemestry: '', max_voltage: '', min_voltage: '', capacity: '', charge_rate: '', min_discharge_rate: '', 
                                        max_discharge_rate: '', diameter: '', lenght: '', thickness: '', height: '', weight: '', energy: '', energy_density: '', ocv_table: null, datasheet: null})
  
  useEffect(() => {
    if(!!currentCell){populateParams()}
  }, [currentCell])

  const populateParams = () => setParams({model_number: currentCell.model_number , brand: currentCell.brand, chemestry: currentCell.chemestry, max_voltage: currentCell.max_voltage, 
                                          min_voltage: currentCell.min_voltage, capacity: currentCell.capacity, charge_rate: currentCell.charge_rate, 
                                          min_discharge_rate: currentCell.min_discharge_rate, max_discharge_rate: currentCell.max_discharge_rate, diameter: currentCell.diameter, 
                                          lenght: currentCell.lenght, thickness: currentCell.thickness, height: currentCell.height, weight: currentCell.weight, 
                                          energy: currentCell.energy, energy_density: currentCell.energy_density, ocv_table: currentCell.ocv_table, datasheet: currentCell.datasheet})

  const saveData = () => {
    const saveParams = {...params}
    clearParams(params, setParams)
    save(saveParams)
  }

  const close = () => {
    clearParams(params, setParams)
    hideModal()
  }

  const calculateEnergyDensity = () => {
    if(!!params.chemestry && !!params.weight && !!params.energy){
      const density = params.chemestry === 'LFP' ?  params.energy * 3.2 / params.weight : params.energy * 3.2 / params.weight 
      updateParams(params, setParams, 'energy_density', density)
    }
  }

  return (
    <Modal show={showModal} onHide={hideModal}>
      <Modal.Header closeButton>
        <Modal.Title>{translator('create cell')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
     
        <div>
          <Form.Label>{translator('model_number')}</Form.Label>
          <Form.Control value={params['model_number']} onChange={e => updateParams(params, setParams, 'model_number', e.target.value)}/>
          <br />
        </div>
        <div>
          <Form.Label>{translator('brand')}</Form.Label>
          <Form.Control value={params['brand']} onChange={e => updateParams(params, setParams, 'brand', e.target.value)}/>
          <br />
        </div>
        <div>
          <Form.Label>{translator('chemestry')}</Form.Label>
          <Form.Control value={params['chemestry']} onChange={e => updateParams(params, setParams, 'chemestry', e.target.value)}/>
          <br />
        </div>
        <div>
          <Form.Label>{translator('max_voltage')}</Form.Label>
          <Form.Control value={params['max_voltage']} onChange={e => updateParams(params, setParams, 'max_voltage', e.target.value)}/>
          <br />
        </div>
        <div>
          <Form.Label>{translator('min_voltage')}</Form.Label>
          <Form.Control value={params['min_voltage']} onChange={e => updateParams(params, setParams, 'min_voltage', e.target.value)}/>
          <br />
        </div>
        <div>
          <Form.Label>{translator('capacity')}</Form.Label>
          <Form.Control value={params['capacity']} onChange={e => updateParams(params, setParams, 'capacity', e.target.value)}/>
          <br />
        </div>
        <div>
          <Form.Label>{translator('charge_rate')}</Form.Label>
          <Form.Control value={params['charge_rate']} onChange={e => updateParams(params, setParams, 'charge_rate', e.target.value)}/>
          <br />
        </div>
        <div>
          <Form.Label>{translator('min_discharge_rate')}</Form.Label>
          <Form.Control value={params['min_discharge_rate']} onChange={e => updateParams(params, setParams, 'min_discharge_rate', e.target.value)}/>
          <br />
        </div>
        <div>
          <Form.Label>{translator('max_discharge_rate')}</Form.Label>
          <Form.Control value={params['max_discharge_rate']} onChange={e => updateParams(params, setParams, 'max_discharge_rate', e.target.value)}/>
          <br />
        </div>     
        <div>
          <Form.Label>{translator('diameter')}</Form.Label>
          <Form.Control value={params['diameter']} onChange={e => updateParams(params, setParams, 'diameter', e.target.value)}/>
          <br />
        </div>   
        <div>
          <Form.Label>{translator('lenght')}</Form.Label>
          <Form.Control value={params['lenght']} onChange={e => updateParams(params, setParams, 'lenght', e.target.value)}/>
          <br />
        </div>   
        <div>
          <Form.Label>{translator('thickness')}</Form.Label>
          <Form.Control value={params['thickness']} onChange={e => updateParams(params, setParams, 'thickness', e.target.value)}/>
          <br />
        </div>   
        <div>
          <Form.Label>{translator('height')}</Form.Label>
          <Form.Control value={params['height']} onChange={e => updateParams(params, setParams, 'height', e.target.value)}/>
          <br />
        </div>  
        <div>
          <Form.Label>{translator('weight')}</Form.Label>
          <Form.Control value={params['weight']} onChange={e => updateParams(params, setParams, 'weight', e.target.value)}/>
          <br />
        </div>
        <div>
          <Form.Label>{translator('energy')}</Form.Label>
          <Form.Control value={params['energy']} onChange={e => updateParams(params, setParams, 'energy', e.target.value)}/>
          <br />
        </div>

        <div>  
          <Form.Label>{translator('energy_density')}</Form.Label>
          <div className='d-flex'>
            <Button onClick={() => calculateEnergyDensity()}>Calculate</Button>
            <Form.Control value={params['energy_density']} disabled='true'/>
          </div>
          
          <br />
        </div>

        <div>
          <Form.Label>{translator('ocv table')}</Form.Label>
          <Form.Control onChange={e => updateParams(params, setParams, 'ocv_table', e.target.files[0])} type="file"/>
          <br />
        </div>
        <div>
          <Form.Label>{translator('datasheet')}</Form.Label>
          <Form.Control onChange={e => updateParams(params, setParams, 'datasheet', e.target.files[0])}  type="file"/>
          <br />
        </div>   
      </Modal.Body>
      <Modal.Footer>
        <Button variant="default" onClick={() => close()}>{translator('cancel')}</Button>
        <Button onClick={() => saveData()}>{translator('save')}</Button>
      </Modal.Footer>
    </Modal>
  )
}

export default CellModal