import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import { Container, Row, Col, Spinner } from 'react-bootstrap';

import SimpleTable from '../widgets/SimpleTable';
import Paginator from '../widgets/Paginator';
import IpBmsModal from './IpBmsModal';

import { getBatteries } from '../../state/BatteriesSlice' 
import { setOrder } from '../../scripts/helperMethods';


function IpBmsIndexComponent() {
  const dispatch = useDispatch();
  const ipBms = useSelector(state => state.batteries.data);
  const currentUser = useSelector(state => state.session.data)
  const [page, setPage] =useState(0)
  const [loading, setLoading] = useState(true)
  const [showModal, setShowModal] = useState(false) 
  const [currentUid, setCurrentUid] = useState('')
  const [searchParams, setSearchParams] = useState({ ip_uid_not_null: true, s: 'created_at desc' });
  const [tableElements] = useState({'serial number': 'serialnumber', 'bms serial number': 'bmsserial', 'purchase order line': 'purchase_order_line', 
                                     'aqua report': 'aquareport_id', 'battery model': 'model', 'bms model': 'bms_model', 'Ip uid': 'ip_uid', 'actions': 'actions' })

  useEffect(() => {
    getData()
  },[])

  useEffect(() => {
    console.log(ipBms)
    if(!!ipBms.batteries && Array.isArray(ipBms.batteries)){
      setLoading(false)
    }
  },[ipBms])

  const getData = () => dispatch(getBatteries({token: currentUser.token, page, params: searchParams}))

  const order= (element) => setOrder(searchParams, setSearchParams, element, getData)

  const openModal = (battery) => {
    setCurrentUid(battery.ip_uid)
    setShowModal(true)
  }
  const closeModal = () => {
    setCurrentUid('')
    setShowModal(false)
  }

  return (
    <Container fluid>
      <Container className='my-5  mx-auto'>       
      </Container>
      <br />
      <Row className='mx-auto'>
        <Col>
          <br />
          {loading === true &&
            <Col className='d-flex justify-content-center'>
              <Spinner size='lg' animation="grow" />
            </Col>
          }
          {loading === false && <Paginator count={ipBms.results} pageSize={100} page={page} setPage={setPage}/>}
          <br />
          {(!!ipBms.batteries && loading === false) &&
            <SimpleTable tableElements={tableElements}  data={ipBms.batteries} searchParams={searchParams} setOrder={order} openModal={openModal}/>
          }
          <br />
          {loading === false && <Paginator count={ipBms.results} pageSize={100} page={page} setPage={setPage}/>}
        </Col>
      </Row>
      <br />
      <br />
      <br />
      <IpBmsModal showModal={showModal} hideModal={closeModal} uid={currentUid}/>
    </Container>
  )
}

export default IpBmsIndexComponent 