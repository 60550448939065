import React, {useState} from 'react';
import { Table, Button, ProgressBar, Accordion, OverlayTrigger, Tooltip, ButtonGroup, DropdownButton, Dropdown } from 'react-bootstrap';
import {useDispatch, useSelector} from 'react-redux';

import { updatePol, deletePol } from '../../state/PurchaseOrderLineSlice';
import { createShipment } from '../../state/ShipmentSlice';
import { translator } from '../../scripts/translator';

import ShipmentsWidget from './ShipmentsWidget';
import PurchaseOrderLinesModal from './PurchaseOrderLinesModal';
import ShipmentsModal from './ShipmentsModal';
import DeleteModal from '../widgets/modals/DeleteModal';

function PurchaseOrderLineWidget({purchaseOrderLines, purchaseOrderId, activeAccordions}) {
  const dispatch = useDispatch();
  const [displayPolModal, setDisplayPolModal] = useState(false);
  const [displayShipmentModal, setDisplayShipmentModal] = useState(false);
  const [displayDeleteModal, setDisplayDeleteModal] = useState(false);
  const [currentPol, setCurrentPol] = useState({});
  const currentUser = useSelector(state => state.session.data);

  const getAmmountShiped = (pol) => {
    let total = 0;
    for(const shipment of pol.shipments){
      total = total + shipment.amount_shipped
    }
    return(`${total} / ${pol.amount}`)
  }

  const calculateAmmountShippedPercentage = (pol) => {
    let total = 0;
    for(const shipment of pol.shipments){
      total = total + shipment.amount_shipped
    }
    return((total / pol.amount) * 100)
  }

  const calculateCompletedPercentage = (pol) => (pol.amount_completed / pol.amount)*100 

  const hideModals = () => {
    setCurrentPol({});
    setDisplayPolModal(false);
    setDisplayShipmentModal(false);
    setDisplayDeleteModal(false);
  }

  const savePol = (params) => {
    if(!params.cell_quality_report || !params.cell_quality_report.name){delete params['cell_quality_report']}
    if(!params.company_id){delete params['company_id']}
    if(params.qspec.length === 0){delete params.qspec}
    if(params.wes_spec.length === 0){delete params.wes_spec}
    params.purchase_order_id = purchaseOrderId;
    const formData = new FormData()

    for(let key of Object.keys(params)){
      if(key === 'technical_documents'){
        
        for(let i = 0; i < params['technical_documents'].length; i++){
          if(typeof params[key][i].id == 'undefined'){formData.append(`purchase_order_line[${key}][]`, params[key][i])}
          
        }
      }else{
        formData.append(`purchase_order_line[${key}]`, params[key]);
      }
      
    }

    dispatch(updatePol({id: currentPol.id, params: formData, token: currentUser.token}))
  }

  const saveShipment = (params) => {
    if(!(params.bill_of_lading instanceof File)){delete params.bill_of_lading }
    if(!(params.commercial_invoice instanceof File)){delete params.commercial_invoice}
    if(!(params.packing_list instanceof File)){delete params.packing_list }
    if(!(params.packing_details instanceof File)){delete params.packing_details }
    if(!!params.documents && params.documents.length === 0){delete params.documents }
    
    params['purchase_order_line_id'] = currentPol.id;
    const formData = new FormData();
    for(let key of Object.keys(params)){
      if(key === 'documents' && params[key].length !== 0){
        for(let i = 0; i < params['documents'].length; i++){
          formData.append(`shipment[documents_attributes][${i}][note]`, params['documents'][i]['note']);
          formData.append(`shipment[documents_attributes][${i}][file]`, params['documents'][i]['file']);
        }
      }else{
        formData.append(`shipment[${key}]`, params[key])
      }
    }

    dispatch(createShipment({params: formData, token: currentUser.token}));
    setDisplayShipmentModal(false)
  }
  
  const openUpdate = (pol) => {
    setCurrentPol(pol)
    setDisplayPolModal(true)
  }

  const openDelete = (line) => {
    setCurrentPol(line);
    setDisplayDeleteModal(true);
  }

  const deleteConfirmedPol = (pol) => {
    dispatch(deletePol({id: pol.id, token: currentUser.token}));
    setCurrentPol({});
    hideModals();
  }

  const openShipment = (pol) => {
    setCurrentPol(pol);
    setDisplayShipmentModal(true)
  }

  const onTime = (line) => {
    let error = {color: 'black', message: ''};
    let currentDate = new Date();
    let overDue = currentDate.toISOString().split('T')[0] > line.expected_production_done_date
    for(let shipment of line.shipments){
      const createdAt = new Date(shipment.created_at)
      const ladingDate = new Date(createdAt.setDate(createdAt.getDate() + 12)).toISOString().split('T')[0]
      if(ladingDate < currentDate.toISOString().split('T')[0] && !shipment.bill_of_lading){ error = {color: '#03DAC6', message: translator('bill of lading missing')}}
    }
    if(!line.expected_production_done_date && !line.actual_production_done_date){error = {color: 'orange', message: translator('expected done date not set')}}
    if(overDue && line.amount > line.total_shipped_amount){error = {color: 'purple', message: translator('not shipped on time')}}
    if(overDue && line.amount > line.amount_completed){error = {color: 'red', message: translator('not produced on time')}}
    if(line.amount_completed !== 0 && !line.cell_quality_report && (line.aqua_enabled === true || line.wes_enabled === true)){error = {color: 'blue', message: translator('Cell quality report not uploaded')}}
    return(error)
  }

  return (
    <div>
      <Accordion  alwaysOpen defaultActiveKey={activeAccordions} >
        {purchaseOrderLines.map((line, index) => { return(
          <Accordion.Item key={index} eventKey={`${index}`}  >
            <Accordion.Header>
  
              <span className='d-flex'  >
              {line.internal_order_for_pack ? <strong style={{color: '#00b5ffe0'}}>{`${line.po_line_id} - ${line.product_number} ${translator('internal order')} `} </strong> : <strong>{`${line.po_line_id} - ${line.product_number}`} </strong> }
               <strong style={{color: (onTime(line).color)}}> {onTime(line).message}</strong>
                {activeAccordions.length === 0 &&
                  <div>
                    <div className="progress-bar-wrapper" style={{marginLeft: '10px'}}>               
                      <ProgressBar className="progress-bar-text" now={100} label={`${line.amount_completed} / ${line.amount}`} />
                      <ProgressBar variant="info" className="progress-bar-body" now={calculateCompletedPercentage(line)}/>
                    </div>
                    <div className="progress-bar-wrapper" style={{marginLeft: '110px'}}>               
                      <ProgressBar className="progress-bar-text" now={100} label={`${getAmmountShiped(line)}`} />
                      <ProgressBar variant="warning" className="progress-bar-body" now={calculateAmmountShippedPercentage(line)}/>
                    </div>
                  </div>
                }
                <strong style={{marginLeft: '120px'}}>{!!line.actual_production_done_date ?  `Act. Done: ${line.actual_production_done_date}` : !!line.expected_production_done_date ? `Exp Done: ${line.expected_production_done_date}` : '' }</strong>
              </span>
            </Accordion.Header>
            <Accordion.Body>
            <Table size="sm" className="table-font">
              <thead>
                <tr>
                  {(!!currentUser && currentUser.role !== 'customer') &&<th></th>}
                  <th>{translator('pid')}</th>
                  <th>{translator('td')}</th>
                  <th>{translator('item number')}</th>
                  <th>{translator('produced')}</th>
                  <th>{translator('shipped')}</th>
                  <th>{translator('notes')}</th>
                  <th>{translator('actualy done')}</th>
                  <th>{translator('expected done')}</th>
                  <th>{translator('cqr')}</th>
                  <th>{translator('acir')}</th>
                  <th>{translator('voltage')} (mV)</th>
                  <th>{translator('shipments')}</th>
                  
                </tr>
              </thead>
              <tbody>
                <tr key={index}>
                  <td style={{width: '5%'}}>
                    {(!!currentUser && currentUser.role !== 'customer') &&
                      <div className='d-flex table-buttons'>
                        <Button id='new-shipment' className='btn-sm d-flex align-items-center' onClick={e => openShipment(line)}><span className="material-symbols-outlined">add</span></Button>
                        <Button id='edit-pol' variant='warning' className='btn-sm d-flex align-items-center' onClick={e => openUpdate(line)}><span className="material-symbols-outlined">edit</span></Button>
                        {currentUser.role === 'admin' && <Button variant='danger' className='btn-sm d-flex align-items-center' onClick={e => openDelete(line)} ><span className="material-symbols-outlined">delete</span></Button>}
                        
                      </div>
                    }
                  </td>
                  <td style={{width: '3%', text: 'center'}} >{line.po_line_id}</td>
                  <td>
                  <ButtonGroup>
                    <DropdownButton size="sm" drop='up' variant="outline-secondary" as={ButtonGroup} title={`${line.technical_documents.length}`} id="bg-nested-dropdown">
                      {line.technical_documents.map((document, index) => {return(
                        <Dropdown.Item key={document.name} download href={document.url} className="text-decoration-underline text-primary" target="_blank">{document.name}</Dropdown.Item>
                      )})}
                         
                      
                    </DropdownButton>
                  </ButtonGroup>
                  </td>
                  <td style={{width: '3%'}}>{line.product_number}</td>
                  <td style={{width: '9%'}}>
                    <div className="progress-bar-wrapper">               
                      <ProgressBar className="progress-bar-text" now={100} label={`${line.amount_completed} / ${line.amount}`} />
                      <ProgressBar variant="info" className="progress-bar-body" now={calculateCompletedPercentage(line)}/>
                    </div>
                  </td>
                  <td style={{width: '10%'}}> 
                  <div className="progress-bar-wrapper">               
                    <ProgressBar className="progress-bar-text" now={100} label={`${getAmmountShiped(line)}`} />
                    <ProgressBar variant="warning" className="progress-bar-body" now={calculateAmmountShippedPercentage(line)}/>
                  </div>
                  </td>
                  <td style={{width: '4%'}}>
                    {line.note && 
                      <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">{line.note}</Tooltip>}>
                        <span className="material-symbols-outlined">info</span>
                      </OverlayTrigger>
                    }
                  </td>
                  <td style={{width: '6%'}}>{line.actual_production_done_date}</td> 
                  <td style={{width: '6%'}}>{line.expected_production_done_date}</td>             
                              
                  <td style={{width: '5%'}}> {!!line.cell_quality_report && <a href={line.cell_quality_report} target='_blank' rel="noreferrer"> <span className="material-symbols-outlined pointer" >download</span></a> }</td>             
                  <td></td>
                  <td></td>
                  <td style={{width: '65%'}}>
                    <ShipmentsWidget shipments={line.shipments} pol={line}/>
                  </td>
                </tr>
              </tbody>
            </Table>
            </Accordion.Body>
          </Accordion.Item>
        )})}
        
      </Accordion>
      
      {displayDeleteModal === true && <DeleteModal showModal={displayDeleteModal} confirmModal={deleteConfirmedPol} hideModal={hideModals} element={currentPol} message={`${translator('are you sure you want to delete purchase order line')} ${currentPol.po_line_id}`}/>}
      {displayShipmentModal === true && <ShipmentsModal showModal={displayShipmentModal} hideModal={hideModals} save={saveShipment} pol={currentPol} currentShipment={null}/>}
      {displayPolModal === true && <PurchaseOrderLinesModal showModal={displayPolModal} hideModal={hideModals} savePol={savePol} currentPol={currentPol} purchaseOrderId={purchaseOrderId} currentUser={currentUser}/>}
    </div>
  )
}

export default PurchaseOrderLineWidget