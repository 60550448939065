import React, {useState, useEffect} from 'react'
import {Navbar, Container, Nav, NavDropdown} from 'react-bootstrap'
import {useSelector, useDispatch} from 'react-redux'
import { useNavigate} from 'react-router'

import { logout } from '../../state/SessionSlice'

import TypeSpecificModal from '../companies/TypeSpecificModal';
import WstLogoComponent from './logos/WstLogoComponent';
import UpdateCurrentUserModal from './modals/UpdateCurrentUserModal';
import { translator } from '../../scripts/translator'

function NavbarCoponent() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const current_user = useSelector(state => state.session.data);
  const aquaReports = useSelector(state => state.aquaReports.data)
  const cellReports = useSelector(state => state.cellReports.data)
  const companies = useSelector(state => state.companies.data)
  const dashboard = useSelector(state => state.dashboard.data)
  const purchaseOrderLine = useSelector(state => state.purchaseOrderLine.data)
  const purchaseOrder = useSelector(state => state.purchaseOrder.data)
  const shipment = useSelector(state => state.shipment.data)
  const users = useSelector(state => state.user.data)
  const overview = useSelector(state => state.overview.data)
  const [currentUserModal, setCurrentUserModal] = useState(false)
  const [displayCompanyModal, setDisplayCompanyModal] = useState(false)

  useEffect(() => {console.log(current_user)},[current_user])

 
  useEffect(() => {
    if(!!purchaseOrderLine.message && purchaseOrderLine.message === "invalid credentials"){ logoutUser()}
    if(!!purchaseOrder.message && purchaseOrder.message === "invalid credentials"){ logoutUser()}
    if(!!shipment && !!shipment.message && shipment.message === "invalid credentials"){ logoutUser()}
    if(!!aquaReports.message && aquaReports.message === "invalid credentials"){ logoutUser()}
    if(!!cellReports.message && cellReports.message === "invalid credentials"){ logoutUser()}
    if(!!companies.message && companies.message === "invalid credentials"){ logoutUser()}
    if(!!dashboard.message && dashboard.message === "invalid credentials"){ logoutUser()}
    if(!!overview.message && overview.message === "invalid credentials"){ logoutUser()}
    
  },[aquaReports, cellReports, companies, dashboard, purchaseOrderLine, purchaseOrder, shipment, users, overview]) // eslint-disable-line react-hooks/exhaustive-deps

  const logoutUser = () => {

    dispatch(logout());
    navigate('/login')
    window.location.reload();
  }


  const hideModal = () => {
    setCurrentUserModal(false);
    setDisplayCompanyModal(false);
  }

  return (
    <Navbar bg="light" collapseOnSelect expand="lg" >
      <Container fluid>
      <Navbar.Brand href="/">
        <WstLogoComponent/> {`V - ${process.env.REACT_APP_VERSION}`}
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse >
          <Nav className="me-auto">
            <Nav.Link href="/overview">{translator('overview')}</Nav.Link>
            <NavDropdown title={translator('reports')}>
              <NavDropdown.Item href="/aqua_reports">{translator('Aqua Report')}</NavDropdown.Item>
              {(!!current_user && current_user.role !== 'customer') && <NavDropdown.Item href="/wes_reports">{translator('WES reports')}</NavDropdown.Item>}
              {(!!current_user && current_user.role !== 'customer') && <NavDropdown.Item href="/cell_reports">{translator('Cell reports')}</NavDropdown.Item>}
              {(!!current_user && current_user.role === 'admin') && <NavDropdown.Item href="/dashboard">{translator('Dashboard')}</NavDropdown.Item>}
              {(!!current_user && (current_user.role === 'admin' || current_user.role === 'supervisor')) && <NavDropdown.Item href="/4g">{translator('4g reports')}</NavDropdown.Item>}
              {/* <NavDropdown.Item href="/batteries">{translator('Batteries')}</NavDropdown.Item>*/}
               
            </NavDropdown>
            
            {(!!current_user && current_user.role !== 'customer') && <Nav.Link href="/override_codes">{translator('Override Codes')}</Nav.Link>}
            {/* {(!!current_user && (current_user.role === 'admin' || current_user.role === 'supervisor')) &&  
              <NavDropdown title={translator("battery")}>
                <NavDropdown.Item href="/upgrade/batteries">{translator('Batteries')}</NavDropdown.Item>
                <NavDropdown.Item href="/battery_parameters">{translator('Battery Params')}</NavDropdown.Item>
                <NavDropdown.Item href="/battery_firmwares">{translator('Battery Firwares')}</NavDropdown.Item>                
                <NavDropdown.Divider />
                <NavDropdown.Item href="/upgrade_manifests">{translator('Upgrade Manifest')} </NavDropdown.Item>
              </NavDropdown>
            } */}
            
            { (!!current_user && (current_user.role === 'admin' || current_user.role === 'supervisor')) &&   <Nav.Link href="/rma">{translator('RMA')}</Nav.Link>}
            { (!!current_user && (current_user.role === 'admin' || current_user.role === 'supervisor')) &&
            <NavDropdown title='Tools'><NavDropdown.Item href="/users">{('Users')}</NavDropdown.Item>
              <NavDropdown.Item href="/companies">{translator('Companies')}</NavDropdown.Item>
              <NavDropdown.Item href="/cells">{translator('cells')}</NavDropdown.Item>
              <NavDropdown.Item href="/bms_models">{translator('BMS models')}</NavDropdown.Item>
              {/* { (!!current_user &&  (current_user.role === 'admin' || current_user.role === 'supervisor')) &&   <NavDropdown.Item href="/chargers">{translator('Chargers')}</NavDropdown.Item>} */}
            </NavDropdown>}
          </Nav>
          <Nav>
            <NavDropdown align='end' title={("Settings")} id="basic-nav-dropdown">
              <NavDropdown.Item onClick={e => setCurrentUserModal(true)}>Update profile</NavDropdown.Item> 
              {(!!current_user && ( current_user.role === "supervisor" || current_user.role === 'admin')) && <NavDropdown.Divider /> }    
              {(!!current_user && (current_user.role === "supervisor" || current_user.role === 'admin')) && <NavDropdown.Item  onClick={e => setDisplayCompanyModal(true)}>{translator('New Customer/Supplier')}</NavDropdown.Item>}         

            </NavDropdown>
            
            {!!current_user &&  current_user.token ?  <Nav.Link onClick={e => logoutUser()}>{translator('Log out')}</Nav.Link> : <Nav.Link href="/login">{translator('Log in')}</Nav.Link> }
          </Nav>
        </Navbar.Collapse>
      </Container>
      <UpdateCurrentUserModal showModal={currentUserModal}  hideModal={hideModal} currentUser={current_user}/>
      { displayCompanyModal === true && <TypeSpecificModal showModal={displayCompanyModal} hideModal={hideModal} current_user={current_user} />}

    </Navbar>
  )
}

export default NavbarCoponent