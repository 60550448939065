import React, {useState, useEffect} from 'react'
import { Button, Modal, Form, Container, Alert } from 'react-bootstrap'
import { translator } from '../../scripts/translator'

function PurchaseOrderModal({showModal, hideModal, savePo, purchaseOrder, currentUser}) {
  const [params, setParams] = useState({wst_order_number: '', haidi_order_number: '', complete_order_received_date: '', note: '', customer_order_number: '', technical_data_delivered: '', purchase_order_lines_attributes: {po_line_id: 0, product_number: '', aqua_enabled: true, amount: 0, amount_completed: 0 }})
  const [formElements] = useState({'Wst order number *': 'wst_order_number', 'Haidi order number': 'haidi_order_number', 'All done date': 'complete_order_received_date', 'Note': 'note', 'Customer order number': 'customer_order_number', 'tdd': 'technical_data_delivered'})
  const [errorMessages, setErrorMessages] = useState([])

  useEffect(()=> {
    Object.keys(purchaseOrder).length !== 0 ? setPo() : resetParams();
  },[purchaseOrder, showModal]) // eslint-disable-line react-hooks/exhaustive-deps

  const savePurchaseOrder = (params) => {
    let errors = []
    if(params.wst_order_number.length === 0){ errors.push(translator('wst order number cannot be empty and has to be unique'))}
    if(errors.length === 0 ){
      savePo(params)
    }else{
      setErrorMessages(errors)
      setTimeout(() => {
        setErrorMessages([]);
      }, 5000)
    }
  }

  

  const resetParams = () => setParams({wst_order_number: '', haidi_order_number: '', complete_order_received_date: '', note: '', customer_order_number: '', technical_data_delivered: '', purchase_order_lines_attributes: {po_line_id: 0, product_number: '', aqua_enabled: true, amount: 0, amount_completed: 0}})

  const setPo = () =>{
    const data = {wst_order_number: '', haidi_order_number: '', complete_order_received_date: '', note: '', customer_order_number: ''}
    for(let key of Object.keys(params)){
      data[key] = purchaseOrder[key]
    }
    setParams(data)
  }
  
  const updateParams = (key, value) => {
    console.log(key, value)
    const data = {...params};
    if(key === 'po_line_id' || key === 'product_number' || key === 'aqua_enabled' || key === 'amount'){
      data['purchase_order_lines_attributes'][key] = value;
    }else{
      data[key] = value;
    }
    
    setParams(data);
  }
  
  return (
    <Modal show={showModal} onHide={hideModal}>
        <Modal.Header closeButton>
          <Modal.Title>{Object.keys(purchaseOrder).length !== 0 ? 'Update purchase order' : 'New Purchase Order'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container>
          {(currentUser.role === 'admin' || currentUser.role === 'supervisor') &&  <div className='mb-2'>
              <Form.Label>{translator('wst order number')} *</Form.Label>
              <Form.Control id='wst-number' value={params['wst_order_number'] || ''} onChange={e => updateParams('wst_order_number', e.target.value)}/>
            </div>}
            <div className='mb-2'>
              <Form.Label>{translator('haidi order number')}</Form.Label>
              <Form.Control id='haidi-number' value={params['haidi_order_number'] || ''} onChange={e => updateParams('haidi_order_number', e.target.value)}/>
            </div>
            {(currentUser.role === 'admin' || currentUser.role === 'supervisor') &&  <div className='mb-2'>
              <Form.Label>{translator('all done date')}</Form.Label>
              <Form.Control value={params['complete_order_received_date'] || ''} onChange={e => updateParams('complete_order_received_date', e.target.value)} type='date'/>
            </div>}
            <div className='mb-2'>
              <Form.Label>{translator('note')}</Form.Label>
              <Form.Control id='note' value={params['note'] || ''} onChange={e => updateParams('note', e.target.value)} as='textarea'/>
            </div>
            <div className='mb-2'>
              <Form.Label>{translator('customer order number')}</Form.Label>
              <Form.Control id ='customer-number' value={params['customer_order_number'] || ''} onChange={e => updateParams('customer_order_number', e.target.value)}/>
            </div>
            <div className='mb-2'>
              <Form.Label>{translator('technical document delivered')}</Form.Label>
              <Form.Select id='tdd' value={params['technical_data_delivered']  || ''} onChange={e => updateParams('technical_data_delivered', e.target.value)}>
                <option value=""></option>
                <option value="red">Red</option>
                <option value="yellow">Yellow</option>
                <option value="green">Green</option>
              </Form.Select>
            </div>            
            <br />
            {Object.keys(purchaseOrder).length === 0 && 
              <div>
                <br />
                <h6>{translator('purchase order line')}</h6>
                <br />
                <br />
                <Form.Label>{translator('po line id')} </Form.Label>
                <Form.Control id='pol-id' value={!!params.purchase_order_lines_attributes && params.purchase_order_lines_attributes.po_line_id ? params.purchase_order_lines_attributes.po_line_id : ''} onChange={e => updateParams('po_line_id', e.target.value)} type='number'/>
                <br />
                <Form.Label>{translator('product number')} </Form.Label>
                <Form.Control id='product-id' value={!!params.purchase_order_lines_attributes && params.purchase_order_lines_attributes.product_number ? params.purchase_order_lines_attributes.product_number : ''} onChange={e => updateParams('product_number', e.target.value)}/>
                <br />
                <Form.Label>{translator('aqua enabled')} </Form.Label>
                <Form.Check id='aqua' checked={!!params.purchase_order_lines_attributes && params.purchase_order_lines_attributes.aqua_enabled ? params.purchase_order_lines_attributes.aqua_enabled : false}  onChange={e => updateParams('aqua_enabled', e.target.checked)} type='switch'/>
                <br />
                <Form.Label>{translator('amount')} </Form.Label>
                <Form.Control id='amount' value={!!params.purchase_order_lines_attributes && params.purchase_order_lines_attributes.amount ? params.purchase_order_lines_attributes.amount : ''} onChange={e => updateParams('amount', e.target.value)} type='number'/>
                <br />
              </div>
            }
          </Container>
          {errorMessages.length !== 0 && errorMessages.map((message, index) => {return(
            <Alert key={index} variant='danger'>
              {message}
            </Alert>
          )})}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="default" onClick={e => hideModal()}>{translator('cancel')}</Button>
          <Button onClick={e => savePurchaseOrder (params)} disabled={errorMessages.length !== 0}>{translator('save')}</Button>
        </Modal.Footer>
      </Modal>
  )
}

export default PurchaseOrderModal